import React, { useState, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/HomePage/Home';
import Services from './pages/Manufacturing/Services';
import Products from './pages/Products/Products';
import AboutUs from './pages/AboutUS/AboutUs';
import Footer from './pages/Footer/Footer';
import Login from './pages/login/index.js';
import Register from './pages/Register/Register';
import Account from './pages/Account';
import Password from './pages/login/components/Password';
import Manufacturers from './pages/Manufacturers/index';
import ShoppingCart from './pages/ShoppingCart/index';
import ActivateAccount from './pages/ActivateAccount/index';
import ManufacturerAccount from './pages/ManufacturerAccount/index';
import ResetPassDesigner from './pages/login/components/ResetPassDesigner';
import Orders from './pages/Orders/index';
import Toast from './components/toast/Toast';
import AdmLoginView from './pages/AdminLogin/index';
import AdminPanel from './pages/MasterAdmin/index';
import Faqpage from './pages/FAQ/index';
import { CartProvider } from './context/CartContext';
import { DesignerProvider } from './context/DesignerContext';



let userToken = JSON.parse(localStorage.getItem('userToken'));

const App = withRouter(({ location }) => {
  const [configToast, setConfigToast] = useState({
    typeToast: '',
    textToast: ''
  });

  const changeError = () => {
    setConfigToast({ ...configToast, typeToast: '', textToast: '' });
  }

  let componentToast = () => {
    if (configToast.typeToast !== '') {
      return <Toast typeToast={configToast.typeToast}
        changeError={changeError}
        textToast={configToast.textToast} />
    } else {
      return null
    }
  }

  useEffect(() => {
    window.scrollTo(0,0);
    if (userToken === null) {
      let objStorage = { token: '', user: 0 };
      localStorage.setItem('userToken', JSON.stringify(objStorage));
      window.location.href = '/';
    } else {
      if (location.pathname !== '/logout' && Object.values(userToken.token).length === 0) {
        setConfigToast({ ...configToast, typeToast: "info", textToast: "Debes iniciar sesión" });
      }
      if (location.pathname === '/manufacturerAccount' && userToken.user !== 2) {
        window.location = '/';
      }
      if (location.pathname === '/AdminDashboard' && userToken.user !== 3) {
        window.location = '/';
      }
    }
  }, [location]);

  return (
    <>
      <DesignerProvider>
        <CartProvider>
          {
            userToken !== null ?
              location.pathname === '/manufacturerAccount' || location.pathname === '/AdminDashboard' || location.pathname === '/pt-admin' ?
                null : <Navbar /> : <Navbar />
          }
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/services' exact component={Services} />
            <Route path='/pt-admin' exact component={AdmLoginView} />
            <Route path='/products' exact component={Products} />
            <Route path='/about-us' exact component={AboutUs} />
            <Route path='/sign-in' exact component={Login} />
            <Route path='/logout' exact component={Home} />
            <Route path='/sign-up' exact component={Register} />
            <Route path='/reset-password' exact component={Password} />
            <Route path='/activateAccount' component={ActivateAccount} />
            <Route path='/resetPassAccount' component={ResetPassDesigner} />
            <Route path='/manufacturers' exact component={Manufacturers} />
            <Route path='/FAQ' exact component={Faqpage} />
            {
              userToken !== null ?
                Object.values(userToken.token).length !== 0 ?
                  userToken.user === 1 ?
                    <>
                      <Route path='/my-account' exact component={Account} />
                      <Route path='/shopping-cart' exact component={ShoppingCart} />
                      <Route path='/order-summary' component={Orders} />
                    </>
                    :
                    userToken.user === 2 ?
                      <Route path='/manufacturerAccount' exact component={ManufacturerAccount} />
                      :
                      <Route path='/AdminDashboard' exact component={AdminPanel} />
                  :
                  <>
                    <Route path='*' exact component={Home} />
                    {componentToast()}
                  </>
                :
                <>
                  <Route path='/*' exact component={Home} />
                  {componentToast()}
                </>
            }
          </Switch>
          {
            userToken !== null ?
              location.pathname === '/manufacturerAccount' || location.pathname === '/AdminDashboard' || location.pathname === '/pt-admin' ?
                null : <Footer /> : <Footer />
          }
        </CartProvider>
      </DesignerProvider>
    </>
  );
})

export default App;

import React, { useState, useEffect } from 'react';
import { fetchAddComentCartProviderApi } from '../services';
import Toast from '../../../components/toast/Toast';


const ModalProviderComments = ({ isModalOpen, cartInfo, handleCloseModalComments }) => {
    const [cartInfoArray, setCartInfoArray] = useState([]);

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    // const [newDocument, setNewDocument] = useState("");
    // const [isLoadingDoc, setIsLoadingDoc] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [currentDate, setCurrentDate] = useState("");

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    // async function handleAddDocument(e) {
    //     e.preventDefault();
    //     setIsLoadingDoc(true);
    //     setTimeout(() => {
    //         setConfigToast({ ...configToast, typeToast: "done", textToast: "Documento añadido" });
    //         setIsLoadingDoc(false);
    //     }, 1500);
    // }

    async function handleAddComent(e) {
        e.preventDefault();
        setIsLoading(true);
        let comment = cartInfoArray.remark;
        if (comment !== "") {
            comment = comment + "\n" + currentDate + " Proveedor: " + newComment;
        } else {
            comment = currentDate + " Proveedor: " + newComment;
        }
        let objComment = {
            orderId: cartInfo._id,
            comment: comment,
            cartId: cartInfoArray._id
        }
        let response = await fetchAddComentCartProviderApi(objComment);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                cartInfoArray.remark = comment;
                setNewComment("");
                setIsLoading(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    const getCurrentDate = () => {
        let tempDate = new Date();
        if (tempDate.getMonth() < 10) {
            if (tempDate.getDate() < 10) {
                let date = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            } else {
                let date = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            }
        } else {
            if (tempDate.getDate() < 10) {
                let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            } else {
                let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            }
        }
    }

    const char_count = (str, letter) => {
        var letter_Count = 0;
        for (var position = 0; position < str.length; position++) {
            if (str.charAt(position) == letter) {
                letter_Count += 1;
            }
        }
        return letter_Count;
    }

    // const handleFile = (e) => {
    //     let files = e.target.files;
    //     let base64Data;
    //     if (files.length !== 0) {
    //         let reader = new FileReader();
    //         reader.readAsDataURL(files[0]);

    //         reader.onload = (e) => {
    //             base64Data = e.target.result;
    //             setNewDocument(base64Data);
    //         }
    //     }
    // }


    useEffect(() => {
        getCurrentDate();
        if (Object.keys(cartInfo).length !== 0 && isModalOpen) {
            setCartInfoArray(cartInfo.cartInfo[0]);
        }
    }, [isModalOpen]);

    return (
        <>
            <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                id="registerModal" tabIndex="1" aria-labelledby="registerModalLabel" aria-hidden="true">
                {componentToast()}
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Comentarios del pedido</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModalComments(false)}></button>
                        </div>
                        <div className="modal-body" style={{ height: 450, overflowY: 'auto' }}>
                            {Object.keys(cartInfoArray).length !== 0 ?
                                <>
                                    <form className="row mb-3" onSubmit={handleAddComent}>
                                        <div className="col-lg-12 optionContainer">
                                            <textarea type="text" className="form-control" id="inputOrderComment" placeholder='Comments'
                                                name="remark" value={cartInfoArray.remark} rows={char_count(cartInfoArray.remark, ':')} disabled />
                                        </div><br />
                                        <div className="col-md-7">
                                            <label htmlFor="inputNewComment" className="form-label">Añadir comentario:</label><br />
                                            <input type="text" className="form-control" value={newComment} id="manufacturerName"
                                                onChange={(e) => { setNewComment(e.target.value) }}
                                                name="inputNewComment" required />
                                        </div>
                                        <div className="col-md-5" style={{ alignSelf: 'flex-end' }}>
                                            {isLoading ?
                                                <button className="btn btn-primary" type="submit" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-success"
                                                    disabled={!newComment}>añadir nuevo</button>
                                            }
                                        </div>
                                    </form>
                                    {/* <form className="row mb-3" onSubmit={handleAddDocument}>
                                        <div className="col-md-7">
                                            <label htmlFor="inputNewComment" className="form-label">Añadir documento:</label><br />
                                            <input className="form-control" type="file" id="formFile" onChange={handleFile} />
                                        </div>
                                        <div className="col-md-5" style={{ alignSelf: 'flex-end' }}>
                                            {isLoadingDoc ?
                                                <button className="btn btn-primary" type="submit" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-success"
                                                    disabled={!newDocument}>añadir documento</button>
                                            }
                                        </div>
                                    </form> */}
                                </>
                                : null
                            }
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => handleCloseModalComments(false)}>Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalProviderComments;



import React from 'react'
import LoginView from './Components/LoginView'

function AdmLoginView() {
    return (
        <div>
           <LoginView/> 
        </div>
    )
}

export default AdmLoginView

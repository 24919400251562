import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
//Url Cart Api
const fetchCreateOrderDesignerUrl = '/orders/register';
const fetchGetDesignerOrdersUrl = '/orders/getDesignerOrders';


let userToken = JSON.parse(localStorage.getItem('userToken'));

//Function's Designer Cart

export async function fetchCreateOrderDesignerApi(dataOrderDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchCreateOrderDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataOrderDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getDesignerOrdersApi() {
  try {
    const response = await axios({
      url: baseUrl + fetchGetDesignerOrdersUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      // data: dataDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const loginUserUrl = '/designers/login';
const resetPassUserUrl = '/designers/sendEmailResetPass';
const completeResetPassUserUrl = '/designers/completeResetPass';
const loginProviderUrl = '/providers/login';

export async function loginDesignerApi(dataDesigner) {
  try {
    const response = await axios({
      url: baseUrl + loginUserUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function loginProviderApi(dataDesigner) {
  try {
    const response = await axios({
      url: baseUrl + loginProviderUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function resetPassDesignerApi(emailDesigner) {
  try {
    const response = await axios({
      url: baseUrl + resetPassUserUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { email: emailDesigner },
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function completeResetPassDesignerApi(dataDesigner) {
  try {
    const response = await axios({
      url: baseUrl + completeResetPassUserUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': dataDesigner.token
      },
      data: dataDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}
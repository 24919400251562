import React, { useState } from 'react';
import '../../../css/principal.css';
import '../css/login.css';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import Toast from '../../../components/toast/Toast';
import { loginDesignerApi, loginProviderApi } from '../services';

function Login({ manufacturer }) {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''

    });
    const [isManufacturer, setIsManufacturer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target
        setFormValues({ ...formValues, [name]: value });
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const handleSubmit = (e) => {
        setIsLoading(true);
        if (isManufacturer) {
            loginProvider();
        } else {
            loginUser();
        }
        e.preventDefault();
    }

    async function loginUser() {
        const response = await loginDesignerApi(formValues);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                setTimeout(() => {
                    let objStorage = { token: response.data.token, user: 1 };
                    localStorage.setItem('userToken', JSON.stringify(objStorage));
                    window.location.href = '/my-account#1';
                }, 500);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    async function loginProvider() {
        const response = await loginProviderApi(formValues);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                setTimeout(() => {
                    let objStorage = { token: response.data.token, user: 2 };
                    localStorage.setItem('userToken', JSON.stringify(objStorage));
                    window.location.href = '/manufacturerAccount#2';
                }, 500);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    let handleChangeUser = () => {
        setIsManufacturer(!isManufacturer);
        manufacturer(!isManufacturer);
    }

    return (
        <>
            <div className='leftContainer'>
                {componentToast()}
                <div className='header'>
                    <div className='header-title'>
                        <h2>Inicia Sesion</h2>
                    </div>
                    <div className='header-logo'>
                        <img src="images/PROTOTIPARK_NOTION.png" alt="" />
                    </div>
                    <label className="switch">
                        <input type="checkbox" id="togBtn" onChange={handleChangeUser} />
                        <div className="slider round">
                            <span className="on"><b>Fabricante</b> </span>
                            <span className="off"><b>Diseñador</b> </span>
                        </div>
                    </label>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="formGroupExampleInput" className="form-label"><b>Email</b></label>
                        <input type="email" name="email" className="form-control" id="formGroupExampleInput" placeholder="usuario@protipark.com"
                            onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formGroupExampleInput2" className="form-label"><b>Contraseña</b></label>
                        <input type="password" name="password" className="form-control" id="formGroupExampleInput2" placeholder="Contraseña"
                            onChange={handleChange} required />
                    </div>
                    <div className='leftContainer-footer'>
                        <div className='leftContainer-footer--remember'>
                            <div className="form-check">
                                {/* <label className="form-check-label" htmlFor="gridCheck">
                                    <b>Recuérdame</b>
                                </label>
                                <input className="form-check-input" type="checkbox" id="gridCheck" /> */}
                            </div>
                            {isLoading ?
                                <Button buttonColor='primary' buttonStyle="btn--primary" type="submit" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </Button>
                                :
                                <Button type="submit" buttonStyle="btn--primary" buttonColor='primary'>INICIAR</Button>
                            }
                        </div>
                        <div className='leftContainer-footer--options'>
                            <Link to='/reset-password'>
                                <p className="text-decoration-underline">He olvidado mi contraseña</p>
                            </Link>
                            {/* <Link to='/manufacturers'>
                                <p className="text-decoration-underline">Soy fabricante</p>
                            </Link> */}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login

import React, { useState, createContext } from 'react';

export const DesignerContext = createContext();

export const DesignerProvider = props => {
    const [designerContext, setdesignerContext] = useState([]);

    return (
        <DesignerContext.Provider value={[designerContext, setdesignerContext]}>
            {props.children}
        </DesignerContext.Provider>
    );
}

import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const registerUrl = '/designers/register';

export async function registerDesignerApi(dataDesigner) {
    try {
      const response = await axios({
        url: baseUrl + registerUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: dataDesigner,
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  }
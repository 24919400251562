import React, { useState, useContext, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import { DesignerContext } from '../../../context/DesignerContext';
import '../css/manufacturerAccount.css';
import { getProviderOrdersApi } from '../services';
import ModalProviderComments from './ModalProviderComments';
import OrderDetails from './OrderDetails';
import MUIDataTable from "mui-datatables";

const columns = [
    {
        name: "#",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Descripción",
        label: "Descripción",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Cliente",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Valor $",
        label: "Valor $",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Horas de producción",
        label: "Horas de producción",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Estado",
        label: "Estado",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Fecha",
        label: "Fecha",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Acción",
        label: "Acción",
        options: {
            filter: false,
            sort: false,
        }
    }
]

const options = {
    filterType: 'checkbox',
};

function ManufacturerOrders() {
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);
    const [isCancelOrderModal, setIsCancelOrderModal] = useState({ modal: false, index: 0 });
    const [designerContext, setdesignerContext] = useContext(DesignerContext);
    const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
    const [ordersArray, setOrdersArray] = useState([]);
    const [cartInfo, setCartInfo] = useState([]);
    const [isModalOpenComments, setIsModalOpenComments] = useState(false);

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const handleShowDetailsView = (order) => {
        setCartInfo(order);
        setIsDetailViewOpen(true);
    }

    const handleVisualizeComments = (order) => {
        setCartInfo(order);
        setIsModalOpenComments(true);
    }

    const handleCloseDetailsView = () => {
        setIsDetailViewOpen(false);
    }

    const handleCloseModalComments = () => {
        // bringDataDesigner();
        setIsModalOpenComments(false);
    }

    async function bringOrdersProvider() {
        const response = await getProviderOrdersApi();
        if (typeof response !== 'undefined') {
            if (response.data.providerOrdersData) {
                let providerOrdersData = response.data.providerOrdersData;
                providerOrdersData.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
                setOrdersArray(providerOrdersData);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    const handleTotalAccount = () => {
        let totalSum = 0;
        ordersArray.forEach(element => {
            element.cartInfo.forEach(element => {
                totalSum = totalSum + element.totalPrice;
            });
        });
        return Intl.NumberFormat("de-DE").format(totalSum);
    }

    const handleCancelOrder = (e) => {
        e.preventDefault();
        setIsLoadingCancelOrder(true);
        setTimeout(() => {
            ordersArray.splice(isCancelOrderModal.index, 1); //implementar integracion con back
            isCancelOrderModal.modal = false;
            setOrdersArray([...ordersArray]);
            setIsLoadingCancelOrder(false);
            setConfigToast({ ...configToast, typeToast: "done", textToast: 'Cancelación exitosa' });
        }, 2000);

    }

    useEffect(() => {
        bringOrdersProvider();
    }, [isDetailViewOpen]);

    return (
        !isDetailViewOpen ?
            <section>
                {componentToast()}
                <div className="fourContainer">
                    <section>
                        <div className="">
                            <div className="firstContainer">
                                <div className="row">
                                    <div className="col-lg-9 py-3">
                                        <h1 style={{ marginLeft: 10 }}><b>Bienvenido {designerContext.companyName}</b></h1>
                                        <h4 style={{ marginLeft: 10 }}>Estado de cuenta: <span style={{ color: '#198754' }}>$ {handleTotalAccount()} </span></h4>
                                    </div>
                                    <div className="col-lg-3 py-4">
                                        <button className="btn btn-primary w-80" >Descargar el reporte</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="dashboardCardOrder">
                                {/* <h3>Tabla de los pedidos</h3> */}
                                <MUIDataTable
                                    title={"Lista de pedidos"}
                                    data={ordersArray.map((order, i) => {
                                        return [
                                            i + 1,
                                            order.cartInfo.map((info) => {
                                                return (
                                                    info.description
                                                )
                                            }),
                                            order.designerInfo.name + " " + order.designerInfo.lastName,
                                            order.cartInfo.map((info) => {
                                                return (
                                                    info.totalPrice
                                                )
                                            }),
                                            order.cartInfo.map((info) => {
                                                return (
                                                    info.productionTime
                                                )
                                            }),
                                            order.cartInfo.map((info) => {
                                                return (
                                                    info.stateCart ? info.stateCart : 'Pago aceptado'
                                                )
                                            }),
                                            order.cartInfo.map((info) => {
                                                return (
                                                    info.createdAt.substring(0, 10)
                                                )
                                            }),
                                            <div style={{ fontSize: 25 }}>
                                                <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-info cartButon" onClick={() => handleShowDetailsView(order)}>
                                                    <ion-icon name="eye"></ion-icon>
                                                </button>
                                                <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-secondary" onClick={() => { handleVisualizeComments(order) }}>
                                                    <ion-icon name="chatboxes"></ion-icon>
                                                </button>
                                                <button style={{ padding: 4 }} type="button" className="btn btn-danger" onClick={() => setIsCancelOrderModal({ ...isCancelOrderModal, modal: true, index: i })}>
                                                    <ion-icon name="close"></ion-icon>
                                                </button>
                                            </div>
                                        ]
                                    })}
                                    columns={columns}
                                    options={options}
                                />
                                {/* <div style={ordersArray.length > 2 ? { overflowY: 'auto', height: 210 } : null}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Cliente</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col"><abbr title="Tiempo de producción">Tiempo</abbr></th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">Fecha</th>
                                                <th scope="col">Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ordersArray.map((order, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th scope="row">{i + 1}</th>
                                                        {order.cartInfo.map((info) => {
                                                            return (
                                                                <td key={i}>{info.description}</td>
                                                            )
                                                        })}
                                                        <td>{order.designerInfo.name + " " + order.designerInfo.lastName}</td>
                                                        {order.cartInfo.map((info) => {
                                                            return (
                                                                <td key={i}>$ {Intl.NumberFormat("de-DE").format(info.totalPrice)}</td>
                                                            )
                                                        })}
                                                        {order.cartInfo.map((info) => {
                                                            return (
                                                                <td key={i}>{info.productionTime}{" horas"}</td>
                                                            )
                                                        })}
                                                        {order.cartInfo.map((info) => {
                                                            return (
                                                                <td key={i}>{info.stateCart ? info.stateCart : 'Pago aceptado'}</td>
                                                            )
                                                        })}
                                                        {order.cartInfo.map((info) => {
                                                            return (
                                                                <td key={i}>{info.createdAt.substring(0, 10)}</td>
                                                            )
                                                        })}
                                                        <td>
                                                            <div style={{ fontSize: 25 }}>
                                                                <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-info cartButon" onClick={() => handleShowDetailsView(order)}>
                                                                    <ion-icon name="eye"></ion-icon>
                                                                </button>
                                                                <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-secondary" onClick={() => { handleVisualizeComments(order) }}>
                                                                    <ion-icon name="chatboxes"></ion-icon>
                                                                </button>
                                                                <button style={{ padding: 4 }} type="button" className="btn btn-danger" onClick={() => setIsCancelOrderModal({ ...isCancelOrderModal, modal: true, index: i })}>
                                                                    <ion-icon name="close"></ion-icon>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className={`modal fade registerModal ${isCancelOrderModal.modal ? 'show' : ''}`}
                        style={{ display: `${isCancelOrderModal.modal ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }} id="deleteModal" tabIndex="1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">¿Desea cancelar la orden?</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsCancelOrderModal({ ...isCancelOrderModal, modal: false })}></button>
                                </div>
                                <form onSubmit={handleCancelOrder}>
                                    <div className="modal-body">
                                        <label htmlFor="">¿Por qué deseas cancelar la orden?</label>
                                        <textarea name="deleteReason" id="" cols="5" rows="5" className="form-control" required></textarea>
                                        <label htmlFor="">Escribe tu contraseña para confirmar</label>
                                        <input type="password" className="form-control" required />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setIsCancelOrderModal({ ...isCancelOrderModal, modal: false })}>Cerrar</button>
                                        {isLoadingCancelOrder ?
                                            <button type="submit" className="btn btn-danger" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button>
                                            :
                                            <button type="submit" className="btn btn-danger">Confirmar</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalProviderComments isModalOpen={isModalOpenComments} cartInfo={cartInfo} handleCloseModalComments={handleCloseModalComments} />
            </section >
            :
            <OrderDetails handleCloseDetailsView={handleCloseDetailsView} cartInfo={cartInfo} />
    )
}

export default ManufacturerOrders

import React, { useContext } from 'react';
import '../css/style.css';
import { GrSecure } from 'react-icons/gr';
import { faCcVisa, faCcMastercard, faCcDinersClub, faCcPaypal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CartContext } from '../../../context/CartContext';
import { Link } from 'react-router-dom';

function ShoppingCartPayment() {
    const [cartsContext, setCartsContext] = useContext(CartContext);

    let subTotalCount = 0;

    let handlePrice = () => {
        if (cartsContext.length !== 0) {
            cartsContext.forEach(element => {
                subTotalCount = subTotalCount + element.totalPrice;
            });
            return <p>{"$ "} {Intl.NumberFormat("de-DE").format(subTotalCount)}</p>
        } else {
            return null
        }
    }

    return (
        <>
            <div className="card">
                <div className='paymentCardTitle'>
                    <h3>Pago</h3>
                </div>
                <div className="cardBody">
                    <div className="cardBodysec">
                        <label><b>Items en el carrito</b></label>
                        <p>{cartsContext.length}</p>
                    </div>
                    <div className="cardBodysec">
                        <label><b>Subtotal</b></label>
                        {handlePrice()}
                    </div>
                    <div className="cardBodysec">
                        <label><b>Impuestos </b></label>
                        <p>19%</p>
                    </div>
                    <div className="cardBodysec">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="¿Tienes un cupón?" aria-label="Recipient's cupon" aria-describedby="button-addon2" />
                            <button className="btn btn-primary" type="button" id="button-addon2">Validar</button>
                        </div>
                    </div>
                    <div className="cardBodysec">
                        <label><b>Total</b></label>
                        <p>{"$ "} {Intl.NumberFormat("de-DE").format((subTotalCount * 0.19) + subTotalCount)}</p>
                    </div>
                    <div className="cardButtonSec">
                        <div className="cardPaymentButton">
                            {cartsContext.length !== 0 ?
                                <Link to='/order-summary' className='btn-link' style={{ padding: 0 }}>
                                    <button className="btn btn-primary" style={{ width: '100%' }}>Ordenar</button>
                                </Link>
                                :
                                <button className="btn btn-primary" disabled>Ordenar</button>
                            }
                        </div>
                        <div>
                            <Link to='/services' className='btn-link' style={{ padding: 0 }}>
                                <button className="btn btn-outline-primary" style={{ width: '100%' }}>+ Añadir items</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="cardFooter">
                    <div className='paymentIcons'>
                        <FontAwesomeIcon icon={faCcVisa} />{" "}
                        <FontAwesomeIcon icon={faCcMastercard} />{" "}
                        <FontAwesomeIcon icon={faCcDinersClub} />{" "}
                        <FontAwesomeIcon icon={faCcPaypal} />{" "}
                    </div>
                    <div className="securePayment">
                        <GrSecure /> Pago seguro
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShoppingCartPayment

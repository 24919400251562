import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import '../css/GerberFile.css';

function CardForm({ bringCardInfo, bringMaker }) {
    const [isCardEdit, setIsCardEdit] = useState(false);
    const [currentDate, setCurrentDate] = useState("");
    const [formValues, setFormValues] = useState({
        layers: 'layer_1',
        dimensionX: 100,
        dimensionY: 100,
        qty: 5,
        format: 'format_0',
        material: 'material_1',
        thickness: 'thickness_6',
        minTrackSpacing: 'min_track_spacing_4',
        minHoleSize: 'min_holesize_4',
        soldermaskColor: 'soldermask_0',
        silkscreenColor: 'silkscreen_0',
        surfaceFinish: 'surface_0',
        copperWeight: 'copper_0',
        goldFinger: false,
        castellatedHoles: false,
        remark: '',
        smtAssembly: '',
        smtStencil: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let ObjDimension = {
            dimension_x: formValues.dimensionX,
            dimension_y: formValues.dimensionY
        };
        formValues.dimensions = ObjDimension;
        if (!isCardEdit) {
            if (formValues.remark !== "" && Object.values(formValues.remark).length < 22) {
                let comment = currentDate + " Diseñador: " + formValues.remark;
                formValues.remark = comment;
            }
        }
        bringCardInfo(formValues);
    }

    const handleGetUrlParam = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setFormValues({
            ...formValues, layers: urlParams.get('lay'),
            dimensionX: urlParams.get('dimX'),
            dimensionY: urlParams.get('dimY')
        });
    }

    const getCurrentDate = () => {
        let tempDate = new Date();
        if (tempDate.getMonth() < 10) {
            if (tempDate.getDate() < 10) {
                let date = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            } else {
                let date = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            }
        } else {
            if (tempDate.getDate() < 10) {
                let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            } else {
                let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            }
        }
    }

    useEffect(() => {
        getCurrentDate();
        if (window.location.search) {
            handleGetUrlParam();
        }
    }, []);

    useEffect(() => {
        setIsCardEdit(bringMaker);
    }, [bringMaker]);

    return (
        <>
            <div className='card'>
                <form className='cardForm' onSubmit={handleSubmit} >
                    <div className='cardFormHeader'>
                        <h4>Propiedades de tu PCB</h4>
                        <h6>Selecciona los parámetros</h6>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputLayer" className="col-sm-3 col-form-label">Capas:</label>
                            <div className="col-sm-4">
                                <select className="form-select" aria-label="layer select" id="layerSelect" name="layers"
                                    onChange={handleChange} value={formValues.layers} disabled={isCardEdit}>
                                    <option hidden>Seleccionar</option>
                                    <option value="layer_0">1</option>
                                    <option value="layer_1">2</option>
                                    <option value="layer_2">4</option>
                                    <option value="layer_3">6</option>
                                    <option value="layer_4">8</option>
                                    <option value="layer_5">10</option>
                                    <option value="layer_6">12</option>
                                    <option value="layer_7">14</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputdimensions" className="col-sm-3 col-form-label">Dimensiones:</label>
                            <div className="col-md-6 dimensionBox">
                                <input type="number" placeholder="X" className="form-control" id="inputDimensionX" style={{ marginRight: 10 }}
                                    name="dimensionX" onChange={handleChange} value={formValues.dimensionX} disabled={isCardEdit} />
                                <input type="number" placeholder="Y" className="form-control" id="inputDimensionY" style={{ marginRight: 10 }}
                                    name="dimensionY" onChange={handleChange} value={formValues.dimensionY} disabled={isCardEdit} />
                                <label htmlFor="">mm</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputQty" className="col-sm-3 col-form-label">Cantidad:</label>
                            <div className="col-md-2">
                                <input type="number" className="form-control" id="inputQty" placeholder='Unty'
                                    name="qty" onChange={handleChange} value={formValues.qty} disabled={isCardEdit} />
                            </div>
                        </div>
                    </div>
                    <fieldset className="row mb-3">
                        <legend className="col-form-label col-sm-3 pt-0">Formato:</legend>
                        <div className="col-sm-8">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="format" id="formatRadios1"
                                    name="format" onChange={handleChange} value="format_0" checked={formValues.format === "format_0"} disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="gridRadios1">
                                    Single PCB
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="format" id="formatRadios2"
                                    name="format" onChange={handleChange} value="format_1" disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="gridRadios2">
                                    Designer panel
                                </label>
                            </div>
                            <div className="form-check disabled">
                                <input className="form-check-input" type="radio" name="format" id="formatRadios3"
                                    name="format" onChange={handleChange} value="format_2" disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="gridRadios3">
                                    Protipark panel
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputMaterial" className="col-sm-3 col-form-label">Material:</label>
                            <div className="col-sm-4">
                                <select className="form-select" aria-label="Material select" id="materialgSelect"
                                    name="material" onChange={handleChange} value={formValues.material} disabled={isCardEdit}>
                                    <option hidden>Seleccionar</option>
                                    <option value="material_0">FR2</option>
                                    <option value="material_1">FR4-TG130-140</option>
                                    <option value="material_2">FR4-TG150-160</option>
                                    <option value="material_3">FR4-TG170-180</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputThickness" className="col-sm-3 col-form-label">Thickness:</label>
                            <div className="col-sm-4">
                                <select className="form-select" aria-label="thickness select" id="thicknessSelect"
                                    name="thickness" onChange={handleChange} value={formValues.thickness} disabled={isCardEdit}>
                                    <option hidden>Seleccionar</option>
                                    <option value="thickness_0">0.2</option>
                                    <option value="thickness_1">0.4</option>
                                    <option value="thickness_2">0.6</option>
                                    <option value="thickness_3">0.8</option>
                                    <option value="thickness_4">1.2</option>
                                    <option value="thickness_5">1.4</option>
                                    <option value="thickness_6">1.6</option>
                                    <option value="thickness_7">1.8</option>
                                    <option value="thickness_8">2.0</option>
                                    <option value="thickness_9">2.4</option>
                                    <option value="thickness_10">2.8</option>
                                    <option value="thickness_11">3.0</option>
                                    <option value="thickness_12">3.2</option>
                                    <option value="thickness_13">3.6</option>
                                    <option value="thickness_14">3.8</option>
                                    <option value="thickness_15">4.0</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputTrackSpacing" className="col-sm-3 col-form-label">Min Track/Spacing:</label>
                            <div className="col-sm-4">
                                <select className="form-select" aria-label="trackSpacing select" id="trackSpacingSelect"
                                    name="minTrackSpacing" onChange={handleChange} value={formValues.minTrackSpacing} disabled={isCardEdit} >
                                    <option hidden>Seleccionar</option>
                                    <option value="min_track_spacing_0">3/3 mil</option>
                                    <option value="min_track_spacing_1">4/4 mil</option>
                                    <option value="min_track_spacing_2">5/5 mil</option>
                                    <option value="min_track_spacing_3">6/6 mil</option>
                                    <option value="min_track_spacing_4">8/8 mil</option>
                                    <option value="min_track_spacing_5">10/10 mil</option>
                                    <option value="min_track_spacing_6">from 15/15 mil</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputHoleSize" className="col-sm-3 col-form-label">Min Hole size:</label>
                            <div className="col-sm-4">
                                <select className="form-select" aria-label="holeSize select" id="holeSizeSelect"
                                    name="minHoleSize" onChange={handleChange} value={formValues.minHoleSize} disabled={isCardEdit}>
                                    <option hidden>Seleccionar</option>
                                    <option value="min_holesize_0">0.15 mm</option>
                                    <option value="min_holesize_1">0.20 mm</option>
                                    <option value="min_holesize_2">0.25 mm</option>
                                    <option value="min_holesize_3">0.30 mm</option>
                                    <option value="min_holesize_4">0.40 mm</option>
                                    <option value="min_holesize_5">from 0.50 mm</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputSolderMaskColor" className="col-sm-3 col-form-label">Mask color:</label>
                            <div className="col-sm-4">
                                <select className="form-select" aria-label="solderMaskColor select" id="solderMaskColorSelect"
                                    name="soldermaskColor" onChange={handleChange} value={formValues.soldermaskColor} disabled={isCardEdit} >
                                    <option >Seleccionar</option>
                                    <option value="soldermask_0">Green</option>
                                    <option value="soldermask_1">Red</option>
                                    <option value="soldermask_2">Yellow</option>
                                    <option value="soldermask_3">Blue</option>
                                    <option value="soldermask_4">Purple</option>
                                    <option value="soldermask_5">White</option>
                                    <option value="soldermask_6">Black</option>
                                    <option value="soldermask_7">None</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="silkScreenColor" className="col-sm-3 col-form-label">Silk color:</label>
                            <div className="col-sm-4">
                                <select className="form-select" aria-label="silkScreenColor select" id="silkScreenColorSelect"
                                    name="silkscreenColor" onChange={handleChange} value={formValues.silkscreenColor} disabled={isCardEdit}>
                                    <option >Seleccionar</option>
                                    <option value="silkscreen_0">White</option>
                                    <option value="silkscreen_1">Black</option>
                                    <option value="silkscreen_2">Yellow</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <fieldset className="row mb-3">
                        <legend className="col-form-label col-sm-3 pt-0">SurfaceFinish:</legend>
                        <div className="col-sm-8" >
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="surfaceFinish" id="surfaceFinish1"
                                    name="surfaceFinish" onChange={handleChange} value="surface_0" checked={formValues.surfaceFinish === "surface_0"} disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="surfaceRadios1">
                                    HASL-Lead
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="surfaceFinish" id="surfaceFinish2"
                                    name="surfaceFinish" onChange={handleChange} value="surface_1" disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="surfaceRadios2">
                                    HASL-RoHS
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="surfaceFinish" id="surfaceFinish3"
                                    name="surfaceFinish" onChange={handleChange} value="surface_2" disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="surfaceRadios3">
                                    ENIG-RoHS
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="row mb-3">
                        <legend htmlFor="inputCooperWeight" className="col-sm-3 col-form-label">copperWeight:</legend>
                        <div className="col-sm-8" >
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="cooperRadios" id="cooperWeight1"
                                    name="copperWeight" onChange={handleChange} value="copper_0" checked={formValues.copperWeight === "copper_0"} disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="cooperRadios3">
                                    1 Oz
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="cooperRadios" id="cooperWeight2"
                                    name="copperWeight" onChange={handleChange} value="copper_1" disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="cooperRadios3">
                                    2 Oz
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputMaterial" className="col-sm-3 col-form-label">Goldfinger:</label>
                            <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3 }}>
                                <input className="form-check-input" type="checkbox" id="goldFinger"
                                    name="goldFinger" onChange={(e) => { setFormValues({ ...formValues, goldFinger: !formValues.goldFinger }) }}
                                    checked={formValues.goldFinger} disabled={isCardEdit} />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputMaterial" className="col-sm-3 col-form-label">CastellatedHoles:</label>
                            <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3 }}>
                                <input className="form-check-input" type="checkbox" id="castellateHoles"
                                    name="castellatedHoles" onChange={(e) => { setFormValues({ ...formValues, castellatedHoles: !formValues.castellatedHoles }) }}
                                    checked={formValues.castellatedHoles} disabled={isCardEdit} />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 optionContainer">
                            <label htmlFor="inputComments" className="col-sm-3 col-form-label">Comentarios:</label>
                            <div className="col-md-7">
                                <textarea type="text" className="form-control" id="inputOrderComment" placeholder='Comments'
                                    name="remark" onChange={handleChange} value={formValues.remark} disabled={isCardEdit} />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-10">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="gridCheck1" disabled={isCardEdit} />
                                <label className="form-check-label" htmlFor="gridCheck1">
                                    Guardar Diseño en mi perfil
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb'3">
                        {isCardEdit ?
                            <div className="col'sm'4">
                                <button type="submit" className="btn btn-success">Editar</button>

                            </div>
                            :
                            <div className="col'sm'4">
                                <button type="submit" className="btn btn-primary">Cotizar</button>

                            </div>
                        }


                    </div>
                </form>
            </div>
        </>
    )
}

export default CardForm;

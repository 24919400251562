import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

function ProviderDetails({ handleCloseDetailsProvider, providerInfo }) {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    const [isEdit, setIsEdit] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isPaymentModal, setIsPaymentModal] = useState(false);
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);

    const [manufacturerInfo, setManufacturerInfo] = useState({
        profileImage: "",
        companyName: "",
        nitNumber: "",
        nitDiv: "",
        email: "",
        phoneNumber: "",
        companyAdress: "",
        city: "",
        country: "",
        description: "",
        website: "",
        companyType: "",
        companyTypeIdentifier: "",
        obligationCodes: "",
        regime: "",
        password: "",
        stateAccount: ""
    });

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const handleFile = (e) => {
        let files = e.target.files;
        let base64Data;
        if (files.length !== 0) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                base64Data = e.target.result;
                setManufacturerInfo({ ...manufacturerInfo, profileImage: base64Data });
            }
        }
    }

    async function bringDataProvider() {
        setManufacturerInfo({
            ...manufacturerInfo,
            profileImage: providerInfo.profileImage,
            companyName: providerInfo.companyName,
            nitNumber: providerInfo.nitNumber,
            nitDiv: providerInfo.nitDiv,
            email: providerInfo.email,
            phoneNumber: providerInfo.phoneNumber,
            companyAdress: providerInfo.city,
            city: providerInfo.city,
            country: providerInfo.country,
            description: providerInfo.description,
            website: providerInfo.website,
            companyType: providerInfo.companyType,
            companyTypeIdentifier: providerInfo.companyTypeIdentifier,
            obligationCodes: providerInfo.obligationCodes,
            stateAccount: providerInfo.paymentInformation,

        });
    }

    const handlePaymentProvider = (e) => {
        e.preventDefault();
        setIsLoadingPayment(true);
        setTimeout(() => {
            // console.log(providerInfo._id);
            setIsPaymentModal(false);
            setIsLoadingPayment(true);
            setConfigToast({ ...configToast, typeToast: "done", textToast: 'Generación exitosa' });
        }, 2000);
    }

    useEffect(() => {
        bringDataProvider();

    }, [])

    return (
        <div>
            {componentToast()}
            <div className="secondContainer">
                <div className="row mb-3">
                    <div className="div-xl-12">
                        <h3><button type="button" className="btn btn-outline-secondary btn-lg" style={{ margin: 10 }}
                            onClick={() => handleCloseDetailsProvider()}>
                            <div style={{ marginTop: 5 }}><ion-icon name="arrow-back" /></div>
                        </button>Proveedor: {providerInfo.companyName}
                            <button type="submit" className="btn btn-success" style={{ marginLeft: 450 }} onClick={() => setIsPaymentModal(true)}>Generar comprobante de pago</button>
                        </h3>
                    </div>
                </div>
                <div className="informationContainer-Section">
                    <form className="row g-3" >
                        <label htmlFor="inputUsername" className="form-label"><b>Foto de Perfil</b></label>
                        <div className="col-lg-12">
                            {manufacturerInfo.profileImage !== '' ?
                                <img src={manufacturerInfo.profileImage} alt="uploadImage" style={{ width: 110, marginBottom: 15 }} />
                                :
                                <img src="https://bulma.io/images/placeholders/128x128.png" alt="Empty" style={{ width: 110, marginBottom: 15 }} />
                            } <br />
                            <input className="form-control" type="file" id="formFile" onChange={handleFile} disabled={!isEdit} />
                        </div>
                        <div className="col-md-7">
                            <label htmlFor="inputCompanyName" className="form-label">Nombre de la empresa *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.companyName} id="manufacturerName"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyName: e.target.value }) }}
                                name="companyName" required disabled={!isEdit} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputCompanyNit" className="form-label">N.I.T de la empresa *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.nitNumber} id="manufacturerNit"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, nitNumber: e.target.value }) }}
                                name="nitNumber" required disabled={!isEdit} />
                        </div>
                        <div className="col-md-1">
                            <label htmlFor="inputCompanyNit" className="form-label">N.I.T Div *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.nitDiv} id="nitDiv"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, nitDiv: e.target.value }) }}
                                name="nitDiv" required disabled={!isEdit} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="inputCompanyName" className="form-label">Tipo de empresa *</label>
                            <select className="form-select" aria-label="Material select" id="companyType"
                                name="companyType" onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyType: e.target.value }) }}
                                value={manufacturerInfo.companyType} required disabled={!isEdit}>
                                <option hidden>Seleccionar</option>
                                <option value="1">Persona jurídica</option>
                                <option value="2">Persona natural</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="inputCompanyNit" className="form-label">ID tipo de empresa *</label>
                            <select className="form-select" aria-label="Material select" id="companyTypeIdentifier"
                                name="companyTypeIdentifier" onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyTypeIdentifier: e.target.value }) }}
                                value={manufacturerInfo.companyTypeIdentifier} required disabled={!isEdit}>
                                <option hidden>Seleccionar</option>
                                <option value="1">Documento de identificación extranjero</option>
                                <option value="2">Tarjeta de identidad</option>
                                <option value="3">Cédula de ciudadanía</option>
                                <option value="4">Pasaporte</option>
                                <option value="5">NIT</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="inputCompanyNit" className="form-label">Codigo de obligacion *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.obligationCodes} id="obligationCodes"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, obligationCodes: e.target.value }) }}
                                name="obligationCodes" required disabled={!isEdit} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="inputCompanyNit" className="form-label">Regimen *</label>
                            <select className="form-select" aria-label="Material select" id="regime"
                                name="regime" onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, regime: e.target.value }) }}
                                value={manufacturerInfo.regime} required disabled={!isEdit}>
                                <option hidden>Seleccionar</option>
                                <option value="1">Regimen simple</option>
                                <option value="2">Regimen ordinario</option>
                                <option value="3">Gran contribuyente</option>
                                <option value="4">Autorretenedor</option>
                                <option value="5">Agente de retención IVA</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputCompanyEmail" className="form-label">Email *</label>
                            <input type="email" className="form-control" value={manufacturerInfo.email} id="contactEmail"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, email: e.target.value }) }}
                                name="email" required disabled={!isEdit} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputCompanyNumber" className="form-label">Teléfono *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.phoneNumber} id="contactNumber"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, phoneNumber: e.target.value }) }}
                                name="phoneNumber" required disabled={!isEdit} />
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="inputCompanyAdress" className="form-label">Dirección *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.companyAdress} id="companyAdress"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyAdress: e.target.value }) }}
                                name="companyAdress" required disabled={!isEdit} />
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="inputCompanyCity" className="form-label">Ciudad *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.city} id="companyCity"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, city: e.target.value }) }}
                                name="city" required disabled={!isEdit} />
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="inputCompanyCountry" className="form-label">País *</label>
                            <input type="text" className="form-control" value={manufacturerInfo.country} id="companyCountry"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, country: e.target.value }) }}
                                name="country" required disabled={!isEdit} />
                        </div>
                        <div className="col-lg-12">
                            <label htmlFor="inputCompanyWebsite" className="form-label">Website </label>
                            <input type="text" className="form-control" value={manufacturerInfo.website} id="companyWebPage"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, website: e.target.value }) }}
                                name="website" required disabled={!isEdit} />
                        </div>
                        <div className="col-lg-12">
                            <label htmlFor="inputCompanyDescription" className="form-label">Descripción </label>
                            <input type="text" className="form-control" value={manufacturerInfo.description} id="companyDescription"
                                onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, description: e.target.value }) }}
                                name="description" required disabled={!isEdit} />
                        </div>
                        {isEdit ?
                            <>
                                <label htmlFor="inputPassword" className="form-label"><b>Contraseña Actual *</b></label>
                                {isShowPass ?
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" id="inputPasswordHide" placeholder="Contraseña Actual" name="password"
                                            onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, password: e.target.value }) }}
                                            autoComplete="on" value={manufacturerInfo.password} required />
                                        <span className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEyeSlash} /></span>
                                    </div>
                                    :
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" id="inputPasswordShow" placeholder="Contraseña Actual" name="password"
                                            onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, password: e.target.value }) }}
                                            autoComplete="on" value={manufacturerInfo.password} required />
                                        <span className="input-group-text" id="basic-addon2" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEye} /></span>
                                    </div>
                                }
                            </>
                            : null}
                        {!isEdit ?
                            <div className="col-4">
                                <button type="button" className="btn btn-primary" onClick={() => { setIsEdit(!isEdit) }}>Editar</button>
                            </div>
                            :
                            <>
                                <div className="col-md-2">
                                    <button type="button" className="btn btn-danger" onClick={() => { setIsEdit(!isEdit) }}>Cancelar</button>
                                </div>
                                {isLoading ?
                                    <div className="col-md-2">
                                        <button className="btn btn-primary" type="submit" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                    </div>
                                    :
                                    <div className="col-md-2">
                                        <button type="submit" className="btn btn-success" >Guardar</button>
                                    </div>
                                }
                            </>
                        }
                    </form>
                </div>
                <div className={`modal fade registerModal ${isPaymentModal ? 'show' : ''}`}
                    style={{ display: `${isPaymentModal ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }} id="deleteModal" tabIndex="1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Comprobante de pago</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsPaymentModal(false)}></button>
                            </div>
                            <form onSubmit={handlePaymentProvider}>
                                <div className="modal-body">
                                    <label htmlFor="">Escribe la fecha incial</label>
                                    <input type="date" className="form-control" required />
                                    <label htmlFor="">Escribe la fecha final</label>
                                    <input type="date" className="form-control" required /><br />
                                    <label htmlFor="">Escribe el monto</label>
                                    <input type="number" className="form-control" required /><hr />
                                    {/* <label htmlFor="">Por el monto de $ {Intl.NumberFormat("de-DE").format(manufacturerInfo.stateAccount)}</label><hr /> */}
                                    <label htmlFor="">Escribe tu contraseña para confirmar</label>
                                    <input type="password" className="form-control" required />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setIsPaymentModal(false)}>Cerrar</button>
                                    {isLoadingPayment ?
                                        <button type="submit" className="btn btn-danger" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                        :
                                        <button type="submit" className="btn btn-danger">Confirmar</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProviderDetails
import React from 'react'
import '../css/style.css'

function Faq() {
    return (
        <div>
            <div className="AboutUs__hero-section darkbg">
                <div className="container">
                    <h3 className="dark">FAQ</h3>
                    <p className="dark">First question</p>
                    <p className="dark">Answers</p>
                </div>
            </div>
        </div>
    )
}

export default Faq

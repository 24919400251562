import React, { useState } from 'react';
import Login from './components/Login';
import RegisterLink from './components/RegisterLink';
import './css/login.css';
import '../../css/principal.css';

function IndexLogin() {
    const [isManufacturer, setIsManufacturer] = useState(false);

    const handleManufacturer = (values) => {
        setIsManufacturer(values);
    }

    return (
        <div className='principalContainer'>
            <div className='mainWrapper'>
                <div className="row">
                    <div className="col-md-6">
                        <Login manufacturer={handleManufacturer} />
                    </div>
                    <div className="col-md-6 rightContainer">
                        <RegisterLink manufacturer={isManufacturer} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndexLogin

import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import { fecthUpdateStateCartOrderProviderApi } from '../../ManufacturerAccount/services';


const ModalCartDelivery = ({ isModalOpen, cartInfo, handleCloseModalDelivery }) => {
    const [selectItem, setSelectItem] = useState({
        index: 0,
        cartSelect: {},
        rows: 0
    });

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const [stateFinish, setStateFinish] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function handleChangeState(e) {
        e.preventDefault();
        setIsLoading(true);
        let objState = {
            orderId: cartInfo.orderId,
            stateCart: "Finalizado",
            cartId: selectItem.cartSelect._id
        }
        const response = await fecthUpdateStateCartOrderProviderApi(objState);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                selectItem.cartSelect.stateCart = "Finalizado";
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setStateFinish(true);
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    useEffect(() => {
        let indexCart = cartInfo.indexCart;
        if (Object.keys(cartInfo).length !== 0 && isModalOpen) {
            console.log(cartInfo[indexCart]);
            cartInfo.forEach(element => {
                if (element.deliveryCode) {
                    setSelectItem({ ...selectItem, index: 0, cartSelect: cartInfo[indexCart] });
                }
            });
        }
    }, [isModalOpen]);

    return (
        <>
            <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                id="registerModal" tabIndex="1" aria-labelledby="registerModalLabel" aria-hidden="true">
                {componentToast()}
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Guia de seguimiendo del envío</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModalDelivery(false)}></button>
                        </div>
                        <div className="modal-body" style={{ height: 450, overflowY: 'auto' }}>
                            <form className="row mb-3" onSubmit={handleChangeState}>
                                <div className="col-lg-12 optionContainer">
                                    <div className="col-md-3 optionContainer" style={{ alignItems: 'center' }}>
                                        <label htmlFor="inputDeliveryCode" className="form-label">Guia de envío:</label><br />
                                        <br />
                                    </div>
                                    <div className="col-md-5 optionContainer">
                                        <input type="text" className="form-control" value={selectItem.cartSelect.deliveryCode} id="deliveryCode"
                                            name="stateCartDelivery" disabled />
                                    </div>
                                </div>
                                <div className="col-xl-12" >
                                    <br />
                                    {stateFinish ?
                                        <label htmlFor="" style={{ color: '#198754' }}>Haz confirmado que el producto fue recibido satisfactoriamente.</label>
                                        :
                                        <>
                                            <ion-icon name="information-circle-outline"></ion-icon>
                                            <label htmlFor="">Debes confirmar que el pedido fue recibido.</label>
                                        </>
                                    }
                                    <hr />
                                    {isLoading ?
                                        <button className="btn btn-primary" type="submit" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                        :
                                        stateFinish ?
                                            <button type="button" className="btn btn-success" disabled>Recibido</button>
                                            :
                                            <button type="button" className="btn btn-primary" onClick={(e) => handleChangeState(e)}>Recibido</button>
                                    }
                                </div>
                            </form>
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => handleCloseModalDelivery(false)}>Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalCartDelivery;



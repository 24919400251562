import React, { useState, useEffect } from 'react';

const ModalQuoterAdmin = ({ isModalOpen, quoterInfo, handleCloseQuoterModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [changeValue, setChangeValue] = useState(false);

    const [haveData, setHaveData] = useState(false);
    const [fixedCost, setFixedCost] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    const [layersCostArray, setLayersCostArray] = useState([
        { id: 1, name: "layer_0", value: -0.10, checked: false },
        { id: 2, name: "layer_1", value: 0.00, checked: false },
        { id: 4, name: "layer_2", value: 0.20, checked: false },
        { id: 8, name: "layer_3", value: 0.25, checked: false },
        { id: 10, name: "layer_4", value: 0.30, checked: false },
        { id: 12, name: "layer_5", value: 0.35, checked: false },
        { id: 14, name: "layer_6", value: 0.40, checked: false }
    ]);

    const [dimensions, setDimensions] = useState({
        dimension_x_min: 0,
        dimension_x_max: 0,
        dimension_y_min: 0,
        dimension_y_max: 0
    });

    const [volume, setVolume] = useState({
        volume_min: 0,
        volume_max: 0,
    });

    const [formatValues, setFormatValues] = useState([
        { id: 1, type: "Single PCB", name: "format_0", value: 0.00, checked: false },
        { id: 2, type: "Designer Panel", name: "format_1", value: 0.10, checked: false },
        { id: 3, type: "Protipark panel", name: "format_2", value: 0.05, checked: false },
    ]);

    const [materialValues, setMaterialValues] = useState([
        { id: 1, type: "FR2", name: "material_0", value: 0.20, checked: false },
        { id: 2, type: "FR4-TG130-140", name: "material_1", value: 0.00, checked: false },
        { id: 3, type: "FR4-TG150-160", name: "material_2", value: 0.20, checked: false },
        { id: 4, type: "FR4-TG170-180", name: "material_3", value: 0.30, checked: false },
    ]);

    const [thicknessValues, setThicknessValues] = useState([
        { id: 1, type: "0.2", name: "thickness_0", value: 0.30, checked: false },
        { id: 2, type: "0.4", name: "thickness_1", value: 0.00, checked: false },
        { id: 3, type: "0.6", name: "thickness_2", value: 0.30, checked: false },
        { id: 4, type: "0.8", name: "thickness_3", value: 0.30, checked: false },
        { id: 5, type: "1.2", name: "thickness_4", value: 0.30, checked: false },
        { id: 6, type: "1.4", name: "thickness_5", value: 0.00, checked: false },
        { id: 7, type: "1.6", name: "thickness_6", value: 0.30, checked: false },
        { id: 8, type: "1.8", name: "thickness_7", value: 0.30, checked: false },
        { id: 9, type: "2.0", name: "thickness_8", value: 0.30, checked: false },
        { id: 10, type: "2.4", name: "thickness_9", value: 0.00, checked: false },
        { id: 11, type: "2.8", name: "thickness_10", value: 0.30, checked: false },
        { id: 12, type: "3.0", name: "thickness_11", value: 0.30, checked: false },
        { id: 13, type: "3.2", name: "thickness_12", value: 0.30, checked: false },
        { id: 14, type: "3.6", name: "thickness_13", value: 0.00, checked: false },
        { id: 15, type: "3.8", name: "thickness_14", value: 0.30, checked: false },
        { id: 16, type: "4.0", name: "thickness_15", value: 0.30, checked: false },
    ]);

    const [minTrackValues, setMinTrackValues] = useState([
        { id: 1, type: "3/3 mil", name: "min_track_spacing_0", value: 0.20, checked: false },
        { id: 2, type: "4/4 mil", name: "min_track_spacing_1", value: 0.00, checked: false },
        { id: 3, type: "5/5 mil", name: "min_track_spacing_2", value: 0.20, checked: false },
        { id: 4, type: "6/6 mil", name: "min_track_spacing_3", value: 0.30, checked: false },
        { id: 5, type: "8/8 mil", name: "min_track_spacing_4", value: 0.20, checked: false },
        { id: 6, type: "10/10 mil", name: "min_track_spacing_5", value: 0.30, checked: false },
        { id: 7, type: "from 15/15 mil", name: "min_track_spacing_6", value: 0.30, checked: false },
    ]);

    const [minHoleValues, setMinHoleValues] = useState([
        { id: 1, type: "0.15 mm", name: "min_hole_size_0", value: 0.20, checked: false },
        { id: 2, type: "0.20 mm", name: "min_hole_size_1", value: 0.00, checked: false },
        { id: 3, type: "0.25 mm", name: "min_hole_size_2", value: 0.20, checked: false },
        { id: 4, type: "0.30 mm", name: "min_hole_size_3", value: 0.30, checked: false },
        { id: 5, type: "0.40 mm", name: "min_hole_size_4", value: 0.20, checked: false },
        { id: 6, type: "from 0.50 mm", name: "min_hole_size_5", value: 0.30, checked: false }
    ]);

    const [soldermaskValues, setSoldermaskValues] = useState([
        { id: 1, type: "Green", name: "soldermask_0", value: 0.20, checked: false },
        { id: 2, type: "Red", name: "soldermask_1", value: 0.00, checked: false },
        { id: 3, type: "Yellow", name: "soldermask_2", value: 0.20, checked: false },
        { id: 4, type: "Blue", name: "soldermask_3", value: 0.30, checked: false },
        { id: 5, type: "Purple", name: "soldermask_4", value: 0.20, checked: false },
        { id: 6, type: "White", name: "soldermask_5", value: 0.30, checked: false },
        { id: 7, type: "Black", name: "soldermask_6", value: 0.20, checked: false },
        { id: 8, type: "None", name: "soldermask_7", value: 0.30, checked: false }
    ]);

    const [silkScreenValues, setSilkScreenValues] = useState([
        { id: 1, type: "White", name: "silkscreen_0", value: 0.20, checked: false },
        { id: 2, type: "Black", name: "silkscreen_1", value: 0.00, checked: false },
        { id: 3, type: "Yellow", name: "silkscreen_2", value: 0.20, checked: false }
    ]);

    const [surfaceFinishValues, setSurfaceFinishValues] = useState([
        { id: 1, type: "HASL-Lead", name: "surface_0", value: 0.20, checked: false },
        { id: 2, type: "HASL-RoHS", name: "surface_1", value: 0.00, checked: false },
        { id: 3, type: "ENIG-RoHS", name: "surface_2", value: 0.20, checked: false }
    ]);

    const [copperValues, setCopperValues] = useState([
        { id: 1, type: "1 Oz", name: "copper_0", value: 0.20, checked: false },
        { id: 2, type: "2 Oz", name: "copper_1", value: 0.00, checked: false }
    ]);

    const [goldFingerValue, setGoldFingerValue] = useState([
        { id: 1, name: "gold_0", value: 0.20, checked: false },
    ]);

    const [castellatedValue, setCastellatedValue] = useState([
        { id: 1, name: "castellated_0", value: 0.20, checked: false },
    ]);


    const handleChangeLayers = (e) => {
        if (e.target.checked !== false) {
            let objIndex = layersCostArray.findIndex((obj => obj.name === e.target.value));
            layersCostArray[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = layersCostArray.findIndex((obj => obj.name === e.target.value));
            layersCostArray[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleDimensions = (e) => {
        const { name, value } = e.target;
        setDimensions({ ...dimensions, [name]: value });
    }

    const handleChangeFormat = (e) => {
        if (e.target.checked !== false) {
            let objIndex = formatValues.findIndex((obj => obj.name === e.target.value));
            formatValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = formatValues.findIndex((obj => obj.name === e.target.value));
            formatValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeMaterial = (e) => {
        if (e.target.checked !== false) {
            let objIndex = materialValues.findIndex((obj => obj.name === e.target.value));
            materialValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = materialValues.findIndex((obj => obj.name === e.target.value));
            materialValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeThickness = (e) => {
        if (e.target.checked !== false) {
            let objIndex = thicknessValues.findIndex((obj => obj.name === e.target.value));
            thicknessValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = thicknessValues.findIndex((obj => obj.name === e.target.value));
            thicknessValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeMinTrack = (e) => {
        if (e.target.checked !== false) {
            let objIndex = minTrackValues.findIndex((obj => obj.name === e.target.value));
            minTrackValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = minTrackValues.findIndex((obj => obj.name === e.target.value));
            minTrackValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeMinHole = (e) => {
        if (e.target.checked !== false) {
            let objIndex = minHoleValues.findIndex((obj => obj.name === e.target.value));
            minHoleValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = minHoleValues.findIndex((obj => obj.name === e.target.value));
            minHoleValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeSoldermask = (e) => {
        if (e.target.checked !== false) {
            let objIndex = soldermaskValues.findIndex((obj => obj.name === e.target.value));
            soldermaskValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = soldermaskValues.findIndex((obj => obj.name === e.target.value));
            soldermaskValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeSilkScreen = (e) => {
        if (e.target.checked !== false) {
            let objIndex = silkScreenValues.findIndex((obj => obj.name === e.target.value));
            silkScreenValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = silkScreenValues.findIndex((obj => obj.name === e.target.value));
            silkScreenValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeSurfaceFinish = (e) => {
        if (e.target.checked !== false) {
            let objIndex = surfaceFinishValues.findIndex((obj => obj.name === e.target.value));
            surfaceFinishValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = surfaceFinishValues.findIndex((obj => obj.name === e.target.value));
            surfaceFinishValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeCopper = (e) => {
        if (e.target.checked !== false) {
            let objIndex = copperValues.findIndex((obj => obj.name === e.target.value));
            copperValues[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = copperValues.findIndex((obj => obj.name === e.target.value));
            copperValues[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }


    const handleChangeGold = (e) => {
        if (e.target.checked !== false) {
            let objIndex = goldFingerValue.findIndex((obj => obj.name === e.target.value));
            goldFingerValue[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = goldFingerValue.findIndex((obj => obj.name === e.target.value));
            goldFingerValue[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeCastellated = (e) => {
        if (e.target.checked !== false) {
            let objIndex = castellatedValue.findIndex((obj => obj.name === e.target.value));
            castellatedValue[objIndex].checked = true;
            setChangeValue(!changeValue);
        } else {
            let objIndex = castellatedValue.findIndex((obj => obj.name === e.target.value));
            castellatedValue[objIndex].checked = false;
            setChangeValue(!changeValue);
        }
    }

    const handleChangeLayerValue = (value, i) => {
        layersCostArray[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeFormatValue = (value, i) => {
        formatValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeMaterialValue = (value, i) => {
        materialValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeThicknessValue = (value, i) => {
        thicknessValues[i].value = Number(value);
        setChangeValue(!changeValue);
    }

    const handleChangeMinTrackValue = (value, i) => {
        minTrackValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeMinHoleValValue = (value, i) => {
        minHoleValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeSolderMaskValue = (value, i) => {
        soldermaskValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeSilkScreenValue = (value, i) => {
        silkScreenValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeSurfaceFinishValue = (value, i) => {
        surfaceFinishValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeCopperValue = (value, i) => {
        copperValues[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeGoldValue = (value, i) => {
        goldFingerValue[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleChangeCastellaValue = (value, i) => {
        castellatedValue[i].value = value;
        setChangeValue(!changeValue);
    }

    const handleAddFixedCost = () => {
        setIsEdit(!isEdit);
    }

    const handleSubmit = () => {
        console.log('update');
    }

    const handleSubmitUpdate = () => {
        console.log('update');
    }

    const bringQuoterProvider = () => {
        if (quoterInfo.fixed_costs) {
            Object.entries(quoterInfo.fixed_costs).forEach(([key, value]) => {
                setFixedCost(value);
                setIsEdit(!isEdit);
            });
        }
        if (quoterInfo.layers) {
            Object.entries(quoterInfo.layers).forEach(([key, value]) => {
                layersCostArray.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.dimensions) {
            Object.entries(quoterInfo.dimensions).forEach(([key, value]) => {
                Object.assign(dimensions, { [key]: value });
            });
        }
        if (quoterInfo.volume) {
            Object.entries(quoterInfo.volume).forEach(([key, value]) => {
                Object.assign(volume, { [key]: value });
            });
        }
        if (quoterInfo.format) {
            Object.entries(quoterInfo.format).forEach(([key, value]) => {
                formatValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.material) {
            Object.entries(quoterInfo.material).forEach(([key, value]) => {
                materialValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.thickness) {
            Object.entries(quoterInfo.thickness).forEach(([key, value]) => {
                thicknessValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.min_track_spacing) {
            Object.entries(quoterInfo.min_track_spacing).forEach(([key, value]) => {
                minTrackValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.min_hole_size) {
            Object.entries(quoterInfo.min_hole_size).forEach(([key, value]) => {
                minHoleValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.soldermask) {
            Object.entries(quoterInfo.soldermask).forEach(([key, value]) => {
                soldermaskValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.silkscreen) {
            Object.entries(quoterInfo.silkscreen).forEach(([key, value]) => {
                silkScreenValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.surface_finish) {
            Object.entries(quoterInfo.surface_finish).forEach(([key, value]) => {
                surfaceFinishValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.copper_weight) {
            Object.entries(quoterInfo.copper_weight).forEach(([key, value]) => {
                copperValues.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.gold_fingers) {
            Object.entries(quoterInfo.gold_fingers).forEach(([key, value]) => {
                goldFingerValue.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (quoterInfo.castellated_holes) {
            Object.entries(quoterInfo.castellated_holes).forEach(([key, value]) => {
                castellatedValue.forEach(element => {
                    if (element.name === key) {
                        element.checked = true;
                        element.value = value;
                    }
                });
            });
        }
        if (Object.keys(quoterInfo).length !== 0) {
            setHaveData(!haveData);
        }
        setChangeValue(!changeValue);
    }

    useEffect(() => {
        if (isModalOpen) {
            bringQuoterProvider();
        }
    }, [isModalOpen]);

    return (
        <>
            <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }} id="deleteModal" tabIndex="1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 1350 }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Cotizador y placas del proveedor</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseQuoterModal(false)}></button>
                        </div>
                        <div className="modal-body" style={{ height: 550, overflowY: 'auto' }}>
                            <div className="thirdContainer">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="card rounded-0 dashboardCard">
                                            <h4>Características de la placa</h4>
                                            <div className="col-lg-12 mb-1">
                                                <label htmlFor=""><b>Costo fijo</b></label>
                                            </div>
                                            <div className="row mb-3 w-100">
                                                <div className="col-md-4">
                                                    <input className="form-control" type="number"
                                                        name="fixedCost" value={fixedCost} onChange={(e) => { setFixedCost(e.target.value) }} disabled={isEdit} />
                                                </div>
                                                <div className="col-md-4">
                                                    {isEdit ?
                                                        <button className="btn btn-success" type='button' onClick={() => { setIsEdit(!isEdit) }}>Editar</button>
                                                        :
                                                        <button className="btn btn-primary" type='button' onClick={handleAddFixedCost}>Guardar</button>
                                                    }

                                                </div>

                                            </div>
                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Capas: </b></label>
                                                </div>
                                                {layersCostArray.map((layer, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-1">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {layer.id}
                                                                    <input className="form-check-input" type="checkbox" value={layer.name} checked={layer.checked} onChange={handleChangeLayers} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-6 row mb-2">
                                                    <label htmlFor=""><b>Dimensiones en X: </b></label>
                                                    <div className="col-md-4">
                                                        <label style={{ verticalAlign: "-webkit-baseline-middle" }} htmlFor="">Minimo:</label>
                                                        <input className="form-control" type="number"
                                                            name="dimension_x_min" value={dimensions.dimension_x_min} onChange={handleDimensions} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label style={{ verticalAlign: "-webkit-baseline-middle" }} htmlFor="">maximo:</label>
                                                        <input className="form-control" type="number"
                                                            name="dimension_x_max" value={dimensions.dimension_x_max} onChange={handleDimensions} />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 row mb-2">
                                                    <label htmlFor=""><b>Dimensiones en Y: </b></label>
                                                    <div className="col-md-4">
                                                        <label style={{ verticalAlign: "-webkit-baseline-middle" }} htmlFor="">Minimo:</label>
                                                        <input className="form-control" type="number"
                                                            name="dimension_y_min" value={dimensions.dimension_y_min} onChange={handleDimensions} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label style={{ verticalAlign: "-webkit-baseline-middle" }} htmlFor="">maximo:</label>
                                                        <input className="form-control" type="number"
                                                            name="dimension_y_max" value={dimensions.dimension_y_max} onChange={handleDimensions} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Cantidad a fabricar : </b></label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label style={{ verticalAlign: "-webkit-baseline-middle" }} htmlFor="">Minimo:</label>
                                                    <input className="form-control" type="number" name="volume_min"
                                                        value={volume.volume_min} onChange={(e) => { setVolume({ ...volume, volume_min: e.target.value }) }} />
                                                </div>
                                                <div className="col-md-3">
                                                    <label style={{ verticalAlign: "-webkit-baseline-middle" }} htmlFor="">maximo:</label>
                                                    <input className="form-control" type="number" name="volume_max"
                                                        value={volume.volume_max} onChange={(e) => { setVolume({ ...volume, volume_max: e.target.value }) }} />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Formato: </b></label>
                                                </div>
                                                {formatValues.map((formatVal, i) => {
                                                    return (
                                                        <div key={i} className="col-md-4">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {formatVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={formatVal.name}
                                                                        checked={formatVal.checked} onChange={handleChangeFormat} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Material: </b></label>
                                                </div>
                                                {materialValues.map((materialVal, i) => {
                                                    return (
                                                        <div key={i} className="col-md-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {materialVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={materialVal.name}
                                                                        checked={materialVal.checked} onChange={handleChangeMaterial} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Thickness: </b></label>
                                                </div>
                                                {thicknessValues.map((thicknessVal, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-1">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {thicknessVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={thicknessVal.name}
                                                                        checked={thicknessVal.checked} onChange={handleChangeThickness} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Min Track/Spacing: </b></label>
                                                </div>
                                                {minTrackValues.map((minTrackVal, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {minTrackVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={minTrackVal.name}
                                                                        checked={minTrackVal.checked} onChange={handleChangeMinTrack} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Min Hole size: </b></label>
                                                </div>
                                                {minHoleValues.map((minHoleVal, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {minHoleVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={minHoleVal.name}
                                                                        checked={minHoleVal.checked} onChange={handleChangeMinHole} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Mask Color: </b></label>
                                                </div>

                                                {soldermaskValues.map((soldermaskVal, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-2">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {soldermaskVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={soldermaskVal.name}
                                                                        checked={soldermaskVal.checked} onChange={handleChangeSoldermask} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }


                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>Silk Color: </b></label>
                                                </div>

                                                {silkScreenValues.map((silksVal, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {silksVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={silksVal.name}
                                                                        checked={silksVal.checked} onChange={handleChangeSilkScreen} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>SurfaceFinish: </b></label>
                                                </div>
                                                {surfaceFinishValues.map((surfaceVal, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-4">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {surfaceVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={surfaceVal.name}
                                                                        checked={surfaceVal.checked} onChange={handleChangeSurfaceFinish} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-lg-12 mb-2">
                                                    <label htmlFor=""><b>copperWeight: </b></label>
                                                </div>

                                                {copperValues.map((copperVal, i) => {
                                                    return (
                                                        <div key={i} className="col-md-6">
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {copperVal.type}
                                                                    <input className="form-check-input" type="checkbox" value={copperVal.name}
                                                                        checked={copperVal.checked} onChange={handleChangeCopper} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>


                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <label htmlFor="inputMaterial" className="col-form-label"><b>Goldfinger:</b></label>
                                                </div>

                                                {goldFingerValue.map((goldVal, i) => {
                                                    return (
                                                        <div key={i} className="col-md-6" style={{ paddingTop: 5 }}>
                                                            <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3 }}>
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    <input className="form-check-input" type="checkbox" id="goldFinger"
                                                                        name="goldFinger" value={goldVal.name}
                                                                        checked={goldVal.checked} onChange={handleChangeGold} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <label htmlFor="inputMaterial" className="col-form-label"><b>CastellatedHoles:</b></label>
                                                </div>
                                                {castellatedValue.map((castellatedVal, i) => {
                                                    return (
                                                        <div key={i} className="col-md-6" style={{ paddingTop: 5 }}>
                                                            <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3, marginLeft: 10 }}>
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    <input className="form-check-input" type="checkbox" id="castellated"
                                                                        name="castellated" value={castellatedVal.name}
                                                                        checked={castellatedVal.checked} onChange={handleChangeCastellated} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            <div className="col-md-3 ">
                                                {isLoading ?
                                                    <button className="btn btn-primary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </button>
                                                    :
                                                    haveData ?
                                                        <button style={{ width: "100%" }} type="button"
                                                            onClick={(e) => handleSubmitUpdate(e)} className="btn btn-primary">Actualizar</button>
                                                        :
                                                        <button style={{ width: "100%" }} type="button"
                                                            onClick={(e) => handleSubmit(e)} className="btn btn-primary">Guardar</button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5">
                                        <div className="card rounded-0 dashboardCard">
                                            <h4>Costos de las características</h4>
                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Capas: </b></label>
                                                </div>
                                                {layersCostArray.map((layer, i) => {
                                                    if (layer.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 100 }}>
                                                                <input type="number" className="form-control" name="flexCheckDefault" value={layer.value}
                                                                    onChange={(e) => { handleChangeLayerValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{layer.id}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Formato: </b></label>
                                                </div>
                                                {formatValues.map((format, i) => {
                                                    if (format.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 200 }}>
                                                                <input type="number" className="form-control" name="flexInputFormat" value={format.value}
                                                                    onChange={(e) => { handleChangeFormatValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{format.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Material: </b></label>
                                                </div>
                                                {materialValues.map((material, i) => {
                                                    if (material.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 200 }}>
                                                                <input type="number" className="form-control" name="flexInputMaterial" value={material.value}
                                                                    onChange={(e) => { handleChangeMaterialValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{material.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>


                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Thickness: </b></label>
                                                </div>
                                                {thicknessValues.map((thickness, i) => {
                                                    if (thickness.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 100 }}>
                                                                <input type="number" className="form-control" name="flexInputThickness" value={thickness.value}
                                                                    onChange={(e) => { handleChangeThicknessValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{thickness.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Min Track/Spacing: </b></label>
                                                </div>
                                                {minTrackValues.map((minTrack, i) => {
                                                    if (minTrack.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={minTrack.type === "from 15/15 mil" ? { width: 140 } : { width: 100 }}>
                                                                <input type="number" className="form-control" name="flexInputMinTrack" value={minTrack.value}
                                                                    onChange={(e) => { handleChangeMinTrackValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{minTrack.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Min Hole size: </b></label>
                                                </div>
                                                {minHoleValues.map((minHoleVal, i) => {
                                                    if (minHoleVal.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={minHoleVal.type === "from 0.50 mm" ? { width: 140 } : { width: 100 }}>
                                                                <input type="number" className="form-control" name="flexInputMinHoleVal" value={minHoleVal.value}
                                                                    onChange={(e) => { handleChangeMinHoleValValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{minHoleVal.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Mask Color: </b></label>
                                                </div>
                                                {soldermaskValues.map((solderMask, i) => {
                                                    if (solderMask.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 100 }}>
                                                                <input type="number" className="form-control" name="flexInputSolderMask" value={solderMask.value}
                                                                    onChange={(e) => { handleChangeSolderMaskValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{solderMask.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>Silk Color: </b></label>
                                                </div>
                                                {silkScreenValues.map((silkScreen, i) => {
                                                    if (silkScreen.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 100 }}>
                                                                <input type="number" className="form-control" name="flexInputSilkScreen" value={silkScreen.value}
                                                                    onChange={(e) => { handleChangeSilkScreenValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{silkScreen.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>SurfaceFinish: </b></label>
                                                </div>
                                                {surfaceFinishValues.map((surfaceFinish, i) => {
                                                    if (surfaceFinish.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 130 }}>
                                                                <input type="number" className="form-control" name="flexInputSurfaceFinish" value={surfaceFinish.value}
                                                                    onChange={(e) => { handleChangeSurfaceFinishValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{surfaceFinish.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-lg-12 mb-1">
                                                    <label htmlFor=""><b>copperWeight: </b></label>
                                                </div>
                                                {copperValues.map((copper, i) => {
                                                    if (copper.checked) {
                                                        return (
                                                            <div key={i} className="form-floating mb-1" style={{ width: 100 }}>
                                                                <input type="number" className="form-control" name="flexInputCopper" value={copper.value}
                                                                    onChange={(e) => { handleChangeCopperValue(e.target.value, i) }} />
                                                                <label htmlFor=""><b>{copper.type}:</b></label>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>

                                            <div className="row mb-3 w-100">
                                                <div className="col-md-6">
                                                    <div className="col-lg-12 mb-1">
                                                        <label htmlFor=""><b>Goldfinger: </b></label>
                                                    </div>
                                                    {goldFingerValue.map((gold, i) => {
                                                        if (gold.checked) {
                                                            return (
                                                                <div key={i} className="form-floating mb-1" style={{ width: 130 }}>
                                                                    <input type="number" className="form-control" name="flexInputGold" value={gold.value}
                                                                        onChange={(e) => { handleChangeGoldValue(e.target.value, i) }} />
                                                                    <label htmlFor=""><b></b></label>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="col-lg-12 mb-1">
                                                        <label htmlFor=""><b>CastellatedHoles: </b></label>
                                                    </div>
                                                    {castellatedValue.map((castella, i) => {
                                                        if (castella.checked) {
                                                            return (
                                                                <div key={i} className="form-floating mb-1" style={{ width: 130 }}>
                                                                    <input type="number" className="form-control" name="flexInputCastella" value={castella.value}
                                                                        onChange={(e) => { handleChangeCastellaValue(e.target.value, i) }} />
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleCloseQuoterModal(false)}>Cerrar</button>
                                {isLoading ?
                                    <button type="submit" className="btn btn-danger" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                    :
                                    <button type="submit" className="btn btn-danger">Confirmar</button>
                                }
                            </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalQuoterAdmin;
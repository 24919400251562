import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import { fetchCreateInitialContent, getInitialContent, fetchUpdateInitialContent } from '../services';

function GeneralSettings() {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditSlogan, setIsEditSlogan] = useState(false);

    const [isEditBackground, setIsEditBackground] = useState(false);

    const [initialInfo, setInitialInfo] = useState({
        logoImage: '',
        slogan: '',
        backgroundImage: '',
        idContent: ''
    });
    const [haveData, setHaveData] = useState(false);

    const handleLogo = (e) => {
        let files = e.target.files;
        let base64Data;
        if (files.length !== 0) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                base64Data = e.target.result;
                setInitialInfo({ ...initialInfo, logoImage: base64Data });
            }
        }
    }

    const handleBackground = (e) => {
        let files = e.target.files;
        let base64Data;
        if (files.length !== 0) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                base64Data = e.target.result;
                setInitialInfo({ ...initialInfo, backgroundImage: base64Data });
            }
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function handleSaveChange() {
        setIsLoading(true);
        const response = await fetchCreateInitialContent(initialInfo);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                setHaveData(!haveData);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    async function bringContent() {
        const response = await getInitialContent();
        if (typeof response !== 'undefined') {
            if (response.data.content) {
                let content = response.data.content[0];
                if (Object.keys(response.data.content).length !== 0) {
                    setInitialInfo({
                        ...initialInfo,
                        logoImage: content.logoImage,
                        slogan: content.slogan,
                        backgroundImage: content.backgroundImage,
                        idContent: content._id
                    });
                    setHaveData(!haveData);
                }
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    async function handleUpdateChange() {
        setIsLoading(true);
        const response = await fetchUpdateInitialContent(initialInfo);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    useEffect(() => {
        bringContent();
    }, []);

    return (
        <div className="adminInformationContainer">
            {componentToast()}
            <div className="row">
                <div className="col-lg-12">
                    <div className="card informationCard">
                        <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {isLoading ?
                                <button type="submit" className="btn btn-danger" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                :
                                haveData ?
                                    <button type="button" className="btn btn-primary" onClick={handleUpdateChange}
                                        disabled={initialInfo.logoImage !== '' && initialInfo.slogan !== '' && initialInfo.backgroundImage !== '' ? false : true} >
                                        Actualizar Cambios
                                    </button>
                                    :
                                    <button type="button" className="btn btn-success" onClick={handleSaveChange}
                                        disabled={initialInfo.logoImage !== '' && initialInfo.slogan !== '' && initialInfo.backgroundImage !== '' ? false : true} >
                                        Guardar cambios
                            </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card informationCard">
                        <h3 style={{ textAlign: "center" }}>Logo principal del sitio</h3>
                        {initialInfo.logoImage !== '' ?
                            <img src={initialInfo.logoImage} alt="uploadImage" className='site-logo' />
                            :
                            <img src="images/PROTOTIPARK_NOTION.png" alt="Empty" className='site-logo' />
                        }
                        <input className="form-control" type="file" id="formFile" onChange={handleLogo} disabled={!isEdit} />
                        {!isEdit ?
                            <div className="col-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                                <button type="button" className="btn btn-primary" onClick={() => { setIsEdit(!isEdit) }}>Editar</button>
                            </div>
                            :
                            <div className="row w-100 d-flex" style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                                <div className="col-md-4">
                                    <button type="button" className="btn btn-danger" onClick={() => { setIsEdit(!isEdit) }}>Cancelar</button>
                                </div>
                                <div className="col-md-4">
                                    <button type="button" className="btn btn-success" onClick={() => { setIsEdit(!isEdit) }}>Guardar</button>
                                </div>
                            </div>

                        }
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card informationCard">
                        <h3>Descripción principal del sitio</h3>
                        <textarea style={{ height: 156, margin: 20 }} className="form-control" placeholder="Leave a comment here"
                            id="floatingTextarea" onChange={(e) => { setInitialInfo({ ...initialInfo, slogan: e.target.value }) }}
                            value={initialInfo.slogan} disabled={!isEditSlogan} />
                        {!isEditSlogan ?
                            <div className="col-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
                                <button type="button" className="btn btn-primary" onClick={() => { setIsEditSlogan(!isEditSlogan) }}>Editar</button>
                            </div>
                            :
                            <div className="row w-100 d-flex" style={{ alignItems: 'center', justifyContent: 'center', marginTop: 5, marginBottom: 0.5 }}>
                                <div className="col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-danger" onClick={() => { setIsEditSlogan(!isEditSlogan) }}>Cancelar</button>
                                </div>
                                <div className="col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-success" onClick={() => { setIsEditSlogan(!isEditSlogan) }}>Guardar</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card informationCard">
                        <h3>Banner del sitio</h3>
                        {initialInfo.backgroundImage !== '' ?
                            <img src={initialInfo.backgroundImage} alt="uploadImageBack" className='site-banner' />
                            :
                            <img src="images/backgroundPT.png" alt="EmptyBack" className='site-banner' />
                        }
                        <input className="form-control" type="file" id="formFile" onChange={handleBackground} disabled={!isEditBackground} />
                        {!isEditBackground ?
                            <div className="col-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                                <button type="button" className="btn btn-primary" onClick={() => { setIsEditBackground(!isEditBackground) }}>Editar</button>
                            </div>
                            :
                            <div className="row w-100 d-flex" style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15, marginBottom: 0.2 }}>
                                <div className="col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-danger" onClick={() => { setIsEditBackground(!isEditBackground) }}>Cancelar</button>
                                </div>
                                <div className="col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <button type="button" className="btn btn-success" onClick={() => { setIsEditBackground(!isEditBackground) }}>Guardar</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>



                {/* <div className="col-lg-4">
                    <div className="card informationCard">
                        <h3>Service Card Left</h3>

                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card informationCard">
                        <h3>Service Card Middle</h3>

                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card informationCard">
                        <h3>Service Card Right</h3>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card informationCard">
                        <h3>Confian en nosotros</h3>
                        <div className="col-md-12 imgGallery">
                            <img src="images/PROTOTIPARK_NOTION.png" alt="" className='site-brands' />
                            <img src="images/PROTOTIPARK_NOTION.png" alt="" className='site-brands' />
                            <img src="images/PROTOTIPARK_NOTION.png" alt="" className='site-brands' />
                            <img src="images/PROTOTIPARK_NOTION.png" alt="" className='site-brands' />
                            <img src="images/PROTOTIPARK_NOTION.png" alt="" className='site-brands' />
                            <img src="images/PROTOTIPARK_NOTION.png" alt="" className='site-brands' />
                        </div>
                        <input className="form-control" type="file" id="formFile" />
                    </div>
                </div> */}
            </div>
            <div className="row">
            </div>
        </div>
    )
}

export default GeneralSettings

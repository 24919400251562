import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaShoppingCart } from 'react-icons/fa';
import { Button } from './Button';
import './Navbar.css';
import { IconContext } from 'react-icons/lib'
import { CartContext } from '../context/CartContext';
import Toast from './toast/Toast';
import { getInitialContent } from '../pages/MasterAdmin/services';

let userToken = JSON.parse(localStorage.getItem('userToken'));

function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [cartsContext, setCartsContext] = useContext(CartContext);

    const [logoImage, setLogoImage] = useState('');
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    };

    const valiteUserLogin = () => {
        if (userToken.token !== null) {
            if (Object.values(userToken.token).length !== 0) {
                setIsUserLogin(true);
            } else {
                setIsUserLogin(false);
            }
        }
    }

    const handleLogoutDesigner = () => {
        setIsUserLogin(false);
        let objStorage = { token: '', user: 0 };
        localStorage.setItem('userToken', JSON.stringify(objStorage));
        window.location.href = '/logout';
    }

    useEffect(() => {
        if (userToken !== null) {
            valiteUserLogin();
        }
    });

    const handleWelcomeUser = () => {
        switch (userToken.user) {
            case 1:
                return (<Link to='/my-account#1' className='btn-link' style={{ padding: 0 }} >
                    <div className="dropdown-item">Mi cuenta</div></Link>)
            case 2:
                return (<Link to='/manufacturerAccount#2' className='btn-link' style={{ padding: 0 }} >
                    <div className="dropdown-item">Mi plataforma</div></Link>)
            case 3:
                return (<Link to='/AdminDashboard#1' className='btn-link' style={{ padding: 0 }} >
                    <div className="dropdown-item"> My Admin</div></Link>)
            default:
                break;
        }
    }

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    async function bringContent() {
        const response = await getInitialContent();
        if (typeof response !== 'undefined') {
            if (response.data.content) {
                let content = response.data.content[0];
                if (Object.keys(response.data.content).length !== 0) {
                    setLogoImage(content.logoImage);
                }
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    useEffect(() => {
        bringContent();
    }, []);

    return (
        <>
            {componentToast()}
            <IconContext.Provider value={{ color: '#f1f1f1' }}>
                <div className="navbar">
                    <div className="navbar-container container">
                        <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                            {logoImage !== '' ?
                                <img src={logoImage} alt="" className='navbar-icon' />
                                :
                                <img src="images/PROTOTIPARK_NOTION.png" alt="" className='navbar-icon' />
                            }
                    PROTIPARK
                </Link>
                        <div className="menu-icon" onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </div>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className="nav-item">
                                <Link to='/services' className="nav-links" onClick={closeMobileMenu}>
                                    Servicios
                        </Link>
                            </li>
                            <li className="nav-item">
                                <a href='#supp' className="nav-links" onClick={closeMobileMenu}>
                                    Soporte
                        </a>
                            </li>
                            <li className="nav-item">
                                <Link to='/FAQ' className="nav-links" onClick={closeMobileMenu}>
                                    FAQ
                        </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/About-Us' className="nav-links" onClick={closeMobileMenu}>
                                    Quienes somos
                        </Link>
                            </li>
                            <li className="nav-btn">

                                {isUserLogin ?
                                    <div className="dropdown">
                                        <a className="btn btn-secondary dropdown-toggle" href="#" role="button"
                                            id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">Bienvenido</a>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li>
                                                {handleWelcomeUser()}
                                            </li>
                                            <li>
                                                {userToken.user === 1 ?
                                                    <Link to='/my-account#3' className='btn-link' style={{ padding: 0 }} >
                                                        <div className="dropdown-item">
                                                            Mis órdenes
                                                    </div>
                                                    </Link>
                                                    :
                                                    null
                                                }
                                            </li>
                                            <li onClick={handleLogoutDesigner}>
                                                <a className="dropdown-item" href="#">
                                                    Cerrar sesión
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    button ? (
                                        <Link to='/sign-in' className='btn-link' >
                                            <Button buttonStyle="btn--outline" buttonColor='primary'>Iniciar</Button>
                                        </Link>
                                    ) : (
                                        <Link to='/sign-in' className='btn-link' onClick={closeMobileMenu}>
                                            <Button buttonStyle='btn--outline' buttonSize='btn--mobile'>Iniciar</Button>
                                        </Link>
                                    )
                                }
                            </li>
                            <li className="nav-item">
                                {isUserLogin ?
                                    userToken.user === 1 ?
                                        <Link to='/shopping-cart' className="nav-links " onClick={closeMobileMenu}>
                                            <FaShoppingCart /> {cartsContext.length !== 0 ?
                                                <p>{cartsContext.length}</p> : null
                                            }
                                        </Link>
                                        : null
                                    : null}
                            </li>
                        </ul>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    )
}

export default Navbar

import React from 'react'
import { Button } from './Button'
import { FaFire } from 'react-icons/fa'
import { BsXDiamondFill } from 'react-icons/bs'
import { GiCrystalize } from 'react-icons/gi'
import { IconContext } from 'react-icons/lib'
import { Link } from 'react-router-dom';
import './Pricing.css'


function Pricing() {
    return (
        <IconContext.Provider value={{ color: '#fff', size: 64 }}>
            <div>
                <div className="pricing__section">
                    <div className="pricing__wrapper">
                        <h1 style={{ textAlign: "center" }} className="pricing__heading"><b>Servicios</b></h1>
                        <div className="pricing__container">
                            <div className="pricing__container-card">
                                <div className="pricing__container-cardInfo">
                                    <div className="icon">
                                        <FaFire />
                                    </div>
                                    <h3>Diseño</h3>
                                    <p></p>
                                    <ul className="pricing__container-features" style={{ paddingLeft: 0 }}>
                                        <li style={{ textAlign: "center" }}>*Servicio personalizado de diseño de PCB para tu proyecto de laboratorio.</li>
                                        <li style={{ textAlign: "center" }}>*Conexión con múltiples proveedores.</li>
                                        <li style={{ textAlign: "center" }}>*Backup de diseños libres y de pago.</li>

                                    </ul>
                                    <h3><strong>Próximamente</strong></h3>
                                    {/* <Button buttonSize='btn--wide' buttonColor='primary'>
                                        Saber más
                                </Button> */}
                                </div>
                            </div>
                            <Link to="/services" className="pricing__container-card">
                                <div className="pricing__container-cardInfo">
                                    <div className="icon">
                                        <BsXDiamondFill />
                                    </div>
                                    <h3>Fabricación</h3>
                                    <ul className="pricing__container-features" style={{ paddingLeft: 0 }}>
                                        <li style={{ textAlign: "center" }}>*Cotiza con las dimensiones de tu circuito.</li>
                                        <li style={{ textAlign: "center" }}>*Conexión con múltiples proveedores.</li>
                                        <li style={{ textAlign: "center" }}>*Seguimiento al proceso de fabricación de tu diseño.</li>
                                    </ul>
                                    <Button buttonSize='btn--wide' buttonColor='blue'>
                                        Cotizar
                                </Button>
                                </div>
                            </Link>
                            <div className="pricing__container-card">
                                <div className="pricing__container-cardInfo">
                                    <div className="icon">
                                        <GiCrystalize />
                                    </div>
                                    <h3>Suministro</h3>
                                    <ul className="pricing__container-features" style={{ paddingLeft: 0 }}>
                                        <br/>
                                        <br/>
                                        <li style={{ textAlign: "center" }}>*Conexión con comercios internacionales .</li>
                                        <li style={{ textAlign: "center" }}>*Stock de la casa para tus proyectos.</li>
                                        <li></li>
                                        <li></li>
                              
                                        <br/>
                                        <li></li>
                                    </ul>
                                    <h3><strong>Próximamente</strong></h3>
                                    {/* <Button buttonSize='btn--wide' buttonColor='primary'>
                                        Catálogo
                                </Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </IconContext.Provider>
    )
}

export default Pricing

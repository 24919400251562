import React, { useContext, useEffect, useState } from 'react';
import Toast from '../../../components/toast/Toast';
import { CartContext } from '../../../context/CartContext';
import { DesignerContext } from '../../../context/DesignerContext';
import { getDesignerDataApi } from '../../Account/services';
import { fetchGetCartDesignerApi } from '../../Manufacturing/services/index';
import '../css/order.css';
import { fetchCreateOrderDesignerApi } from '../services';

function OrderSummary() {
    const [designerContext, setdesignerContext] = useContext(DesignerContext);
    const [cartsContext, setCartsContext] = useContext(CartContext);

    const [billingsArray, setBillingsArray] = useState([]);
    const [shippingsArray, setShippingsArray] = useState([]);

    const [isLoadingAllData, setIsLoadingAllData] = useState(false);

    const [isCheckTerms, setIsCeckTerms] = useState(false);

    const [billAddressSelected, setBillAddressSelected] = useState({});
    const [billAddressSelectedIndex, setBillAddressSelectedIndex] = useState('');
    const [addressSelected, setAddressSelected] = useState({});
    const [addressSelectedIndex, setAddressSelectedIndex] = useState('');

    const [isBillSelect, setIsBillSelect] = useState(false);
    const [isAddressSelect, setIsAddressSelect] = useState(false);

    const [isLoadingPay, setIsLoadingPay] = useState(false);
    const [orderPayInfo, setOrderPayInfo] = useState();

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const [personalInfo, setPersonalInfo] = useState({
        profileImage: '',
        username: '',
        name: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        country: '',
        city: ''
    });

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function bringDataDesigner() {
        setIsLoadingAllData(true);
        const response = await getDesignerDataApi();
        if (typeof response !== 'undefined') {
            if (Object.keys(response.data.designerData).length !== 0) {
                let designerData = response.data.designerData;
                setdesignerContext(designerData);
                setPersonalInfo({
                    ...personalInfo,
                    profileImage: designerData.profileImage,
                    username: designerData.username,
                    name: designerData.name,
                    lastName: designerData.lastName,
                    email: designerData.email,
                    phoneNumber: designerData.phoneNumber,
                    city: designerData.city,
                    country: designerData.country,
                });
                setBillingsArray(designerData.billingInformation);
                setShippingsArray(designerData.shippingInformation);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingAllData(false);
            return
        }
    }

    async function bringDataCartDesigner() {
        const response = await fetchGetCartDesignerApi();
        if (typeof response !== 'undefined') {
            if (Object.keys(response.data.cartData).length !== 0) {
                setCartsContext(response.data.cartData);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }
    let subTotalCount = 0;
    let subTotal = 0;

    let handleTotalPrice = () => {
        if (cartsContext.length !== 0) {
            cartsContext.forEach(element => {
                subTotalCount = subTotalCount + element.totalPrice;
                subTotal = subTotal + element.totalPrice;
            });
            subTotalCount = (subTotalCount * 0.19) + subTotalCount;
            return <h4><b>Total a pagar: {"$ "} {Intl.NumberFormat("de-DE").format(subTotalCount)} </b>(impuestos incluidos)</h4>
        } else {
            return null
        }
    }

    const handleSelectBill = (bill, i) => {
        setBillAddressSelected(bill);
        setBillAddressSelectedIndex(i);
    }

    const handleCancelSelectBill = () => {
        setIsBillSelect(!isBillSelect);
        setBillAddressSelected({});
        setBillAddressSelectedIndex('');
    }

    const handleSelectAddress = (shipping, i) => {
        setAddressSelected(shipping);
        setAddressSelectedIndex(i);
    }

    const handleCancelSelectAddress = () => {
        setIsAddressSelect(!isAddressSelect);
        setAddressSelected({});
        setAddressSelectedIndex('');
    }

    async function goToPay() {
        setIsLoadingPay(true);
        let objTotalInfo = {
            designerInfo: {
                username: personalInfo.username,
                name: personalInfo.name,
                lastName: personalInfo.lastName,
                email: personalInfo.email,
                phone: personalInfo.phoneNumber,
                city: personalInfo.city,
                country: personalInfo.country
            },
            billingInfo: billAddressSelected,
            shippingInfo: addressSelected,
            cartInfo: cartsContext,
            subtotal: subTotal,
            tax: 0.19,
            shippingCost: 0,
            totalPrice: subTotalCount
        }

        const response = await fetchCreateOrderDesignerApi(objTotalInfo);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setIsLoadingPay(false);
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setCartsContext([]);
                setIsCeckTerms(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingPay(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingPay(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingPay(false);
            return
        }
    }

    useEffect(() => {
        bringDataDesigner();
        bringDataCartDesigner();
    }, [])

    return (
        <div>
            {componentToast()}
            <h2><b>Resumen de orden #</b></h2><br />
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h4><b>Información personal</b></h4>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='informationContainer'>
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <form className="row g-3">
                                            <label htmlFor="inputUsername" className="form-label"><b>Foto de Perfil</b></label>
                                            <div className="col-md-12">
                                                {personalInfo.profileImage !== '' ?
                                                    <img src={personalInfo.profileImage} alt="uploadImage" style={{ width: 110, marginBottom: 15 }} />
                                                    :
                                                    <img src="https://bulma.io/images/placeholders/128x128.png" alt="Empty" style={{ width: 110, marginBottom: 15 }} />
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="inputUsername" className="form-label"><b>Username</b></label>
                                                <input type="text" className="form-control" id="inputUsername" name="username"
                                                    value={personalInfo.username} onChange={null} disabled />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputName" className="form-label"><b>Nombre</b></label>
                                                <input type="text" className="form-control" id="inputName" name="name"
                                                    value={personalInfo.name} disabled />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputLastName" className="form-label"><b>Apellidos</b></label>
                                                <input type="text" className="form-control" id="inputLastName" name="lastName"
                                                    value={personalInfo.lastName} disabled />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputEmail" className="form-label"><b>Email</b></label>
                                                <input type="email" className="form-control" id="inputEmail" placeholder="Example@example.com" name="email"
                                                    value={personalInfo.email} disabled />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputTel" className="form-label"><b>Teléfono</b></label>
                                                <input type="text" className="form-control" id="inputTel" placeholder="555-3333" name="phoneNumber"
                                                    value={personalInfo.phoneNumber} disabled />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputCity" className="form-label"><b>Ciudad</b></label>
                                                <input type="text" className="form-control" id="inputCity" placeholder="Cali" name="city"
                                                    value={personalInfo.city} disabled />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="inputCountry" className="form-label"><b>País</b></label>
                                                <input type="text" className="form-control" id="inputCountry" placeholder="Colombia" name="country"
                                                    value={personalInfo.country} disabled />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <h4><b>Datos de facturación</b></h4>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            {/* <h4><b>Dirección de facturación</b></h4> */}
                            <div className='col-xl-12 informationContainer'>
                                <div className="row g-3">
                                    {/* <div className="col-lg-8">
                                        <div className='informationContainer-Section'>
                                            <form className="row g-3">
                                                <div className="col-md-6">
                                                    <label htmlFor="inputEmail4" className="form-label"><b>Nombre</b></label>
                                                    <input type="text" className="form-control" id="inputEmail4" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="inputPassword4" className="form-label"><b>Apellidos</b></label>
                                                    <input type="text" className="form-control" id="inputPassword4" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="inputPassword4" className="form-label"><b>Facturar nombre de:</b></label>
                                                    <input type="text" className="form-control" id="inputPassword4" />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="inputAddress2" className="form-label"><b>Dirección 1:</b></label>
                                                    <input type="text" className="form-control" id="inputAddress2" placeholder="Apartmento, piso, o conjunto" />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="inputCity" className="form-label"><b>Ciudad</b></label>
                                                    <input type="text" className="form-control" id="inputCity" />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="inputState" className="form-label"><b>Estado</b></label>
                                                    <select id="inputState" className="form-select">
                                                        <option>Seleccionar</option>
                                                        <option>...</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="inputState" className="form-label"><b>Pais</b></label>
                                                    <select id="inputState" className="form-select">
                                                        <option>Seleccionar</option>
                                                        <option>...</option>
                                                    </select>
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-primary">Confirmar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12">
                                        <div className="card paymentCard">
                                            <label style={{ padding: 20 }}> <b>Tus direcciones de facturación</b> </label>
                                            <div className='overflow-auto billingAdressContainer'>
                                                <div className="list-group" id="list-tab" role="tablist">
                                                    {billingsArray.map((bill, i) => {
                                                        return (
                                                            <a key={i} className={`list-group-item list-group-item-action  ${billAddressSelectedIndex === i ? 'active' : ''} ${isBillSelect ? 'disabled' : ''}`} id="list-home-list" data-bs-toggle="list"
                                                                href="#list-home" role="tab" aria-controls="home" onClick={() => { handleSelectBill(bill, i) }} >
                                                                <div className="row mb-3">
                                                                    <div className="col-sm-4">
                                                                        {i + 1} ).{" "}
                                                                        {bill.billingName} {" "}
                                                                        {bill.billingLastName} - {bill.billingCompany}
                                                                    </div>

                                                                    <div className="col-sm-4">
                                                                        {bill.billingAddress1}, <br />
                                                                        {bill.billingAddress2}
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        {bill.billingCity}, {bill.billingState}<br />
                                                                        {bill.billingCountry}
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            {isBillSelect ?
                                                <div className="col-12 adressButton">
                                                    <button type="submit" className="btn btn-primary" onClick={() => { handleCancelSelectBill() }}>Editar</button>
                                                </div>
                                                :
                                                <div className="col-12 adressButton">
                                                    <button type="submit" className="btn btn-success" onClick={() => { setIsBillSelect(!isBillSelect) }}>Usar</button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h4><b>Direcciones de entrega</b></h4>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='col-xl-12 informationContainer'>
                                <div className="row g-3">
                                    {/* <div className="col-lg-8">
                                        <div className='informationContainer-Section'>
                                            <form className="row g-3">
                                                <div className="col-6">
                                                    <label htmlFor="inputAdressName" className="form-label"><b>Nombre:</b></label>
                                                    <input type="text" className="form-control" id="inputName" placeholder="Nombre" />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="inputAddress2" className="form-label"><b>Apellido:</b></label>
                                                    <input type="text" className="form-control" id="inputLastName" placeholder="Apellido" />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="inputAddress1" className="form-label"><b>Dirección:</b></label>
                                                    <input type="text" className="form-control" id="inputAddress1" placeholder="Direccón completo" />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="inputAddress2" className="form-label"><b>Complemento:</b></label>
                                                    <input type="text" className="form-control" id="inputAddress2" placeholder="Indicaciones, Ej color de casa, apartamamento, etc" />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="inputReceiverName" className="form-label"><b>Quién recibe:</b></label>
                                                    <input type="text" className="form-control" id="inputReceiverName" placeholder="Nombre de quién recibe" />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="inputPhone" className="form-label"><b>Teléfono:</b></label>
                                                    <input type="text" className="form-control" id="inputLastName" placeholder="111-222-3344" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="inputCity" className="form-label"><b>Ciudad</b></label>
                                                    <input type="text" className="form-control" id="inputCity" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="inputState" className="form-label"><b>Estado</b></label>
                                                    <select id="inputState" className="form-select">
                                                        <option>Seleccionar</option>
                                                        <option>...</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="inputState" className="form-label"><b>Pais</b></label>
                                                    <select id="inputCountry" className="form-select">
                                                        <option>Seleccionar</option>
                                                        <option>...</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="inputState" className="form-label"><b>ZIP Code</b></label>
                                                    <input type="text" className="form-control" id="inputZipCode" placeholder="000000" />
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-primary">Confirmar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="col-xl-12">
                                        <div className="card paymentCard">
                                            <label style={{ padding: 20 }}> <b>Tus direcciones</b> </label>
                                            <div className='overflow-auto deliveryAdressContainer'>
                                                <div className="list-group" id="list-tab" role="tablist" style={shippingsArray.length > 1 ? { overflowY: 'scroll', height: 250 } : null}>

                                                    {shippingsArray.map((shipping, i) => {
                                                        return (

                                                            <a key={i} className={`list-group-item list-group-item-action  ${addressSelectedIndex === i ? 'active' : ''}  ${isAddressSelect ? 'disabled' : ''}`} id="list-home-list" data-bs-toggle="list"
                                                                href="#list-home" role="tab" aria-controls="home" onClick={() => { handleSelectAddress(shipping, i) }}>
                                                                <div className="row mb-3">
                                                                    <div className="col-sm-2">
                                                                        {i + 1} ).{" "}
                                                                        {shipping.shippingName} {" "}
                                                                        {shipping.shippingLastName}
                                                                    </div>

                                                                    <div className="col-sm-4">
                                                                        <p>Recibe: {shipping.shippingReceiver} {" "} <br />
                                                                            Teléfono: {shipping.shippingNumber} {" "}
                                                                        </p>
                                                                    </div>

                                                                    <div className="col-sm-4">
                                                                        {shipping.shippingAddress1}, <br />
                                                                        {shipping.shippingAddress2}
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        {shipping.shippingCity}, <br />
                                                                        {shipping.shippingCountry} <br />
                                                                        {shipping.shippingZipCode}
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        )
                                                    })}


                                                </div>
                                            </div>
                                            {isAddressSelect ?
                                                <div className="col-12 adressButton">
                                                    <button type="submit" className="btn btn-primary" onClick={() => { handleCancelSelectAddress() }}>Editar</button>
                                                </div>
                                                :
                                                <div className="col-12 adressButton">
                                                    <button type="submit" className="btn btn-success" onClick={() => { setIsAddressSelect(!isAddressSelect) }}>Usar</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <h4><b>Tu pedido</b></h4>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='col-xl-12 informationContainer' style={cartsContext.length > 1 ? { overflowY: 'scroll', height: 280 } : null}>

                                {cartsContext.map((cart, i) => {
                                    return (
                                        <div key={i} className="card" style={{ padding: 25 }}>
                                            <div className="row mb-3">
                                                <div className="col-sm-2">
                                                    <h6 style={{ paddingBottom: 20 }}><strong>Item</strong></h6>
                                                    <p>
                                                        # {" "}{i + 1}
                                                    </p>
                                                </div>
                                                <div className="col-sm-2" >
                                                    <h6 style={{ paddingBottom: 20 }}><strong>Descripción</strong></h6>
                                                    <p>
                                                        {cart.description}
                                                    </p>

                                                </div>
                                                <div className="col-sm-2">
                                                    <h6 style={{ paddingBottom: 20 }}><strong>Fabricante</strong></h6>
                                                    <p>{cart.providerCompany}</p>
                                                </div>
                                                <div className="col-sm-2">
                                                    <h6 style={{ paddingBottom: 20 }}><strong>Tiempo</strong></h6>
                                                    <p>
                                                        {cart.productionTime}{" "} Horas
                                                    </p>

                                                </div>
                                                <div className="col-sm-2">
                                                    <h6 style={{ paddingBottom: 20 }}><strong>Cantidad</strong></h6>
                                                    <p>
                                                        {cart.qty}
                                                    </p>
                                                </div>
                                                <div className="col-sm-2">
                                                    <h6 style={{ paddingBottom: 20 }}><strong>Precio</strong></h6>
                                                    <p>
                                                        {"$ "}{Intl.NumberFormat("de-DE").format(cart.totalPrice)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-xl-12 informationContainer'>
                <h4 style={{ marginTop: 30 }}><b>Paga tu orden</b></h4>
                <div style={{ padding: 20 }} className="card">
                    <div style={{ marginBottom: 20 }}>
                        {handleTotalPrice()}
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <input style={{ marginRight: 10 }} className="form-check-input" type="checkbox"
                            id="gridCheck1" onChange={(e) => { setIsCeckTerms(e.target.checked) }} checked={isCheckTerms} />
                        <label className="form-check-label" htmlFor="gridCheck1">
                            Acepto las condicione y términos de pago de PayU
                    </label>
                    </div>
                    {isLoadingPay ?
                        <div className="col-md-6">
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                        :
                        <div className="col-md-6">
                            {isBillSelect && isAddressSelect && isCheckTerms ?
                                <button type="button" className="btn btn-success" onClick={() => { goToPay() }}>Pagar</button>
                                :
                                <button type="button" className="btn btn-success" disabled>Pagar</button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default OrderSummary

import React, { useState, useEffect, useContext } from 'react';
import { BiTrash } from 'react-icons/bi';
import '../css/style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { fetchGetCartDesignerApi } from '../../Manufacturing/services/index';
import Toast from '../../../components/toast/Toast';
import { CartContext } from '../../../context/CartContext';
import { removeCartDesignerApi } from '../services';

function ShoppingCartBody() {
    const [cartsContext, setCartsContext] = useContext(CartContext);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    async function bringDataCartDesigner() {
        const response = await fetchGetCartDesignerApi();
        if (typeof response !== 'undefined') {
            if (Object.keys(response.data.cartData).length !== 0) {
                setCartsContext(response.data.cartData);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    async function handleDeleteCart(id, i) {
        setIsLoadingDelete(true);
        const response = await removeCartDesignerApi(id);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                cartsContext.splice(i, 1);
                setCartsContext([...cartsContext]);
                setIsLoadingDelete(false);
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingDelete(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingDelete(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingDelete(false);
            return
        }

    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    useEffect(() => {
        bringDataCartDesigner();
    }, []);



    return (
        <>
            {componentToast()}
            <div className="col-lg-12" style={{ marginBottom: 20 }}>
                <div className="card" style={{ padding: 25 }}>
                    <div style={{ paddingBottom: 20 }}>
                        <h3>Tu carrito</h3>
                    </div>
                    <div className="list-group" id="list-tab" role="tablist" style={cartsContext.length > 1 ? { overflowY: 'scroll', height: 250 } : null}>
                        {cartsContext.map((cart, i) => {
                            return (
                                <div key={i} className="list-group-item" id="list-home-list" role="tab" aria-controls="home">
                                    <div className="row mb-3 w-100">
                                        <div className="col-sm-2" >
                                            <h6 style={{ paddingBottom: 20 }}><strong>Descripción</strong></h6>
                                            <p>
                                                {cart.description}
                                            </p>
                                        </div>
                                        <div className="col-sm-2">
                                            <h6 style={{ paddingBottom: 20 }}><strong>Fabricante</strong></h6>
                                            <p>{cart.providerCompany}</p>
                                        </div>
                                        <div className="col-sm-2">
                                            <h6 style={{ paddingBottom: 20 }}><strong>Tiempo</strong></h6>
                                            <p>
                                                {cart.productionTime}{" "} Horas
                                            </p>

                                        </div>
                                        <div className="col-sm-2">
                                            <h6 style={{ paddingBottom: 20 }}><strong>Cantidad</strong></h6>
                                            <p>
                                                {cart.qty}
                                            </p>
                                        </div>
                                        <div className="col-sm-2">
                                            <h6 style={{ paddingBottom: 20 }}><strong>Precio</strong></h6>
                                            <p>
                                                {"$ "}{Intl.NumberFormat("de-DE").format(cart.totalPrice)}
                                            </p>
                                        </div>
                                        <div className="col-sm-2">
                                            <h6 style={{ paddingBottom: 10 }}><strong>Acciones</strong></h6>
                                            {isLoadingDelete ?
                                                <button className="btn btn-danger cartButton" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                                :
                                                <button type="button" className="btn btn-danger cartButton"
                                                    onClick={() => { if (window.confirm('¿Estás seguro de que deseas eliminar este artículo?')) handleDeleteCart(cart._id, i) }}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShoppingCartBody

import React from 'react';
import { Link } from 'react-router-dom';
import '../css/login.css';


function RegisterLink({ manufacturer }) {
    return (
        <>
            <div>
                <h4><b>Inicia sesión para tener acceso a tus proyectos</b></h4>
            </div>
            <div className='rightContainer-register'>
                {manufacturer ?
                    <Link to='/manufacturers'>
                        <h5 className="text-decoration-underline">¿Eres fabricante? Únete</h5>
                    </Link>
                    :
                    <Link to='/sign-up'>
                        <h5 className="text-decoration-underline">¿No eres miembro aún? Regístrate</h5>
                    </Link>
                }
            </div>
        </>
    )
}

export default RegisterLink

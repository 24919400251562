import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const registerProviderUrl = '/providers/register';

export async function registerProviderApi(dataProvider) {
    try {
      const response = await axios({
        url: baseUrl + registerProviderUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: dataProvider,
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  }
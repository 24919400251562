import React from 'react'
import '../../css/principal.css'
import './css/style.css'
import ManufacturersForm from './components/ManufacturersForm'
import HeaderInformation from './components/HeaderInformation'
import ManufacturersVideo from './components/ManufacturersVideo'

function Manufacturers() {
    return (
        <div className='principalContainer'>
            <div className='mainWrapper'>
                <div className="row">
                    <div className="col-lg-12" style={{ marginBottom: 50 }}>
                        <HeaderInformation />
                    </div>
                    <div className="col-md-6 ManLeftSide">
                        <ManufacturersForm />
                    </div>
                    <div className="col-md-6 ManRightSide">
                        <ManufacturersVideo />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Manufacturers

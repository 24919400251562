import React from 'react';
import '../css/AboutUsSection.css';

function HeroSection({
  lightBg,
  lightText,
  headline,
  description,
  descriptionTwo,
  descriptionThree,
  corpValues
}) {
  return (
    <>
    .
      <div className={lightBg ? 'AboutUs__hero-section' : 'AboutUs__hero-section darkBg'}>
        <div className='container'>
          <h1 className={lightText ? 'heading2' : 'heading dark'}>
            {headline}
          </h1>
          <p className='dark'>
            {description}
          </p>
          <h1 className={lightText ? 'heading' : 'heading dark'}>Misión</h1>
          <p className='dark'>
            {descriptionTwo}
          </p>
          <h1 className={lightText ? 'heading' : 'heading dark'}>Visión</h1>
          <p className='dark'>
            {descriptionThree}
          </p>
          <h1 className={lightText ? 'heading' : 'heading dark'}>Valores</h1>
          <p className='dark' style={{paddingBottom: 50 }}>
            {corpValues} <br/>
            - Somos un equipo creativo y con habilidades técnicas. <br/>
            - Creemos en el trabajo en equipo. <br/>
            - Le damos importancia a la capacidad para resolver problemas. <br/>
            - Nunca renunciaremos a la transparencia, humildad y disciplina. <br/>
          </p>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
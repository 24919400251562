import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
//Url Cart Api
const fetchCreateCartDesignerUrl = '/carts/register';
const fetchGetCartDesignerUrl = '/carts/getCartData';
const fetchGetProvidersUrl = '/providers/getProviders';

//Url Quoter Api
const fetchQuotersProvidersUrl = '/quoter/quote';


let userToken = JSON.parse(localStorage.getItem('userToken'));

//Function's Designer Cart

export async function fetchCreateCartDesignerApi(dataCartDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchCreateCartDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataCartDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchGetCartDesignerApi() {
  try {
    const response = await axios({
      url: baseUrl + fetchGetCartDesignerUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      // data: dataDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchGetProvidersApi() {
  try {
    const response = await axios({
      url: baseUrl + fetchGetProvidersUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'x-auth-token': userToken.token
      },
      // data: dataDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchQuotersProvidersApi(dataForm) {
  try {
    const response = await axios({
      url: baseUrl + fetchQuotersProvidersUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'x-auth-token': userToken.token
      },
      data: dataForm,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}
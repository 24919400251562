export const aboutUsObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Sobre nosotros',
    description:
      'Hola, bienvenidos a protipark.',
    descriptionTwo: 'Buscamos brindar soluciones efectivas en el sector de la fabricación de circuitos impresos. Nos enfocamos en mercados desatendidos como el de prototipado donde se encuentran los estudiantes, egresados o jobistas. Nuestra propuesta de valor se basa en proporcionar tiempos cortos de fabricación, sistema de cotización transparente y excelente atención al cliente.',
    descriptionThree: 'Seremos un referente de comunidad y desarrollo de prototipado y dispositivos electrónicos en latinoamérica y el mundo caracterizándonos por la innovación en procesos de desarrollo, prototipado y producción.',
    corpValues: '- La transparencia es nuestro valor más importante y luchamos por transmitirlo en cada punto de la cadena de valor de la compañía.'
  };
import React, { useState, useEffect, useContext } from 'react';
import Toast from '../../../components/toast/Toast';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import '../css/manufacturerAccount.css';
import { fetchUpdateProviderProfileApi, fetchUpdatePassProviderApi } from '../services';
import { DesignerContext } from '../../../context/DesignerContext';

function AccountManufacturer() {
    const [manufacturerInfo, setManufacturerInfo] = useState({
        profileImage: "",
        companyName: "",
        nitNumber: "",
        nitDiv: "",
        email: "",
        phoneNumber: "",
        companyAdress: "",
        city: "",
        country: "",
        description: "",
        website: "",
        companyType: "",
        companyTypeIdentifier: "",
        obligationCodes: "",
        regime: "",
        password: ""
    });
    const [designerContext, setdesignerContext] = useContext(DesignerContext);

    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);

    const [isLoadingChangePass, setIsLoadingChangePass] = useState(false);
    const [isShowPassChangePass, setIsShowPassChangePass] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formValuesPass, setFormValuesPass] = useState({
        pass: '',
        confirmPass: '',
        oldPass: ''
    });

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const handleFile = (e) => {
        let files = e.target.files;
        let base64Data;
        if (files.length !== 0) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                base64Data = e.target.result;
                setManufacturerInfo({ ...manufacturerInfo, profileImage: base64Data });
            }
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function bringDataProvider() {
        setManufacturerInfo({
            ...manufacturerInfo,
            profileImage: designerContext.profileImage,
            companyName: designerContext.companyName,
            nitNumber: designerContext.nitNumber,
            nitDiv: designerContext.nitDiv,
            email: designerContext.email,
            phoneNumber: designerContext.phoneNumber,
            companyAdress: designerContext.city,
            city: designerContext.city,
            country: designerContext.country,
            description: designerContext.description,
            website: designerContext.website,
            companyType: designerContext.companyType,
            companyTypeIdentifier: designerContext.companyTypeIdentifier,
            obligationCodes: designerContext.obligationCodes,
            regime: designerContext.regime
        });
    }

    async function handleSubmitProfile(e) {
        e.preventDefault();
        setIsLoading(true);
        const response = await fetchUpdateProviderProfileApi(manufacturerInfo);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setdesignerContext(manufacturerInfo);
                setIsLoading(false);
                setIsEdit(!isEdit);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }

    }

    async function handleResetPass(e) {
        e.preventDefault();
        setIsLoadingChangePass(true);
        if (formValuesPass.pass === formValuesPass.confirmPass) {
            const response = await fetchUpdatePassProviderApi(formValuesPass);
            if (typeof response !== 'undefined') {
                if (response.data.done) {
                    setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                    setIsLoadingChangePass(false);
                    setIsModalOpen(false);
                }
                if (response.data.info) {
                    setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                    setIsLoadingChangePass(false);
                }
                if (response.data.error) {
                    setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                    setIsLoadingChangePass(false);
                }
            } else {
                console.log("problema en la peticion");
                setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
                setIsLoadingChangePass(false);
                return
            }
        } else {
            setConfigToast({ ...configToast, typeToast: "warning", textToast: "Las contraseñas no coinciden" });
            setIsLoadingChangePass(false);
        }
    }

    useEffect(() => {
        bringDataProvider();

    }, [])

    return (
        <section>
            {componentToast()}
            <div className="informationContainer">
                <h3 style={{ padding: 25 }}><b>Información de la empresa</b></h3>
                <div className="firstContainer">
                    <div className="card">
                        <div className="informationContainer-Section">
                            <form className="row g-3" onSubmit={handleSubmitProfile}>
                                <div className="col-lg-12">
                                    {manufacturerInfo.profileImage !== '' ?
                                        <img src={manufacturerInfo.profileImage} alt="uploadImage" style={{ width: 110, marginBottom: 15 }} />
                                        :
                                        <img src="https://bulma.io/images/placeholders/128x128.png" alt="Empty" style={{ width: 110, marginBottom: 15 }} />
                                    } <br />

                                    <input className="form-control" type="file" id="formFile" onChange={handleFile} disabled={!isEdit} />
                                </div>
                                <div className="col-md-7">
                                    <label htmlFor="inputCompanyName" className="form-label">Nombre de la empresa *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.companyName} id="manufacturerName"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyName: e.target.value }) }}
                                        name="companyName" required disabled={!isEdit} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="inputCompanyNit" className="form-label">N.I.T de la empresa *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.nitNumber} id="manufacturerNit"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, nitNumber: e.target.value }) }}
                                        name="nitNumber" required disabled={!isEdit} />
                                </div>
                                <div className="col-md-1">
                                    <label htmlFor="inputCompanyNit" className="form-label">N.I.T Div *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.nitDiv} id="nitDiv"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, nitDiv: e.target.value }) }}
                                        name="nitDiv" required disabled={!isEdit} />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="inputCompanyName" className="form-label">Tipo de empresa *</label>
                                    <select className="form-select" aria-label="Material select" id="companyType"
                                        name="companyType" onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyType: e.target.value }) }}
                                        value={manufacturerInfo.companyType} required disabled={!isEdit}>
                                        <option hidden>Seleccionar</option>
                                        <option value="1">Persona jurídica</option>
                                        <option value="2">Persona natural</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="inputCompanyNit" className="form-label">ID tipo de empresa *</label>
                                    <select className="form-select" aria-label="Material select" id="companyTypeIdentifier"
                                        name="companyTypeIdentifier" onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyTypeIdentifier: e.target.value }) }}
                                        value={manufacturerInfo.companyTypeIdentifier} required disabled={!isEdit}>
                                        <option hidden>Seleccionar</option>
                                        <option value="1">Documento de identificación extranjero</option>
                                        <option value="2">Tarjeta de identidad</option>
                                        <option value="3">Cédula de ciudadanía</option>
                                        <option value="4">Pasaporte</option>
                                        <option value="5">NIT</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="inputCompanyNit" className="form-label">Codigo de obligacion *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.obligationCodes} id="obligationCodes"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, obligationCodes: e.target.value }) }}
                                        name="obligationCodes" required disabled={!isEdit} />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="inputCompanyNit" className="form-label">Regimen *</label>
                                    <select className="form-select" aria-label="Material select" id="regime"
                                        name="regime" onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, regime: e.target.value }) }}
                                        value={manufacturerInfo.regime} required disabled={!isEdit}>
                                        <option hidden>Seleccionar</option>
                                        <option value="1">Regimen simple</option>
                                        <option value="2">Regimen ordinario</option>
                                        <option value="3">Gran contribuyente</option>
                                        <option value="4">Autorretenedor</option>
                                        <option value="5">Agente de retención IVA</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputCompanyEmail" className="form-label">Email *</label>
                                    <input type="email" className="form-control" value={manufacturerInfo.email} id="contactEmail"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, email: e.target.value }) }}
                                        name="email" required disabled={!isEdit} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputCompanyNumber" className="form-label">Teléfono *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.phoneNumber} id="contactNumber"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, phoneNumber: e.target.value }) }}
                                        name="phoneNumber" required disabled={!isEdit} />
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="inputCompanyAdress" className="form-label">Dirección *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.companyAdress} id="companyAdress"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, companyAdress: e.target.value }) }}
                                        name="companyAdress" required disabled={!isEdit} />
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="inputCompanyCity" className="form-label">Ciudad *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.city} id="companyCity"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, city: e.target.value }) }}
                                        name="city" required disabled={!isEdit} />
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="inputCompanyCountry" className="form-label">País *</label>
                                    <input type="text" className="form-control" value={manufacturerInfo.country} id="companyCountry"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, country: e.target.value }) }}
                                        name="country" required disabled={!isEdit} />
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="inputCompanyWebsite" className="form-label">Website </label>
                                    <input type="text" className="form-control" value={manufacturerInfo.website} id="companyWebPage"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, website: e.target.value }) }}
                                        name="website" disabled={!isEdit} />
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="inputCompanyDescription" className="form-label">Descripción </label>
                                    <input type="text" className="form-control" value={manufacturerInfo.description} id="companyDescription"
                                        onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, description: e.target.value }) }}
                                        name="description" disabled={!isEdit} />
                                </div>
                                {isEdit ?
                                    <>
                                        <label htmlFor="inputPassword" className="form-label"><b>Contraseña Actual *</b></label>
                                        {isShowPass ?
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" id="inputPasswordHide" placeholder="Contraseña Actual" name="password"
                                                    onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, password: e.target.value }) }}
                                                    autoComplete="on" value={manufacturerInfo.password} required />
                                                <span className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEyeSlash} /></span>
                                            </div>
                                            :
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" id="inputPasswordShow" placeholder="Contraseña Actual" name="password"
                                                    onChange={(e) => { setManufacturerInfo({ ...manufacturerInfo, password: e.target.value }) }}
                                                    autoComplete="on" value={manufacturerInfo.password} required />
                                                <span className="input-group-text" id="basic-addon2" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEye} /></span>
                                            </div>
                                        }
                                    </>
                                    : null}
                                {!isEdit ?
                                    <div className="col-4">
                                        <button type="button" className="btn btn-primary" onClick={() => { setIsEdit(!isEdit) }}>Editar</button>
                                    </div>
                                    :
                                    <>
                                        <div className="col-md-2">
                                            <button type="button" className="btn btn-danger" onClick={() => { setIsEdit(!isEdit) }}>Cancelar</button>
                                        </div>
                                        {isLoading ?
                                            <div className="col-md-2">
                                                <button className="btn btn-primary" type="submit" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                            </div>
                                            :
                                            <div className="col-md-2">
                                                <button type="submit" className="btn btn-success" >Guardar</button>
                                            </div>
                                        }
                                    </>
                                }

                                <div className="col-6">
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#changePassModal" onClick={() => setIsModalOpen(true)}>Cambiar contraseña</button>
                                </div>

                            </form>

                            <div className={`modal fade changePassModal ${isModalOpen ? 'show' : ''}`}
                                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                                id="changePassModal" tabIndex="1" aria-labelledby="changePassModalLabel" aria-hidden="true">
                                {componentToast()}
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="modal-title" id="changePassModalLabel">Actualización de contraseña</h2>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Por favor escribe tu contraseña actual.</p>
                                            <form onSubmit={handleResetPass} className="row g-3">
                                                <div className="col-md-6">
                                                    <label htmlFor="inputChangePassOldConfirm" className="form-label"><b>Contraseña actual *</b></label>
                                                    <input type={isShowPassChangePass ? "text" : "password"} name="oldPass" className="form-control" id="inputChangePassOldConfirm" placeholder="Confirmar contraseña"
                                                        onChange={(e) => { setFormValuesPass({ ...formValuesPass, oldPass: e.target.value }) }}
                                                        autoComplete="on" value={formValuesPass.oldPass} required />
                                                </div><br />
                                                <p>Ahora escribe tus nuevas contraseñas.</p>
                                                <div className="col-md-6">
                                                    <label htmlFor="inputChangePass" className="form-label"><b>Nueva contraseña *</b></label>
                                                    <input type={isShowPassChangePass ? "text" : "password"} name="password" className="form-control" id="inputChangePass" placeholder="Contraseña"
                                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="La contraseña debe tener 8 caracteres, incluida 1 letra mayúscula, 1 letra minúscula y 1 número"
                                                        onChange={(e) => { setFormValuesPass({ ...formValuesPass, pass: e.target.value }) }}
                                                        autoComplete="on" value={formValuesPass.pass} required />
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="inputChangePassConfirm" className="form-label"><b>Confirmar contraseña *</b></label>
                                                    <input type={isShowPassChangePass ? "text" : "password"} name="password2" className="form-control" id="inputChangePassConfirm" placeholder="Confirmar contraseña"
                                                        onChange={(e) => { setFormValuesPass({ ...formValuesPass, confirmPass: e.target.value }) }}
                                                        autoComplete="on" value={formValuesPass.confirmPass} required />
                                                </div>
                                                <br />

                                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                    {isShowPassChangePass ?
                                                        <div className="input-group mb-3">
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                                            <a className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPassChangePass(!isShowPassChangePass) }}><FontAwesomeIcon icon={faEyeSlash} /></a>
                                                        </div>
                                                        :
                                                        <div className="input-group mb-3">
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                                            <a className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPassChangePass(!isShowPassChangePass) }}><FontAwesomeIcon icon={faEye} /></a>
                                                        </div>
                                                    }

                                                </div>
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                                                </div>
                                                {isLoadingChangePass ?
                                                    <div className="col-4">
                                                        <button className="btn btn-primary" type="submit" disabled>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="col-4">
                                                        <button type="submit" className="btn btn-success">Actualizar</button>
                                                    </div>
                                                }
                                            </form><br />
                                        </div>
                                        <div className="modal-footer">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AccountManufacturer

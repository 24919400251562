import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import Toast from '../../../components/toast/Toast';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import '../css/style.css';
import { registerProviderApi } from '../services';

function ManufacturersForm() {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''

    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);

    const [formValues, setFormValues] = useState({
        companyName: '',
        nitNumber: '',
        email: '',
        password: '',
        confirmPass: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target
        setFormValues({ ...formValues, [name]: value });
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (formValues.password === formValues.confirmPass) {
            let numberNit = formValues.nitNumber;
            let lastChar = numberNit.substr(numberNit.length - 1);
            formValues.nitDiv = lastChar;
            registerManufacturer();
        } else {
            setConfigToast({ ...configToast, typeToast: "warning", textToast: "Las contraseñas no coinciden" });
            setIsLoading(false);
        }
    }

    async function registerManufacturer() {
        const response = await registerProviderApi(formValues);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                formValues({ ...formValues, companyName: '', nitNumber: '', email: '', password: '', confirmPass: '' });
                setIsModalOpen(true);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    return (
        <>
            <div className='lefManContainer'>
                {componentToast()}
                <div className='header'>
                    <div className='header-title'>
                        <h2>Únete</h2>
                    </div>
                    <div className='header-logo'>
                        <img src="images/PROTOTIPARK_NOTION.png" alt="" />
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="formGroupNameCompany" className="form-label"><b>Nombre de la empresa *</b></label>
                        <input type="text" className="form-control" id="formGroupNameCompany"
                            name="companyName" onChange={handleChange} value={formValues.companyName} placeholder="Nombre de la empresa" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formGroupNitCompany" className="form-label"><b>Nit *</b></label>
                        <input type="text" className="form-control" id="formGroupNitCompany"
                            name="nitNumber" onChange={handleChange} value={formValues.nitNumber} placeholder="000.000.000" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formGroupEmail" className="form-label"><b>Email de contacto *</b></label>
                        <input type="text" className="form-control" id="formGroupEmail"
                            name="email" onChange={handleChange} value={formValues.email} placeholder="user@example.com" required />
                    </div>

                    <div className="row mb-3">

                        <div className="col-md-5">
                            <label htmlFor="inputChangePass" className="form-label"><b>Nueva contraseña *</b></label>
                            <input type={isShowPass ? "text" : "password"} name="password" className="form-control" id="inputChangePass" placeholder="Contraseña"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="La contraseña debe tener 8 caracteres, incluida 1 letra mayúscula, 1 letra minúscula y 1 número"
                                onChange={handleChange} value={formValues.password} required />
                        </div>

                        <div className="col-md-5">
                            <label htmlFor="inputChangePassConfirm" className="form-label"><b>Confirmar contraseña *</b></label>
                            <input type={isShowPass ? "text" : "password"} name="confirmPass" className="form-control" id="inputChangePassConfirm" placeholder="Confirmar contraseña"
                                onChange={handleChange} value={formValues.confirmPass} required />
                        </div>
                        <div className="btn-group col-sm-2" style={{ alignItems: 'flex-end' }} role="group" aria-label="Basic radio toggle button group">
                            {isShowPass ?
                                <div>
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                    <a className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEyeSlash} /></a>
                                </div>
                                :
                                <div>
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                    <a className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEye} /></a>
                                </div>
                            }
                        </div>
                    </div>
                    <br />
                    <div className='leftManContainer-footer'>
                        {isLoading ?
                            <div className='leftManContainer-footer--buttonBox'>
                                <Button buttonColor='primary' buttonStyle="btn--primary" type="submit" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </Button>
                            </div>
                            :
                            <div className='leftManContainer-footer--buttonBox'>
                                <Button type="submit" buttonStyle="btn--primary" buttonColor='primary' >Enviar</Button>
                            </div>
                        }
                        <div className='leftContainer-footer--options'>
                            <Link to='/sign-in'>
                                <p className="text-decoration-underline">Ya estoy registrado</p>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>

            <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                id="registerModal" tabIndex="1" aria-labelledby="registerModalLabel" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Registro Exitoso</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsModalOpen(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p> Su cuenta ha sido creada, por favor revise su bandeja de entrada de correo electrónico para activarla.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => setIsModalOpen(false)}>Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManufacturersForm

import React from 'react'

function HeaderInformation() {
    return (
        <>
            <div className='headerInformation'>
                <h1>¿Eres fabricante?</h1>
                <p>No esperas mas y únete a la mayor plataforma de fabricantes de PCB's, envía tu solicitud de fabricante y será atendida lo más pronto posible, por favor diligencia el formulario de contacto y visualiza el video que se muestra en la sección derecha, es una guía especial elaborada para fabricantes.</p>
            </div>
            
        </>
    )
}

export default HeaderInformation

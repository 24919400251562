import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { getInitialContent } from '../pages/MasterAdmin/services';
import Toast from './toast/Toast';

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  buttonLabel2,
  img,
  alt,
  imgStart
}) {
  const [formShort, setFormShort] = useState({
    layers: '',
    dimensionX: '',
    dimensionY: '',
  });
  const [descriptionSlogan, setDescriptionSlogan] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [configToast, setConfigToast] = useState({
    typeToast: '',
    textToast: ''
  });
  let componentToast = () => {
    if (configToast.typeToast !== '') {
      return <Toast typeToast={configToast.typeToast}
        changeError={changeError}
        textToast={configToast.textToast} />
    } else {
      return null
    }
  }

  const changeError = () => {
    setConfigToast({ ...configToast, typeToast: '', textToast: '' });
  }

  async function bringContent() {
    const response = await getInitialContent();
    if (typeof response !== 'undefined') {
      if (response.data.content) {
        let content = response.data.content[0];
        if (Object.keys(response.data.content).length !== 0) {
          setDescriptionSlogan(content.slogan);
          setBannerImage(content.backgroundImage);
        }
      }
      if (response.data.error) {
        setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
      }
      if (response.data.info) {
        setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
      }
    } else {
      console.log("problema en la peticion");
      setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
      return
    }
  }

  useEffect(() => {
    bringContent();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormShort({ ...formShort, [name]: value });
  }

  let componentUrl = `/services?lay=${formShort.layers}&dimX=${formShort.dimensionX}&dimY=${formShort.dimensionY}`;

  return (
    <div
      className={bannerImage === '' ? 'home__hero-section darkBg' : 'home__hero-section'}
      style={bannerImage !== '' ? { backgroundImage: "url(" + bannerImage + ")", backgroundSize: 'cover' } : null}
    >
      {componentToast()}
      <div className='container'>
        <div
          className='row home__hero-row'
          style={{
            display: 'flex',
            flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
          }}
        >
          <div className='col'>
            <div className='home__hero-text-wrapper'>
              <div className='top-line'>{topLine}</div>
              <h1 className={lightText ? 'heading' : 'heading dark'}>
                {headline}
              </h1>
              <p
                className={
                  lightTextDesc
                    ? 'home__hero-subtitle'
                    : 'home__hero-subtitle dark'
                }
              >
                {descriptionSlogan ? descriptionSlogan : description}
              </p>
              <Link to='/sign-in' className='buttonHome'>
                <Button buttonSize='btn--wide' buttonColor='blue' className='buttonHome'>
                  {buttonLabel}
                </Button>
              </Link>
              <Link to='/manufacturers'>
                <Button buttonSize='btn--wide' buttonColor='blue' className='buttonHome'>
                  {buttonLabel2}
                </Button>
              </Link>
            </div>
          </div>
          <div className='col'>
            <div className='home__hero-img-wrapper'>
              {/* <img src={img} alt={alt} className='home__hero-img' /> */}
              <form>
                <div className="row g-3">
                  <div className="col-md-4">
                    <label htmlFor="inputState" className="form-label">CAPAS</label>
                    <select className="form-select" aria-label="layer select" id="layerSelect" name="layers"
                      onChange={handleChange} value={formShort.layers}>
                      <option hidden>Seleccionar</option>
                      <option value="layer_0">1</option>
                      <option value="layer_1">2</option>
                      <option value="layer_2">4</option>
                      <option value="layer_3">6</option>
                      <option value="layer_4">8</option>
                      <option value="layer_5">10</option>
                      <option value="layer_6">12</option>
                      <option value="layer_7">14</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputState" className="form-label">TAMAÑO X</label>
                    <input type="number" placeholder="X" className="form-control" id="inputDimensionX" style={{ marginRight: 10 }}
                      name="dimensionX" onChange={handleChange} value={formShort.dimensionX} />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputState" className="form-label">TAMAÑO Y</label>
                    <input type="number" placeholder="Y" className="form-control" id="inputDimensionY" style={{ marginRight: 10 }}
                      name="dimensionY" onChange={handleChange} value={formShort.dimensionY} />
                  </div>
                  <div className="col-md-4">

                    <Link to={componentUrl} className='buttonHome' aria-disabled>
                      <Button buttonSize='btn--wide' buttonColor='blue' className='buttonHome'>
                        Cotizar
                    </Button>
                    </Link>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
import React from 'react'
import RegisterForm from './components/RegisterForm'

function Register() {
    return (
        <div className='principalContainer'>
            <div className='mainWrapper'>
                <div className="row">
                    <div className="col-md-6">
                        <RegisterForm />
                    </div>
                    <div className="col-md-6 rightContainer">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register

import React from 'react'

function ManufacturersVideo() {
    return (
        <>
            <div>
                <h1>Aqui va un Video</h1>
            </div>
        </>
    )
}

export default ManufacturersVideo

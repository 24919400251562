import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import Toast from '../../../components/toast/Toast';
import '../../../css/principal.css';
import '../css/register.css';
import { registerDesignerApi } from '../services';

function RegisterForm() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        lastName: '',
        email: '',
        password: '',
        confirmPass: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target
        setFormValues({ ...formValues, [name]: value });
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (formValues.password === formValues.confirmPass) {
            registerDesigner();
        } else {
            setConfigToast({ ...configToast, typeToast: "warning", textToast: "Passwords do not match" });
            setIsLoading(false);
        }
    }

    async function registerDesigner() {
        const response = await registerDesignerApi(formValues);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                setFormValues({ ...formValues, name: '', lastName: '', email: '', password: '', confirmPass: '' });
                setIsModalOpen(true);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    return (
        <>
            <div className='leftContainer'>
                {componentToast()}
                <div className='header'>
                    <div className='header-title'>
                        <h2>Regístrate</h2>
                    </div>
                    <div className='header-logo'>
                        <img src="images/PROTOTIPARK_NOTION.png" alt="" />
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="formGroupExampleInput" className="form-label"><b>Nombre</b></label>
                        <input type="text" className="form-control" id="formGroupExampleInput1"
                            name="name" onChange={handleChange} value={formValues.name} placeholder="Nombre" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formGroupExampleInput" className="form-label"><b>Apellidos</b></label>
                        <input type="text" className="form-control" id="formGroupExampleInput2"
                            name="lastName" onChange={handleChange} value={formValues.lastName} placeholder="Apellidos" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formGroupExampleInput" className="form-label"><b>Email</b></label>
                        <input type="email" className="form-control" id="formGroupExampleInput3"
                            name="email" onChange={handleChange} value={formValues.email} placeholder="usuario@prototipark.com" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formGroupExampleInput2" className="form-label"><b>Contraseña</b></label>
                        <input type="password" className="form-control" id="formGroupExampleInput4" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title="La contraseña debe tener 8 caracteres, incluida 1 letra mayúscula, 1 letra minúscula y 1 número" name="password" onChange={handleChange} value={formValues.password} placeholder="Contraseña" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formGroupExampleInput2" className="form-label"><b>Confirmar contraseña</b></label>
                        <input type="password" className="form-control" id="formGroupExampleInput5"
                            name="confirmPass" onChange={handleChange} value={formValues.confirmPass} placeholder="Contraseña" required />
                    </div>
                    <div className='leftContainer-footer'>
                        {isLoading ?
                            <div className='leftContainer-footer--buttonBox'>
                                <Button buttonColor='primary' buttonStyle="btn--primary" type="submit" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </Button>
                            </div>
                            :
                            <div className='leftContainer-footer--buttonBox'>
                                <Button type="submit" buttonStyle="btn--primary" buttonColor='primary' >Registrarme</Button>
                            </div>
                        }

                        <div className='leftContainer-footer--options'>
                            <Link to='/sign-in'>
                                <p className="text-decoration-underline">Ya tengo una cuenta</p>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>

            <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                id="registerModal" tabIndex="1" aria-labelledby="registerModalLabel" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Registro Exitoso</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsModalOpen(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p> Su cuenta ha sido creada, por favor revise su bandeja de entrada de correo electrónico para activarla.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => setIsModalOpen(false)}>Entendido</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RegisterForm

import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const getDesignerDataUrl = '/designers/completeRegister';

export async function getActiveDesignerApi(tokenUser) {
    try {
      const response = await axios({
        url: baseUrl + getDesignerDataUrl,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': tokenUser
        },
        // data: dataDesigner,
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  }
import React, { useState, useEffect } from 'react';


const ModalCartDetails = ({ isModalOpen, cartInfo, handleCloseModal }) => {
    const [selectItem, setSelectItem] = useState({
        index: 0,
        cartSelect: {}
    });

    const handleSwitchFormat = (value) => {
        switch (value) {
            case 'FORMAT_0':
                return (<label className="form-check-label" htmlFor="gridRadios1">
                    Single PCB
                </label>)
            case 'FORMAT_1':
                return (<label className="form-check-label" htmlFor="gridRadios2">
                    Designer panel
                </label>)
            case 'FORMAT_2':
                return (<label className="form-check-label" htmlFor="gridRadios1">
                    Protipark panel
                </label>)
            default:
                break;
        }
    }

    const handleSwitchSurface = (value) => {
        switch (value) {
            case 'SURFACE_0':
                return (<label className="form-check-label" htmlFor="surfaceRadios1">
                    HASL-Lead
                </label>)
            case 'SURFACE_1':
                return (<label className="form-check-label" htmlFor="surfaceRadios1">
                    HASL-RoHS
                </label>)
            case 'SURFACE_2':
                return (<label className="form-check-label" htmlFor="surfaceRadios1">
                    ENIG-RoHS
                </label>)
            default:
                break;
        }
    }


    useEffect(() => {
        if (Object.keys(cartInfo).length !== 0 && isModalOpen) {
            setSelectItem({ ...selectItem, index: 0, cartSelect: cartInfo[0] });
        }
    }, [isModalOpen]);

    return (
        <>
            <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                id="registerModal" tabIndex="1" aria-labelledby="registerModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 1050 }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Detalles de la orden</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModal(false)}></button>
                        </div>
                        <div className="modal-body" style={{ height: 450, overflowY: 'auto' }}>
                            {/* <h2 style={{ marginRight: "10px" }}>Seleccionar bodega: </h2> */}
                            {Object.keys(cartInfo).length !== 0 && Object.keys(cartInfo).length <= 1 ?
                                <>
                                    <select className="form-select form-select-sm" aria-label="Default select example" style={{ marginBottom: 20 }}>
                                        <option> 1).{" "}{cartInfo[0].description}</option>
                                    </select>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className='card' style={{ padding: 10 }}>
                                                <div className='cardFormHeader'>
                                                    <h5>Propiedades de tu PCB</h5>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputLayer" className="col-sm-3 col-form-label">Capas:</label>
                                                        <div className="col-sm-4">
                                                            <select className="form-select" aria-label="layer select"
                                                                id="layerSelect" name="layers" value={cartInfo[0].layers} disabled>
                                                                <option value="LAYER_0">1</option>
                                                                <option value="LAYER_1">2</option>
                                                                <option value="LAYER_2">4</option>
                                                                <option value="LAYER_3">6</option>
                                                                <option value="LAYER_4">8</option>
                                                                <option value="LAYER_5">10</option>
                                                                <option value="LAYER_6">12</option>
                                                                <option value="LAYER_7">14</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputdimensions" className="col-sm-6 col-form-label">Dimensiones:</label>
                                                        <div className="col-md-4 dimensionBox">
                                                            <label htmlFor=""> {cartInfo[0].dimensions.dimension_x} x {cartInfo[0].dimensions.dimension_y} mm</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputMaterial" className="col-sm-4 col-form-label">Material:</label>
                                                        <div className="col-sm-8">
                                                            <select className="form-select" aria-label="Material select" id="materialgSelect"
                                                                name="material" value={cartInfo[0].material} disabled>
                                                                <option value="MATERIAL_0">FR2</option>
                                                                <option value="MATERIAL_1">FR4-TG130-140</option>
                                                                <option value="MATERIAL_2">FR4-TG150-160</option>
                                                                <option value="MATERIAL_3">FR4-TG170-180</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputThickness" className="col-sm-4 col-form-label">Thickness:</label>
                                                        <div className="col-sm-4">
                                                            <select className="form-select" aria-label="thickness select" id="thicknessSelect"
                                                                name="thickness" value={cartInfo[0].thickness} disabled>
                                                                <option value="THICKNESS_0">0.2</option>
                                                                <option value="THICKNESS_1">0.4</option>
                                                                <option value="THICKNESS_2">0.6</option>
                                                                <option value="THICKNESS_3">0.8</option>
                                                                <option value="THICKNESS_4">1.2</option>
                                                                <option value="THICKNESS_5">1.4</option>
                                                                <option value="THICKNESS_6">1.6</option>
                                                                <option value="THICKNESS_7">1.8</option>
                                                                <option value="THICKNESS_8">2.0</option>
                                                                <option value="THICKNESS_9">2.4</option>
                                                                <option value="THICKNESS_10">2.8</option>
                                                                <option value="THICKNESS_11">3.0</option>
                                                                <option value="THICKNESS_12">3.2</option>
                                                                <option value="THICKNESS_13">3.6</option>
                                                                <option value="THICKNESS_14">3.8</option>
                                                                <option value="THICKNESS_15">4.0</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputTrackSpacing" className="col-sm-7 col-form-label">Min Track/Spacing:</label>
                                                        <div className="col-sm-5">
                                                            <select className="form-select" aria-label="trackSpacing select" id="trackSpacingSelect"
                                                                name="minTrackSpacing" value={cartInfo[0].minTrackSpacing} disabled >
                                                                <option value="MIN_TRACK_SPACING_0">3/3 mil</option>
                                                                <option value="MIN_TRACK_SPACING_1">4/4 mil</option>
                                                                <option value="MIN_TRACK_SPACING_2">5/5 mil</option>
                                                                <option value="MIN_TRACK_SPACING_3">6/6 mil</option>
                                                                <option value="MIN_TRACK_SPACING_4">8/8 mil</option>
                                                                <option value="MIN_TRACK_SPACING_5">10/10 mil</option>
                                                                <option value="MIN_TRACK_SPACING_6">from 15/15 mil</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputHoleSize" className="col-sm-7 col-form-label">Min Hole size:</label>
                                                        <div className="col-sm-5">
                                                            <select className="form-select" aria-label="holeSize select" id="holeSizeSelect"
                                                                name="minHoleSize" value={cartInfo[0].minHoleSize} disabled>
                                                                <option value="MIN_HOLESIZE_0">0.15 mm</option>
                                                                <option value="MIN_HOLESIZE_1">0.20 mm</option>
                                                                <option value="MIN_HOLESIZE_2">0.25 mm</option>
                                                                <option value="MIN_HOLESIZE_3">0.30 mm</option>
                                                                <option value="MIN_HOLESIZE_4">0.40 mm</option>
                                                                <option value="MIN_HOLESIZE_5">from 0.50 mm</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputSolderMaskColor" className="col-sm-5 col-form-label">Mask color:</label>
                                                        <div className="col-sm-5">
                                                            <select className="form-select" aria-label="solderMaskColor select" id="solderMaskColorSelect"
                                                                name="soldermaskColor" value={cartInfo[0].soldermaskColor} disabled >
                                                                <option value="SOLDERMASK_0">Green</option>
                                                                <option value="SOLDERMASK_1">Red</option>
                                                                <option value="SOLDERMASK_2">Yellow</option>
                                                                <option value="SOLDERMASK_3">Blue</option>
                                                                <option value="SOLDERMASK_4">Purple</option>
                                                                <option value="SOLDERMASK_5">White</option>
                                                                <option value="SOLDERMASK_6">Black</option>
                                                                <option value="SOLDERMASK_7">None</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="silkScreenColor" className="col-sm-5 col-form-label">Silk color:</label>
                                                        <div className="col-sm-5">
                                                            <select className="form-select" aria-label="silkScreenColor select" id="silkScreenColorSelect"
                                                                name="silkscreenColor" value={cartInfo[0].silkscreenColor} disabled >
                                                                <option value="SILKSCREEN_0">White</option>
                                                                <option value="SILKSCREEN_1">Black</option>
                                                                <option value="SILKSCREEN_2">Yellow</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputMaterial" className="col-form-label col-sm-6 pt-0">Goldfinger:</label>
                                                        <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3 }}>
                                                            <input className="form-check-input" type="checkbox" id="goldFinger"
                                                                name="goldFinger" checked={cartInfo[0].goldFinger} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputMaterial" className="col-form-label col-sm-8 pt-0">CastellatedHoles:</label>
                                                        <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3 }}>
                                                            <input className="form-check-input" type="checkbox" id="castellateHoles"
                                                                name="castellatedHoles" checked={cartInfo[0].castellatedHoles} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                                <fieldset className="row mb-3">
                                                    <legend className="col-form-label col-sm-6 pt-0">SurfaceFinish:</legend>
                                                    <div className="col-lg-6">
                                                        {handleSwitchSurface(cartInfo[0].surfaceFinish)}
                                                    </div>
                                                </fieldset>
                                                <fieldset className="row mb-3">
                                                    <legend htmlFor="inputCooperWeight" className="col-form-label col-sm-6 pt-0">copperWeight:</legend>
                                                    <div className="col-sm-6" >
                                                        <label className="form-check-label" htmlFor="cooperRadios3">
                                                            {cartInfo[0].copperWeight === 'COPPER_0' ?
                                                                <p>1 Oz</p>
                                                                :
                                                                <p>2 Oz</p>
                                                            }
                                                        </label>
                                                    </div>

                                                </fieldset>

                                                <div className="row mb-3">
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputQty" className="col-form-label col-sm-5 pt-0">Cantidad:</label>
                                                        <div className="col-sm-6">
                                                            <label className="form-check-label" htmlFor="gridRadios1">
                                                                {cartInfo[0].qty}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <fieldset className="row mb-3">
                                                    <legend className="col-form-label col-sm-5 pt-0">Formato:</legend>
                                                    <div className="col-lg-6">
                                                        {handleSwitchFormat(cartInfo[0].format)}
                                                    </div>
                                                </fieldset>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6 optionContainer">
                                                        <label htmlFor="inputComments" className="col-form-label col-sm-5 pt-0">Comentarios:</label>
                                                        <div className="col-md-7">
                                                            <textarea type="text" className="form-control" id="inputOrderComment" placeholder='Comments'
                                                                name="remark" value={cartInfo[0].remark} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row mb-3">
                                                    <div className="col-sm-10">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="gridCheck1" disabled />
                                                            <label className="form-check-label" htmlFor="gridCheck1">
                                                                Guardar Diseño en mi perfil
                                </label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="col-lg-12">
                                                    <div className='manImgCont'>
                                                        <img src="images/PROTOTIPARK_NOTION.png" className="card-img-top manImg" alt="..." />
                                                    </div>
                                                    <div className="card-body">
                                                        <h5 className="card-title">{cartInfo[0].providerCompany}</h5>
                                                        <p className="card-text">Tiempo de producción: <br />{cartInfo[0].productionTime}{" "}horas</p>
                                                        <p className="card-text">Precio: {"$ "}{Intl.NumberFormat("de-DE").format(cartInfo[0].totalPrice)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <select className="form-select form-select-sm" aria-label="Default select example" style={{ marginBottom: 20 }}
                                        value={selectItem.index}
                                        onChange={(e) => { setSelectItem({ ...selectItem, index: Number(e.target.value), cartSelect: cartInfo[Number(e.target.value)] }) }}>
                                        {cartInfo.map((cart, j) => {
                                            return (
                                                <option key={j} value={j}>{j + 1}).{" "}{cart.description}</option>
                                            )
                                        })}
                                    </select>
                                    {Object.keys(cartInfo).length !== 0 && isModalOpen ?
                                        <div className="row">
                                            <div className="col-md-9">


                                                <div className='card' style={{ padding: 10 }}>
                                                    <div className='cardFormHeader'>
                                                        <h5>Propiedades de tu PCB</h5>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputLayer" className="col-sm-3 col-form-label">Capas:</label>
                                                            <div className="col-sm-4">
                                                                <select className="form-select" aria-label="layer select"
                                                                    id="layerSelect" name="layers" value={selectItem.cartSelect.layers} disabled>
                                                                    <option value="LAYER_0">1</option>
                                                                    <option value="LAYER_1">2</option>
                                                                    <option value="LAYER_2">4</option>
                                                                    <option value="LAYER_3">6</option>
                                                                    <option value="LAYER_4">8</option>
                                                                    <option value="LAYER_5">10</option>
                                                                    <option value="LAYER_6">12</option>
                                                                    <option value="LAYER_7">14</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputdimensions" className="col-sm-6 col-form-label">Dimensiones:</label>
                                                            <div className="col-md-6 dimensionBox">
                                                                {Object.keys(selectItem.cartSelect).length !== 0 ?
                                                                    <label htmlFor="">{selectItem.cartSelect.dimensions.dimension_x} x {selectItem.cartSelect.dimensions.dimension_y} mm</label>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputMaterial" className="col-sm-4 col-form-label">Material:</label>
                                                            <div className="col-sm-8">
                                                                <select className="form-select" aria-label="Material select" id="materialgSelect"
                                                                    name="material" value={selectItem.cartSelect.material} disabled>
                                                                    <option value="MATERIAL_0">FR2</option>
                                                                    <option value="MATERIAL_1">FR4-TG130-140</option>
                                                                    <option value="MATERIAL_2">FR4-TG150-160</option>
                                                                    <option value="MATERIAL_3">FR4-TG170-180</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputThickness" className="col-sm-4 col-form-label">Thickness:</label>
                                                            <div className="col-sm-4">
                                                                <select className="form-select" aria-label="thickness select" id="thicknessSelect"
                                                                    name="thickness" value={selectItem.cartSelect.thickness} disabled>
                                                                    <option value="THICKNESS_0">0.2</option>
                                                                    <option value="THICKNESS_1">0.4</option>
                                                                    <option value="THICKNESS_2">0.6</option>
                                                                    <option value="THICKNESS_3">0.8</option>
                                                                    <option value="THICKNESS_4">1.2</option>
                                                                    <option value="THICKNESS_5">1.4</option>
                                                                    <option value="THICKNESS_6">1.6</option>
                                                                    <option value="THICKNESS_7">1.8</option>
                                                                    <option value="THICKNESS_8">2.0</option>
                                                                    <option value="THICKNESS_9">2.4</option>
                                                                    <option value="THICKNESS_10">2.8</option>
                                                                    <option value="THICKNESS_11">3.0</option>
                                                                    <option value="THICKNESS_12">3.2</option>
                                                                    <option value="THICKNESS_13">3.6</option>
                                                                    <option value="THICKNESS_14">3.8</option>
                                                                    <option value="THICKNESS_15">4.0</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputTrackSpacing" className="col-sm-7 col-form-label">Min Track/Spacing:</label>
                                                            <div className="col-sm-5">
                                                                <select className="form-select" aria-label="trackSpacing select" id="trackSpacingSelect"
                                                                    name="minTrackSpacing" value={selectItem.cartSelect.minTrackSpacing} disabled >
                                                                    <option value="MIN_TRACK_SPACING_0">3/3 mil</option>
                                                                    <option value="MIN_TRACK_SPACING_1">4/4 mil</option>
                                                                    <option value="MIN_TRACK_SPACING_2">5/5 mil</option>
                                                                    <option value="MIN_TRACK_SPACING_3">6/6 mil</option>
                                                                    <option value="MIN_TRACK_SPACING_4">8/8 mil</option>
                                                                    <option value="MIN_TRACK_SPACING_5">10/10 mil</option>
                                                                    <option value="MIN_TRACK_SPACING_6">from 15/15 mil</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputHoleSize" className="col-sm-7 col-form-label">Min Hole size:</label>
                                                            <div className="col-sm-5">
                                                                <select className="form-select" aria-label="holeSize select" id="holeSizeSelect"
                                                                    name="minHoleSize" value={selectItem.cartSelect.minHoleSize} disabled>
                                                                    <option value="MIN_HOLESIZE_0">0.15 mm</option>
                                                                    <option value="MIN_HOLESIZE_1">0.20 mm</option>
                                                                    <option value="MIN_HOLESIZE_2">0.25 mm</option>
                                                                    <option value="MIN_HOLESIZE_3">0.30 mm</option>
                                                                    <option value="MIN_HOLESIZE_4">0.40 mm</option>
                                                                    <option value="MIN_HOLESIZE_5">from 0.50 mm</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputSolderMaskColor" className="col-sm-5 col-form-label">Mask color:</label>
                                                            <div className="col-sm-5">
                                                                <select className="form-select" aria-label="solderMaskColor select" id="solderMaskColorSelect"
                                                                    name="soldermaskColor" value={selectItem.cartSelect.soldermaskColor} disabled >
                                                                    <option value="SOLDERMASK_0">Green</option>
                                                                    <option value="SOLDERMASK_1">Red</option>
                                                                    <option value="SOLDERMASK_2">Yellow</option>
                                                                    <option value="SOLDERMASK_3">Blue</option>
                                                                    <option value="SOLDERMASK_4">Purple</option>
                                                                    <option value="SOLDERMASK_5">White</option>
                                                                    <option value="SOLDERMASK_6">Black</option>
                                                                    <option value="SOLDERMASK_7">None</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="silkScreenColor" className="col-sm-5 col-form-label">Silk color:</label>
                                                            <div className="col-sm-5">
                                                                <select className="form-select" aria-label="silkScreenColor select" id="silkScreenColorSelect"
                                                                    name="silkscreenColor" value={selectItem.cartSelect.silkscreenColor} disabled >
                                                                    <option value="SILKSCREEN_0">White</option>
                                                                    <option value="SILKSCREEN_1">Black</option>
                                                                    <option value="SILKSCREEN_2">Yellow</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputMaterial" className="col-form-label col-sm-6 pt-0">Goldfinger:</label>
                                                            <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3 }}>
                                                                {Object.keys(selectItem.cartSelect).length !== 0 ?
                                                                    <input className="form-check-input" type="checkbox" id="goldFinger"
                                                                        name="goldFinger" checked={selectItem.cartSelect.goldFinger} disabled />
                                                                    : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputMaterial" className="col-form-label col-sm-8 pt-0">CastellatedHoles:</label>
                                                            <div className="form-check form-switch" style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: 3 }}>
                                                                {Object.keys(selectItem.cartSelect).length !== 0 ?
                                                                    <input className="form-check-input" type="checkbox" id="castellateHoles"
                                                                        name="castellatedHoles" checked={selectItem.cartSelect.castellatedHoles} disabled />
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <fieldset className="row mb-3">
                                                        <legend className="col-form-label col-sm-6 pt-0">SurfaceFinish:</legend>
                                                        <div className="col-lg-6">
                                                            {handleSwitchSurface(selectItem.cartSelect.surfaceFinish)}
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="row mb-3">
                                                        <legend htmlFor="inputCooperWeight" className="col-form-label col-sm-6 pt-0">copperWeight:</legend>
                                                        <div className="col-sm-6" >
                                                            <label className="form-check-label" htmlFor="cooperRadios3">
                                                                {selectItem.cartSelect.copperWeight === 'COPPER_0' ?
                                                                    <p>1 Oz</p>
                                                                    :
                                                                    <p>2 Oz</p>
                                                                }
                                                            </label>
                                                        </div>

                                                    </fieldset>
                                                    <fieldset className="row mb-3">
                                                        <legend className="col-form-label col-sm-5 pt-0">Formato:</legend>
                                                        <div className="col-lg-6">
                                                            {handleSwitchFormat(selectItem.cartSelect.format)}
                                                        </div>
                                                    </fieldset>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputQty" className="col-form-label col-sm-5 pt-0">Cantidad:</label>
                                                            <div className="col-sm-6">
                                                                <label className="form-check-label" htmlFor="gridRadios1">
                                                                    {selectItem.cartSelect.qty}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 optionContainer">
                                                            <label htmlFor="inputComments" className="col-form-label col-sm-5 pt-0">Comentarios:</label>
                                                            <div className="col-md-7">
                                                                <textarea type="text" className="form-control" id="inputOrderComment" placeholder='Comments'
                                                                    name="remark" value={selectItem.cartSelect.remark} disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row mb-3">
                                                    <div className="col-sm-10">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="gridCheck1" disabled />
                                                            <label className="form-check-label" htmlFor="gridCheck1">
                                                                Guardar Diseño en mi perfil
                                </label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="card">
                                                    <div className="col-lg-12">
                                                        <div className='manImgCont'>
                                                            <img src="images/PROTOTIPARK_NOTION.png" className="card-img-top manImg" alt="..." />
                                                        </div>
                                                        <div className="card-body">
                                                            <h5 className="card-title">{selectItem.cartSelect.providerCompany}</h5>
                                                            <p className="card-text">Tiempo de producción: <br />{selectItem.cartSelect.productionTime}{" "}horas</p>
                                                            <p className="card-text">Precio: {"$ "}{Intl.NumberFormat("de-DE").format(selectItem.cartSelect.totalPrice)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </>
                            }
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => handleCloseModal(false)}>Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalCartDetails;



import React from 'react'
import '../../css/principal.css'
import './css/Carousel.css'


function Carousel() {
    return (
        <div>
            <div className="principalContainer">
                <div className="mainWrapper">
                    <div className='carouselSection'>
                        <h2>Confían en nosotros</h2>
                        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" style={{color: "black"}}>
                            <ol className="carousel-indicators" >
                                <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" style={{backgroundColor: "black"}}></li>
                                <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" style={{backgroundColor: "black"}}></li>
                                <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" style={{backgroundColor: "black"}}></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="images/INSTAGRAM_LOGO.png" className="d-block" alt="img2" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Seitechs</h5>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src="images/PROTIPARK_IG_LOGO.png" className="d-block" alt="img3" />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>Protipark</h5>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-bs-slide="prev" style={{color: "black"}}>
                                <span className="carousel-control-prev-icon" aria-hidden="true" style={{backgroundColor: "black"}}></span>
                                <span className="visually-hidden">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-bs-slide="next" style={{color: "black"}}>
                                <span className="carousel-control-next-icon" aria-hidden="true" style={{backgroundColor: "black"}}></span>
                                <span className="visually-hidden">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Carousel

import React from 'react';
import ManufacturerDashboard from './Components/ManufacturerDashboard';

function ManufacturerAccount() {

    return (
        <div className=''>
            <ManufacturerDashboard/>
        </div>
    )
}

export default ManufacturerAccount

import React, { useState, createContext } from 'react';

export const CartContext = createContext();

export const CartProvider = props => {
    const [cartsContext, setCartsContext] = useState([]);

    return (
        <CartContext.Provider value={[cartsContext, setCartsContext]}>
            {props.children}
        </CartContext.Provider>
    );
}

import React from 'react'
import '../css/information.css'
import { ImEye } from 'react-icons/im'

function Historial() {
    return (
        <>
            <div className="col-md-12">
                <div className="card" style={{ padding: 25 }}>
                    <div className="row">
                        <div className="ordersTitle">
                            <h3>Tu Historial</h3>
                        </div>
                        <table className="table mytable" >
                            <thead className=''>
                                <tr className=''>

                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                <tr>
                                    <th scope="row" className=''>1</th>
                                    <td>
                                        <label htmlFor=""><b>Orden #:</b></label>
                                                    0002
                                    </td>
                                    <td>
                                        <label htmlFor=""><b>Fecha:</b></label>
                                                    01/02/1994
                                    </td>
                                    <td className='qtyColumn'>
                                        <label htmlFor=""><b>Total:</b></label>
                                                    $1'000.000
                                    </td>
                                    <td>
                                    <label style={{marginRight:20}} htmlFor=""><b>Acciones:</b></label>
                                            <ImEye size={30} />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className=''>2</th>
                                    <td>
                                        <label htmlFor=""><b>Orden #:</b></label>
                                                    0002
                                    </td>
                                    <td>
                                        <label htmlFor=""><b>Fecha:</b></label>
                                                    01/02/1994
                                    </td>
                                    <td className='qtyColumn'>
                                        <label htmlFor=""><b>Total:</b></label>
                                                    $1'000.000
                                    </td>
                                    <td>
                                        <label style={{marginRight:20}} htmlFor=""><b>Acciones:</b></label>
                                            <ImEye size={30} />
                                       
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Historial

import React, { useState, useEffect, useContext } from 'react';
import { faCcVisa, faCcMastercard, faCcDinersClub, faCcPaypal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../../css/principal.css';
import '../css/information.css';
import Toast from '../../../components/toast/Toast';
import {
    fetchUpdateDesignerProfileApi, fetchUpdatePassDesignerApi, getDesignerDataApi,
    fetchCreateBillingDesignerApi, removeBillingDesignerApi, fetchUpdateBillingDesignerApi,
    fetchCreateShippingDesignerApi, removeShippingDesignerApi, fetchUpdateShippingDesignerApi
} from '../services';
import { faEye, faEyeSlash, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { DesignerContext } from '../../../context/DesignerContext';

function Information() {
    const [designerContext, setdesignerContext] = useContext(DesignerContext);

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    //Profile info states
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [personalInfo, setPersonalInfo] = useState({
        profileImage: '',
        username: '',
        name: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        country: '',
        city: '',
        password: ''
    });

    const [isLoadingChangePass, setIsLoadingChangePass] = useState(false);
    const [isShowPassChangePass, setIsShowPassChangePass] = useState(false);

    const [formValuesPass, setFormValuesPass] = useState({
        pass: '',
        confirmPass: '',
        oldPass: ''
    });
    //Billing info states
    const [isLoadingBill, setIsLoadingBill] = useState(false);
    const [isEditBill, setIsEditBill] = useState(false);
    const [billingInfo, setBillingInfo] = useState({
        billingName: '',
        billingLastName: '',
        billingCompany: '',
        billingAddress1: '',
        billingAddress2: '',
        billingCountry: '',
        billingState: '',
        billingCity: '',
    });

    const [billingsArray, setBillingsArray] = useState([]);
    const [isLoadingDeleteBill, setIsLoadingDeleteBill] = useState(false);
    const [isEditListBill, setIsEditListBill] = useState({ type: false, index: 0 });
    //Shipping info states
    const [isLoadingShipping, setIsLoadingShipping] = useState(false);
    const [isEditShipping, setIsEditShipping] = useState(false);
    const [ShippingInfo, setShippingInfo] = useState({
        shippingName: '',
        shippingLastName: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingZipCode: '',
        shippingCountry: '',
        shippingState: '',
        shippingCity: '',
        shippingReceiver: '',
        shippingNumber: '',
        shippingDefaultFlag: false
    });
    const [shippingsArray, setShippingsArray] = useState([]);
    const [isLoadingDeleteShipping, setIsLoadingDeleteShipping] = useState(false);
    const [isEditListShipping, setIsEditListShipping] = useState({ type: false, index: 0 });

    async function bringDataDesigner() {
        const response = await getDesignerDataApi();
        if (typeof response !== 'undefined') {
            if (Object.keys(response.data.designerData).length !== 0) {
                let designerData = response.data.designerData;
                setdesignerContext(designerData);
                setPersonalInfo({
                    ...personalInfo,
                    profileImage: designerData.profileImage,
                    username: designerData.username,
                    name: designerData.name,
                    lastName: designerData.lastName,
                    email: designerData.email,
                    phoneNumber: designerData.phoneNumber,
                    city: designerData.city,
                    country: designerData.country,
                });
                setBillingsArray(designerData.billingInformation);
                setShippingsArray(designerData.shippingInformation);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const handleFile = (e) => {
        let files = e.target.files;
        let base64Data;
        if (files.length !== 0) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                base64Data = e.target.result;
                setPersonalInfo({ ...personalInfo, profileImage: base64Data });
            }
        }
    }

    async function handleSubmitProfile(e) {
        e.preventDefault();
        setIsLoading(true);
        const response = await fetchUpdateDesignerProfileApi(personalInfo);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                setIsEdit(!isEdit);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }

    }

    async function handleResetPass(e) {
        e.preventDefault();
        setIsLoadingChangePass(true);
        if (formValuesPass.pass === formValuesPass.confirmPass) {
            const response = await fetchUpdatePassDesignerApi(formValuesPass);
            if (typeof response !== 'undefined') {
                if (response.data.done) {
                    setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                    setIsLoadingChangePass(false);
                    setIsModalOpen(false);
                }
                if (response.data.info) {
                    setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                    setIsLoadingChangePass(false);
                }
                if (response.data.error) {
                    setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                    setIsLoadingChangePass(false);
                }
            } else {
                console.log("problema en la peticion");
                setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
                setIsLoadingChangePass(false);
                return
            }
        } else {
            setConfigToast({ ...configToast, typeToast: "warning", textToast: "Las contraseñas no coinciden" });
            setIsLoadingChangePass(false);
        }
    }

    const handleSubmitBilling = (e) => {
        e.preventDefault();
        if (isEditListBill.type) {
            handleSubmitUpdateBilling();
        } else {
            handleSubmitCreateBilling();
        }
    }

    async function handleSubmitUpdateBilling() {
        setIsLoadingBill(true);
        let billingData = { billing: billingInfo, indexBill: isEditListBill.index };
        const response = await fetchUpdateBillingDesignerApi(billingData);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                billingsArray[billingData.indexBill] = billingInfo;
                setBillingsArray([...billingsArray]);
                handleCancelUpdateBillButton();
                setIsLoadingBill(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingBill(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingBill(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingBill(false);
            return
        }
    }

    async function handleSubmitCreateBilling() {
        setIsLoadingBill(true);
        let billingData = { billing: billingInfo };
        const response = await fetchCreateBillingDesignerApi(billingData);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                billingsArray.push(billingInfo);
                setIsLoadingBill(false);
                setIsEditBill(!isEditBill);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingBill(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingBill(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingBill(false);
            return
        }
    }

    const handleUpdateBillAddress = (bill, i) => {
        setBillingInfo({
            billingName: bill.billingName,
            billingLastName: bill.billingLastName,
            billingCompany: bill.billingCompany,
            billingAddress1: bill.billingAddress1,
            billingAddress2: bill.billingAddress2,
            billingCountry: bill.billingCountry,
            billingState: bill.billingState,
            billingCity: bill.billingCity,
        });
        setIsEditBill(true);
        setIsEditListBill({ ...isEditListBill, type: true, index: i });
    }

    const handleCancelUpdateBillButton = () => {
        setIsEditBill(!isEditBill);
        setIsEditListBill({ ...isEditListBill, type: false, index: 0 });
    }

    async function handleRemoveBillAddress(index) {
        setIsLoadingDeleteBill(true);
        let indexBill = { indexBill: index };
        const response = await removeBillingDesignerApi(indexBill);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                billingsArray.splice(indexBill.indexBill, 1);
                setBillingsArray([...billingsArray]);
                setIsLoadingDeleteBill(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingDeleteBill(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingDeleteBill(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingDeleteBill(false);
            return
        }

    }

    const handleCancelUpdateShippingButton = () => {
        setIsEditShipping(!isEditShipping);
        setIsEditListShipping({ ...isEditListShipping, type: false, index: 0 });
    }

    const handleSubmitShipping = (e) => {
        e.preventDefault();
        if (isEditListShipping.type) {
            handleSubmitUpdateShipping();
        } else {
            handleSubmitCreateShipping();
        }
    }

    async function handleSubmitCreateShipping() {
        setIsLoadingShipping(true);
        let shippingData = { shipping: ShippingInfo };
        const response = await fetchCreateShippingDesignerApi(shippingData);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                shippingsArray.push(ShippingInfo);
                setIsLoadingShipping(false);
                handleCancelUpdateShippingButton();
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingShipping(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingShipping(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingShipping(false);
            return
        }
    }

    async function handleRemoveShippingAddress(index) {
        setIsLoadingDeleteShipping(true);
        let indexShippingObj = { indexShipping: index };
        const response = await removeShippingDesignerApi(indexShippingObj);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                shippingsArray.splice(indexShippingObj.indexShipping, 1);
                setShippingsArray([...shippingsArray]);
                setIsLoadingDeleteShipping(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingDeleteShipping(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingDeleteShipping(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingDeleteShipping(false);
            return
        }

    }

    const handleUpdateShippingAddress = (shipping, i) => {
        setShippingInfo({
            shippingName: shipping.shippingName,
            shippingLastName: shipping.shippingLastName,
            shippingAddress1: shipping.shippingAddress1,
            shippingAddress2: shipping.shippingAddress2,
            shippingZipCode: shipping.shippingZipCode,
            shippingCountry: shipping.shippingCountry,
            shippingState: shipping.shippingState,
            shippingCity: shipping.shippingCity,
            shippingReceiver: shipping.shippingReceiver,
            shippingNumber: shipping.shippingNumber,
            shippingDefaultFlag: shipping.shippingDefaultFlag
        });
        setIsEditShipping(true);
        setIsEditListShipping({ ...isEditListShipping, type: true, index: i });
    }

    async function handleSubmitUpdateShipping() {
        setIsLoadingShipping(true);
        let shippingData = { shipping: ShippingInfo, indexShipping: isEditListShipping.index };
        const response = await fetchUpdateShippingDesignerApi(shippingData);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                shippingsArray[shippingData.indexShipping] = ShippingInfo;
                setShippingsArray([...shippingsArray]);
                handleCancelUpdateShippingButton();
                setIsLoadingShipping(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingShipping(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingShipping(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingShipping(false);
            return
        }
    }

    useEffect(() => {
        bringDataDesigner();

    }, []);
    return (
        <>
            <div className="accordion" id="accordionExample">
                {componentToast()}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h4><b>Información personal</b></h4>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='informationContainer'>
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <form className="row g-3" onSubmit={handleSubmitProfile}>
                                            <label htmlFor="inputUsername" className="form-label"><b>Foto de Perfil</b></label>
                                            <div className="col-md-12">
                                                {personalInfo.profileImage !== '' ?
                                                    <img src={personalInfo.profileImage} alt="uploadImage" style={{ width: 110, marginBottom: 15 }} />
                                                    :
                                                    <img src="https://bulma.io/images/placeholders/128x128.png" alt="Empty" style={{ width: 110, marginBottom: 15 }} />
                                                } <br />

                                                <input className="form-control" type="file" id="formFile" onChange={handleFile} disabled={!isEdit} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="inputUsername" className="form-label"><b>Username *</b></label>
                                                <input type="text" className="form-control" id="inputUsername" name="username"
                                                    onChange={(e) => { setPersonalInfo({ ...personalInfo, username: e.target.value }) }}
                                                    value={personalInfo.username} disabled={!isEdit} required />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputName" className="form-label"><b>Nombre *</b></label>
                                                <input type="text" className="form-control" id="inputName" name="name"
                                                    onChange={(e) => { setPersonalInfo({ ...personalInfo, name: e.target.value }) }}
                                                    value={personalInfo.name} disabled={!isEdit} required />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputLastName" className="form-label"><b>Apellidos</b></label>
                                                <input type="text" className="form-control" id="inputLastName" name="lastName"
                                                    onChange={(e) => { setPersonalInfo({ ...personalInfo, lastName: e.target.value }) }}
                                                    value={personalInfo.lastName} disabled={!isEdit} />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputEmail" className="form-label"><b>Email *</b></label>
                                                <input type="email" className="form-control" id="inputEmail" placeholder="Example@example.com" name="email"
                                                    onChange={(e) => { setPersonalInfo({ ...personalInfo, email: e.target.value }) }}
                                                    value={personalInfo.email} disabled={!isEdit} required />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputTel" className="form-label"><b>Teléfono</b></label>
                                                <input type="text" className="form-control" id="inputTel" placeholder="555-3333" name="phoneNumber"
                                                    onChange={(e) => { setPersonalInfo({ ...personalInfo, phoneNumber: e.target.value }) }}
                                                    value={personalInfo.phoneNumber} disabled={!isEdit} />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputCity" className="form-label"><b>Ciudad</b></label>
                                                <input type="text" className="form-control" id="inputCity" placeholder="Cali" name="city"
                                                    onChange={(e) => { setPersonalInfo({ ...personalInfo, city: e.target.value }) }}
                                                    value={personalInfo.city} disabled={!isEdit} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="inputCountry" className="form-label"><b>País</b></label>
                                                <input type="text" className="form-control" id="inputCountry" placeholder="Colombia" name="country"
                                                    onChange={(e) => { setPersonalInfo({ ...personalInfo, country: e.target.value }) }}
                                                    value={personalInfo.country} disabled={!isEdit} />
                                            </div>
                                            {isEdit ?
                                                <>
                                                    <label htmlFor="inputPassword" className="form-label"><b>Contraseña Actual *</b></label>
                                                    {isShowPass ?
                                                        <div className="input-group mb-3">
                                                            <input type="text" className="form-control" id="inputPasswordHide" placeholder="Contraseña Actual" name="password"
                                                                onChange={(e) => { setPersonalInfo({ ...personalInfo, password: e.target.value }) }}
                                                                value={personalInfo.password} required />
                                                            <span className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEyeSlash} /></span>
                                                        </div>
                                                        :
                                                        <div className="input-group mb-3">
                                                            <input type="password" className="form-control" id="inputPasswordShow" placeholder="Contraseña Actual" name="password"
                                                                onChange={(e) => { setPersonalInfo({ ...personalInfo, password: e.target.value }) }}
                                                                value={personalInfo.password} required />
                                                            <span className="input-group-text" id="basic-addon2" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEye} /></span>
                                                        </div>
                                                    }
                                                </>
                                                : null}
                                            {!isEdit ?
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-primary" onClick={() => { setIsEdit(!isEdit) }}>Editar</button>
                                                </div>
                                                :
                                                <>
                                                    <div className="col-md-2">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setIsEdit(!isEdit) }}>Cancelar</button>
                                                    </div>
                                                    {isLoading ?
                                                        <div className="col-md-2">
                                                            <button className="btn btn-primary" type="submit" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="col-md-2">
                                                            <button type="submit" className="btn btn-success" >Guardar</button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            <div className="col-6">
                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#changePassModal" onClick={() => setIsModalOpen(true)}>Cambiar contraseña</button>
                                            </div>
                                        </form>


                                        <div className={`modal fade changePassModal ${isModalOpen ? 'show' : ''}`}
                                            style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                                            id="changePassModal" tabIndex="1" aria-labelledby="changePassModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h2 className="modal-title" id="changePassModalLabel">Actualización de contraseña</h2>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <p>Por favor escribe tu contraseña actual.</p>
                                                        <form onSubmit={handleResetPass} className="row g-3">
                                                            <div className="col-md-6">
                                                                <label htmlFor="inputChangePassOldConfirm" className="form-label"><b>Contraseña actual *</b></label>
                                                                <input type={isShowPassChangePass ? "text" : "password"} name="password2" className="form-control" id="inputChangePassOldConfirm" placeholder="Confirmar contraseña"
                                                                    onChange={(e) => { setFormValuesPass({ ...formValuesPass, oldPass: e.target.value }) }} value={formValuesPass.oldPass} required />
                                                            </div><br />
                                                            <p>Ahora escribe tus nuevas contraseñas.</p>
                                                            <div className="col-md-6">
                                                                <label htmlFor="inputChangePass" className="form-label"><b>Nueva contraseña *</b></label>
                                                                <input type={isShowPassChangePass ? "text" : "password"} name="password" className="form-control" id="inputChangePass" placeholder="Contraseña"
                                                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="La contraseña debe tener 8 caracteres, incluida 1 letra mayúscula, 1 letra minúscula y 1 número"
                                                                    onChange={(e) => { setFormValuesPass({ ...formValuesPass, pass: e.target.value }) }} value={formValuesPass.pass} required />
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label htmlFor="inputChangePassConfirm" className="form-label"><b>Confirmar contraseña *</b></label>
                                                                <input type={isShowPassChangePass ? "text" : "password"} name="password2" className="form-control" id="inputChangePassConfirm" placeholder="Confirmar contraseña"
                                                                    onChange={(e) => { setFormValuesPass({ ...formValuesPass, confirmPass: e.target.value }) }} value={formValuesPass.confirmPass} required />
                                                            </div>
                                                            <br />

                                                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                {isShowPassChangePass ?
                                                                    <div className="input-group mb-3">
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                                                        <a className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPassChangePass(!isShowPassChangePass) }}><FontAwesomeIcon icon={faEyeSlash} /></a>
                                                                    </div>
                                                                    :
                                                                    <div className="input-group mb-3">
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                                                        <a className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPassChangePass(!isShowPassChangePass) }}><FontAwesomeIcon icon={faEye} /></a>
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="col-4">
                                                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                                                            </div>
                                                            {isLoadingChangePass ?
                                                                <div className="col-4">
                                                                    <button className="btn btn-primary" type="submit" disabled>
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="col-4">
                                                                    <button type="submit" className="btn btn-success">Actualizar</button>
                                                                </div>
                                                            }
                                                        </form><br />
                                                    </div>
                                                    <div className="modal-footer">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <h4><b>Datos de facturación</b></h4>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='informationContainer'>
                                <h5><b>Añadir dirección de facturación</b></h5>
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <form className="row g-3" onSubmit={handleSubmitBilling}>
                                            <div className="col-md-6">
                                                <label htmlFor="inputNameBill" className="form-label"><b>Nombre *</b></label>
                                                <input type="text" className="form-control" id="inputNameBill" onChange={(e) => { setBillingInfo({ ...billingInfo, billingName: e.target.value }) }}
                                                    value={billingInfo.billingName} disabled={!isEditBill} required />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="inputLastNameBill" className="form-label"><b>Apellidos *</b></label>
                                                <input type="text" className="form-control" id="inputLastNameBill" onChange={(e) => { setBillingInfo({ ...billingInfo, billingLastName: e.target.value }) }}
                                                    value={billingInfo.billingLastName} disabled={!isEditBill} required />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="inputCompanyBill" className="form-label"><b>Compañía *</b></label>
                                                <input type="text" className="form-control" id="inputCompanyBill" onChange={(e) => { setBillingInfo({ ...billingInfo, billingCompany: e.target.value }) }}
                                                    value={billingInfo.billingCompany} disabled={!isEditBill} required />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="inputAddress1Bill" className="form-label"><b>Dirección 1 *</b></label>
                                                <input type="text" className="form-control" id="inputAddress1Bill" placeholder="Calle, carrera, o avenida" onChange={(e) => { setBillingInfo({ ...billingInfo, billingAddress1: e.target.value }) }}
                                                    value={billingInfo.billingAddress1} disabled={!isEditBill} required />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="inputAddress2Bill" className="form-label"><b>Dirección 2 *</b></label>
                                                <input type="text" className="form-control" id="inputAddress2Bill" placeholder="Apartamento, piso, o conjunto" onChange={(e) => { setBillingInfo({ ...billingInfo, billingAddress2: e.target.value }) }}
                                                    value={billingInfo.billingAddress2} disabled={!isEditBill} required />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputCityBill" className="form-label"><b>Ciudad *</b></label>
                                                <input type="text" className="form-control" id="inputCityBill" onChange={(e) => { setBillingInfo({ ...billingInfo, billingCity: e.target.value }) }}
                                                    value={billingInfo.billingCity} disabled={!isEditBill} required />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputStateBill" className="form-label"><b>Estado *</b></label>
                                                <input type="text" className="form-control" id="inputStateBill" onChange={(e) => { setBillingInfo({ ...billingInfo, billingState: e.target.value }) }}
                                                    value={billingInfo.billingState} disabled={!isEditBill} required />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputCountryBill" className="form-label"><b>Pais *</b></label>
                                                <input type="text" className="form-control" id="inputCountryBillBill" onChange={(e) => { setBillingInfo({ ...billingInfo, billingCountry: e.target.value }) }}
                                                    value={billingInfo.billingCountry} disabled={!isEditBill} required />
                                            </div>
                                            {!isEditBill ?
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-primary" onClick={handleCancelUpdateBillButton}>Añadir</button>
                                                </div>
                                                :
                                                <>
                                                    <div className="col-md-2">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setIsEditBill(!isEditBill) }}>Cancelar</button>
                                                    </div>
                                                    {isLoadingBill ?
                                                        <div className="col-md-2">
                                                            <button className="btn btn-primary" type="submit" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            </button>
                                                        </div>
                                                        :
                                                        isEditListBill.type ?
                                                            <div className="col-md-2">
                                                                <button type="submit" className="btn btn-secondary" >Actualizar</button>
                                                            </div>
                                                            :
                                                            <div className="col-md-2">
                                                                <button type="submit" className="btn btn-success" >Guardar</button>
                                                            </div>
                                                    }
                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <h4><b>Direcciones de facturación registradas</b></h4>
                            <div className="col-lg-12 informationContainer">
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <div className="list-group" id="list-tab" role="tablist" style={billingsArray.length > 1 ? { overflowY: 'scroll', height: 250 } : null}>
                                            {billingsArray.map((bill, i) => {
                                                return (
                                                    <div key={i} className="list-group-item" id="list-home-list" role="tab" aria-controls="home">

                                                        <div className="row mb-3">
                                                            <div className="col-sm-2">
                                                                <h6><strong>Datos Básicos</strong></h6>
                                                                <p>{i + 1} ).{" "}
                                                                    {bill.billingName} {" "}
                                                                    {bill.billingLastName}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6><strong>Compañía</strong></h6>
                                                                <p>{bill.billingCompany}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <h6><strong>Dirección</strong></h6>
                                                                <p>
                                                                    {bill.billingAddress1}, <br />
                                                                    {bill.billingAddress2}
                                                                </p>

                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6><strong>Ubicación</strong></h6>
                                                                <p>
                                                                    {bill.billingCity}, <br />
                                                                    {bill.billingCountry}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-1">
                                                                <button type="button" className="btn btn-info"
                                                                    onClick={() => { handleUpdateBillAddress(bill, i) }}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </button>
                                                            </div>
                                                            {isLoadingDeleteBill ?
                                                                <div className="col-md-1">
                                                                    <button className="btn btn-danger" type="button" disabled>
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="col-sm-1">
                                                                    <button type="button" className="btn btn-danger"
                                                                        onClick={() => { if (window.confirm('¿Estás seguro de que deseas eliminar este artículo?')) handleRemoveBillAddress(i) }}>
                                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                                    </button>
                                                                </div>
                                                            }


                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h4><b>Direcciones de entrega</b></h4>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className='informationContainer'>
                                <h5><b>Añadir dirección de entrega</b></h5>
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <form className="row g-3" onSubmit={handleSubmitShipping}>
                                            <div className="col-6">
                                                <label htmlFor="inputShippingName" className="form-label"><b>Nombre *</b></label>
                                                <input type="text" className="form-control" id="inputShippingName"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingName: e.target.value }) }}
                                                    value={ShippingInfo.shippingName} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="inputShippingLastname" className="form-label"><b>Apellido *</b></label>
                                                <input type="text" className="form-control" id="inputShippingLastname"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingLastName: e.target.value }) }}
                                                    value={ShippingInfo.shippingLastName} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="inputShippingAddress1" className="form-label"><b>Dirección *</b></label>
                                                <input type="text" className="form-control" id="inputShippingAddress1" placeholder="Direccón completa"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingAddress1: e.target.value }) }}
                                                    value={ShippingInfo.shippingAddress1} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="inputShippingAddress2" className="form-label"><b>Dirección 2 *</b></label>
                                                <input type="text" className="form-control" id="inputShippingAddress2" placeholder="Indicaciones, Ej color de casa, apartamamento, etc"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingAddress2: e.target.value }) }}
                                                    value={ShippingInfo.shippingAddress2} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="inputReceiverName" className="form-label"><b>Quién recibe *</b></label>
                                                <input type="text" className="form-control" id="inputReceiverName" placeholder="Nombre de quién recibe"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingReceiver: e.target.value }) }}
                                                    value={ShippingInfo.shippingReceiver} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="inputShippingPhone" className="form-label"><b>Teléfono *</b></label>
                                                <input type="text" className="form-control" id="inputShippingPhone" placeholder="Teléfono de quién recibe"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingNumber: e.target.value }) }}
                                                    value={ShippingInfo.shippingNumber} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="inputShippingCity" className="form-label"><b>Ciudad *</b></label>
                                                <input type="text" className="form-control" id="inputShippingCity"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingCity: e.target.value }) }}
                                                    value={ShippingInfo.shippingCity} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="inputShippingState" className="form-label"><b>Estado *</b></label>
                                                <input type="text" className="form-control" id="inputShippingState"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingState: e.target.value }) }}
                                                    value={ShippingInfo.shippingState} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="inputShippingCountry" className="form-label"><b>Pais *</b></label>
                                                <input type="text" className="form-control" id="inputShippingCountry"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingCountry: e.target.value }) }}
                                                    value={ShippingInfo.shippingCountry} disabled={!isEditShipping} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="inputZipCode" className="form-label"><b>ZIP Code</b></label>
                                                <input type="text" className="form-control" id="inputZipCode" placeholder="código postal"
                                                    onChange={(e) => { setShippingInfo({ ...ShippingInfo, shippingZipCode: e.target.value }) }}
                                                    value={ShippingInfo.shippingZipCode} disabled={!isEditShipping} required />
                                            </div>

                                            {!isEditShipping ?
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-primary" onClick={handleCancelUpdateShippingButton}>Añadir</button>
                                                </div>
                                                :
                                                <>
                                                    <div className="col-md-2">
                                                        <button type="button" className="btn btn-danger" onClick={() => { setIsEditShipping(!isEditShipping) }}>Cancelar</button>
                                                    </div>
                                                    {isLoadingShipping ?
                                                        <div className="col-md-2">
                                                            <button className="btn btn-primary" type="submit" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            </button>
                                                        </div>
                                                        :
                                                        isEditListShipping.type ?
                                                            <div className="col-md-2">
                                                                <button type="submit" className="btn btn-secondary" >Actualizar</button>
                                                            </div>
                                                            :
                                                            <div className="col-md-2">
                                                                <button type="submit" className="btn btn-success" >Guardar</button>
                                                            </div>
                                                    }
                                                </>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                            <h4><b>Direcciones de entrega registradas</b></h4>
                            <div className="col-lg-12 informationContainer">
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <div className="list-group" id="list-tab" role="tablist" style={shippingsArray.length > 1 ? { overflowY: 'scroll', height: 250 } : null}>
                                            {shippingsArray.map((shipping, i) => {
                                                return (
                                                    <div key={i} className="list-group-item" id="list-home-list" role="tab" aria-controls="home">

                                                        <div className="row mb-3">
                                                            <div className="col-sm-2">
                                                                <h6><strong>Datos Básicos</strong></h6>
                                                                <p>{i + 1} ).{" "}
                                                                    {shipping.shippingName} {" "}
                                                                    {shipping.shippingLastName}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6><strong>Quien Recibe</strong></h6>
                                                                <p>{shipping.shippingReceiver} {" "} <br />
                                                                    Teléfono: {shipping.shippingNumber} {" "}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6><strong>Dirección</strong></h6>
                                                                <p>
                                                                    {shipping.shippingAddress1}, <br />
                                                                    {shipping.shippingAddress2}
                                                                </p>

                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6><strong>Ubicación</strong></h6>
                                                                <p>
                                                                    {shipping.shippingCity}, <br />
                                                                    {shipping.shippingCountry}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6><strong>ZIP code</strong></h6>
                                                                <p>
                                                                    {shipping.shippingZipCode}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-1">
                                                                <button type="button" className="btn btn-info"
                                                                    onClick={() => { handleUpdateShippingAddress(shipping, i) }}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </button>
                                                            </div>
                                                            {isLoadingDeleteShipping ?
                                                                <div className="col-md-1">
                                                                    <button className="btn btn-danger" type="button" disabled>
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="col-sm-1">
                                                                    <button type="button" className="btn btn-danger"
                                                                        onClick={() => { if (window.confirm('¿Estás seguro de que deseas eliminar este artículo?')) handleRemoveShippingAddress(i) }}>
                                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                                    </button>
                                                                </div>
                                                            }


                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <h4><b>Tarjetas de crédito</b></h4>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className="informationContainer">
                                <h5><b>Añadir tarjeta de crédito</b></h5>
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <form className="row g-3">
                                            <div className="col-md-12">
                                                <label htmlFor="inputNamePayment" className="form-label"><b>Nombre completo del titular</b></label>
                                                <input type="text" className="form-control" id="inputNamePayment" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="inputNumberPayment" className="form-label"><b>Numero en la tarjeta</b></label>
                                                <input type="text" className="form-control" id="inputNumberPayment" />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="inputCvvCode" className="form-label"><b>CVV</b></label>
                                                <input type="text" className="form-control" id="inputCvvCode" />
                                            </div>
                                            <div className='paymentIcons'>
                                                <FontAwesomeIcon icon={faCcVisa} />{" "}
                                                <FontAwesomeIcon icon={faCcMastercard} />{" "}
                                                <FontAwesomeIcon icon={faCcDinersClub} />{" "}
                                                <FontAwesomeIcon icon={faCcPaypal} />{" "}
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="inputDateExp" className="form-label"><b>Expiración</b></label>
                                                <input type="date" className="form-control" id="inputDateExp" />
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-primary">Añadir</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <h4><b>Tarjetas añadidas</b></h4>
                            <div className="informationContainer">
                                <div className="card">
                                    <div className='informationContainer-Section'>
                                        <h4>No hay tarjetas añadidas</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br />


            <h4><b>Eliminar cuenta</b></h4>
            <div className="informationContainer">
                <div className="card">
                    <div className='informationContainer-Section'>
                        <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteModal">
                            Eliminar cuenta
                        </button>
                        <div className="modal" style={{ backgroundColor: 'rgb(0,0,0,0.55)' }} id="deleteModal" tabIndex="1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">¿Desea Eliminar la cuenta?</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsModalOpen(false)}></button>
                                    </div>
                                    <div className="modal-body">
                                        <label htmlFor="">¿Por qué deseas eliminar la cuenta?</label>
                                        <textarea name="deleteReason" id="" cols="5" rows="5" className="form-control"></textarea>
                                        <label htmlFor="">contraseña</label>
                                        <input type="password" className="form-control" />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button>
                                        <button type="button" className="btn btn-danger">Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Information
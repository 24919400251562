import React, { useState } from 'react'
import Toast from '../../../components/toast/Toast';
import '../css/password.css'
import { resetPassDesignerApi } from '../services';

function Password() {
    const [isLoading, setIsLoading] = useState(false);
    const [formValueEmail, setFormValueEmail] = useState('');
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    async function handleSendResetPass(e) {
        e.preventDefault();
        setIsLoading(true);
        console.log(formValueEmail);
        const response = await resetPassDesignerApi(formValueEmail);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    return (
        <>
            <div className='principalContainer'>
            {componentToast()}
                <div className='mainWrapper'>
                    <div className='resetPasswordContainer'>
                        <form onSubmit={handleSendResetPass}>
                            <div className='resetPasswordContainer-title'>
                                <h4><b>Recuperar contraseña</b></h4>
                            </div>
                            <div className='resetPasswordContainer-p'>
                                <p>Si olvidaste tu contraseña por favor diligencia el correo electrónico asociado a tu cuenta, se te enviará un Email con los pasos para restablecer tu contraseña.</p>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="exampleFormControlInput1" className="form-label"><b>Email</b></label>
                                <input type="email" className="form-control" id="exampleFormControlInput1"
                                    onChange={(e) => { setFormValueEmail(e.target.value) }} value={formValueEmail} placeholder="name@example.com" required />
                            </div>
                            {isLoading ?
                                <div className='resetPasswordContainer-button'>
                                    <button className="btn btn-primary" type="submit" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                                :
                                <div className='resetPasswordContainer-button'>
                                    <button type="submit" className="btn btn-primary">Enviar</button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Password

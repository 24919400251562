import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
//Url Provider Profile Api
const getProviderDataUrl = '/providers/providerData';
const fetchUpdateProfileProviderUrl = '/providers/updateProfileProvider';
const fetchUpdatePassProviderUrl = '/providers/updatePassProvider';

//Url Provider Orders Api
const fetchGetProviderOrdersUrl = '/orders/getOrdersProvider';
const fecthUpdateStateCartOrderUrl = '/orders/updateStateCartOrder';
//Url Provider Add Comment Cart
const fetchAddComentCartProviderUrl = '/orders/addCommentCartProvider';

//Url Provider Add Quoter
const fetchAddQuoteProviderUrl = '/quoter/register';
const fetchGetQuoteProviderUrl = '/quoter/getProviderQuote';
const fetchUpdateQuoteProviderUrl = '/quoter/update';

let userToken = JSON.parse(localStorage.getItem('userToken'));

export async function getProviderDataApi() {
    try {
        const response = await axios({
            url: baseUrl + getProviderDataUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fetchUpdateProviderProfileApi(dataProfileProvider) {
    try {
        const response = await axios({
            url: baseUrl + fetchUpdateProfileProviderUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataProfileProvider,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fetchUpdatePassProviderApi(dataChangePassProvider) {
    try {
        const response = await axios({
            url: baseUrl + fetchUpdatePassProviderUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataChangePassProvider,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function getProviderOrdersApi() {
    try {
        const response = await axios({
            url: baseUrl + fetchGetProviderOrdersUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fetchAddComentCartProviderApi(dataCartUpdate) {
    try {
        const response = await axios({
            url: baseUrl + fetchAddComentCartProviderUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataCartUpdate,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fecthUpdateStateCartOrderProviderApi(dataCartUpdate) {
    try {
        const response = await axios({
            url: baseUrl + fecthUpdateStateCartOrderUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataCartUpdate,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fecthAddQuoteProviderApi(dataQuote) {
    try {
        const response = await axios({
            url: baseUrl + fetchAddQuoteProviderUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataQuote,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function getQuoterProviderDataApi() {
    try {
        const response = await axios({
            url: baseUrl + fetchGetQuoteProviderUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fecthUpdateQuoteProviderApi(dataQuote) {
    try {
        const response = await axios({
            url: baseUrl + fetchUpdateQuoteProviderUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataQuote,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}
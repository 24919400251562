import React, { useState, useEffect } from 'react';
import '../css/information.css';
import { ImEye, ImCancelCircle } from 'react-icons/im';
import { BiCommentDetail, BiRun } from 'react-icons/bi';
import { getDesignerOrdersApi } from '../../Orders/services';
import Toast from '../../../components/toast/Toast';
import ModalCartDetails from './ModalCartDetails';
import ModalCartComments from './ModalCartComments';
import ModalCartDelivery from './ModalCartDelivery';

function Orders() {
    const [ordersArray, setOrdersArray] = useState([]);

    const [cartDetailsArray, setCartDetailsArray] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenComments, setIsModalOpenComments] = useState(false);
    const [isModalOpenDelivery, setIsModalOpenDelivery] = useState(false);

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function bringDataDesigner() {
        const response = await getDesignerOrdersApi();
        if (typeof response !== 'undefined') {
            if (response.data.designerOrdersData) {
                let designerOrdersData = response.data.designerOrdersData;
                setOrdersArray(designerOrdersData);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    useEffect(() => {
        bringDataDesigner();
    }, []);

    const handleVisualizeDetails = (cartInfo) => {
        setCartDetailsArray(cartInfo);
        setIsModalOpen(true);
    }

    const handleVisualizeComments = (orderId, cartInfo) => {
        cartInfo.orderId = orderId;
        setCartDetailsArray(cartInfo);
        setIsModalOpenComments(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleCloseModalComments = () => {
        setIsModalOpenComments(false);
    }

    const handleVisualizeDelivery = (orderId, cartInfo, index) => {
        cartInfo.orderId = orderId;
        cartInfo.indexCart = index;
        setCartDetailsArray(cartInfo);
        setIsModalOpenDelivery(true);
    }

    const handleCloseModalDelivery = () => {
        setIsModalOpenDelivery(false);
    }

    const handleStateCart = (cartState) => {
        switch (cartState) {
            case "Pago aceptado":
                return (<button type="button" className="btn btn-outline-success" style={{ marginBottom: 25 }} disabled>{" "}{cartState}</button>)
            case "En revisión":
                return (<button type="button" className="btn btn-outline-warning" style={{ marginBottom: 25 }} disabled>{" "}{cartState}</button>)
            case "En proceso":
                return (<button type="button" className="btn btn-outline-secondary" style={{ marginBottom: 25 }} disabled>{" "}{cartState}</button>)
            case "En embalaje":
                return (<button type="button" className="btn btn-outline-info" style={{ marginBottom: 25 }} disabled>{" "}{cartState}</button>)
            case "Enviado":
                return (<button type="button" className="btn btn-outline-primary" style={{ marginBottom: 25 }} disabled>{" "}{cartState}</button>)
            case "Entregado":
                return (<button type="button" className="btn btn-primary" style={{ marginBottom: 25 }} disabled>{" "}{cartState}</button>)
            case "Finalizado":
                return (<button type="button" className="btn btn-success" style={{ marginBottom: 25 }} disabled>{" "}{cartState}</button>)

            default:
                return (<button type="button" className="btn btn-outline-success" style={{ marginBottom: 25 }} disabled>{" Pago aceptado"}</button>)
        }
    }

    return (
        <div>
            {componentToast()}
            <div className="col-md-12">
                <div className="row">
                    <div className="ordersTitle">
                        <h3>Tus órdenes</h3>
                    </div>
                    <div style={ordersArray.length > 2 ? { overflowY: 'auto', height: 410 } : null}>
                    {ordersArray.map((order, i) => {
                        return (
                            <div key={i} className="card" style={{ padding: 25 }}>
                                <div className="row mb-3">
                                    <div className="col-sm-2">
                                        <h6 style={{ paddingBottom: 20 }}><strong>Orden</strong></h6>
                                        <p>
                                            #{" "}{i + 1}
                                        </p><br />
                                        <p>
                                            Total: {"$ "}{Intl.NumberFormat("de-DE").format(order.totalPrice)}
                                        </p>
                                    </div>
                                    <div className="col-sm-2">
                                        <h6 style={{ paddingBottom: 20 }}><strong>Descripción</strong></h6>
                                        {order.cartInfo.map((cart, j) => {
                                            return (
                                                <div key={j}>
                                                    <div className="dropdown">
                                                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {j + 1}).{" "}{cart.description}
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><p className="dropdown-item">Proveedor: {cart.providerCompany}</p></li>
                                                            <li><p className="dropdown-item">Dimensiones: {cart.dimensions.dimension_x}{" "}x{" "}{cart.dimensions.dimension_y}</p></li>
                                                            <li><p className="dropdown-item">Tiempo de producción: {cart.productionTime} {" "}horas </p></li>
                                                        </ul>
                                                    </div><br />
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div className="col-sm-2">
                                        <h6 style={{ textAlign: 'center', paddingBottom: 20 }}><strong>Cantidad</strong></h6>
                                        {order.cartInfo.map((cart, c) => {
                                            return (<div key={c} style={{ textAlign: 'center' }}><p>
                                                {" "}{cart.qty}
                                            </p><br /></div>)
                                        })}
                                    </div>
                                    <div className="col-sm-2">
                                        <h6 style={{ paddingBottom: 20, textAlign: 'center' }}><strong>Precio</strong></h6>
                                        {order.cartInfo.map((cart, c) => {
                                            return (<div key={c} style={{ textAlign: 'center' }}><p>
                                                {"$ "}{Intl.NumberFormat("de-DE").format(cart.totalPrice)}
                                            </p><br /></div>)
                                        })}
                                    </div>
                                    <div className="col-sm-2">
                                        <h6 style={{ paddingBottom: 10, textAlign: 'center' }}><strong>Estado</strong></h6>
                                        {order.cartInfo.map((cart, c) => {
                                            if (cart.deliveryCode && cart.stateCart === 'Enviado' || cart.stateCart === 'Entregado') {
                                                return (<div key={c} style={{ textAlign: 'center' }}>
                                                    {handleStateCart(cart.stateCart)} {" "}
                                                    <abbr title="Guia de envío"><button key={c} style={{ marginBottom: 25, padding: 8 }} type="button" className="btn btn-success"
                                                        onClick={() => { handleVisualizeDelivery(order._id, order.cartInfo, c) }}>
                                                        <BiRun size={20} />
                                                    </button></abbr>
                                                </div>)
                                            } else {
                                                return (<div key={c} style={{ textAlign: 'center' }}>
                                                    {handleStateCart(cart.stateCart)}<br />
                                                </div>)
                                            }
                                        })}

                                    </div>
                                    <div className="col-sm-2">
                                        <h6 style={{ paddingBottom: 20, textAlign: "center" }}><strong>Acciones</strong></h6>
                                        <div className='orderOptions'>
                                            <button style={{ padding: 10 }} type="button" className="btn btn-info cartButon" onClick={() => { handleVisualizeDetails(order.cartInfo) }}>
                                                <ImEye size={20} />
                                            </button>
                                            <button style={{ padding: 10 }} type="button" className="btn btn-secondary" onClick={() => { handleVisualizeComments(order._id, order.cartInfo) }}>
                                                <BiCommentDetail size={20} />
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>
            <ModalCartDetails isModalOpen={isModalOpen} cartInfo={cartDetailsArray} handleCloseModal={handleCloseModal} />
            <ModalCartComments isModalOpen={isModalOpenComments} cartInfo={cartDetailsArray} handleCloseModalComments={handleCloseModalComments} />
            <ModalCartDelivery isModalOpen={isModalOpenDelivery} cartInfo={cartDetailsArray} handleCloseModalDelivery={handleCloseModalDelivery} />
        </div>
    )
}

export default Orders

import React, { useState, useEffect } from 'react';
import { fetchAddComentCartDesignerApi } from '../services';
import Toast from '../../../components/toast/Toast';

let userToken = JSON.parse(localStorage.getItem('userToken'));

const ModalCartComments = ({ isModalOpen, cartInfo, handleCloseModalComments }) => {
    const [selectItem, setSelectItem] = useState({
        index: 0,
        cartSelect: {},
        rows: 0
    });

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [currentDate, setCurrentDate] = useState("");

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }


    async function handleAddComentSelect(e) {
        e.preventDefault();
        setIsLoading(true);
        let comment = selectItem.cartSelect.remark;
        if (userToken.user === 3) {
            if (comment !== "") {
                comment = comment + "\n" + currentDate + " Admin: " + newComment;
            } else {
                comment = currentDate + " Admin: " + newComment;
            }
        } else {
            if (comment !== "") {
                comment = comment + "\n" + currentDate + " Diseñador: " + newComment;
            } else {
                comment = currentDate + " Diseñador: " + newComment;
            }
        }
        let objComment = {
            orderId: cartInfo.orderId,
            comment: comment,
            index: selectItem.index
        }
        let response = await fetchAddComentCartDesignerApi(objComment);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                selectItem.cartSelect.remark = comment;
                setNewComment("");
                setIsLoading(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    async function handleAddComent(e) {
        e.preventDefault();
        setIsLoading(true);
        let comment = cartInfo[0].remark;

        if (userToken.user === 3) {
            if (comment !== "") {
                comment = comment + "\n" + currentDate + " Admin: " + newComment;
            } else {
                comment = currentDate + " Admin: " + newComment;
            }
        } else {
            if (comment !== "") {
                comment = comment + "\n" + currentDate + " Diseñador: " + newComment;
            } else {
                comment = currentDate + " Diseñador: " + newComment;
            }
        }

        let objComment = {
            orderId: cartInfo.orderId,
            comment: comment,
            index: 0
        }
        let response = await fetchAddComentCartDesignerApi(objComment);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                cartInfo[0].remark = comment;
                setNewComment("");
                setIsLoading(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    const getCurrentDate = () => {
        let tempDate = new Date();
        if (tempDate.getMonth() < 10) {
            if (tempDate.getDate() < 10) {
                let date = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            } else {
                let date = tempDate.getFullYear() + '-0' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            }
        } else {
            if (tempDate.getDate() < 10) {
                let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-0' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            } else {
                let date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
                const currDate = date;
                setCurrentDate(currDate);
            }
        }
    }

    const char_count = (str, letter) => {
        var letter_Count = 0;
        for (var position = 0; position < str.length; position++) {
            if (str.charAt(position) === letter) {
                letter_Count += 1;
            }
        }
        return letter_Count;
    }


    useEffect(() => {
        getCurrentDate();
        if (Object.keys(cartInfo).length !== 0 && isModalOpen) {
            setSelectItem({ ...selectItem, index: 0, cartSelect: cartInfo[0] });
        }
    }, [isModalOpen]);

    return (
        <>
            <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                id="registerModal" tabIndex="1" aria-labelledby="registerModalLabel" aria-hidden="true">
                {componentToast()}
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Comentarios del pedido</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModalComments(false)}></button>
                        </div>
                        <div className="modal-body" style={{ height: 450, overflowY: 'auto' }}>
                            {Object.keys(cartInfo).length !== 0 && Object.keys(cartInfo).length <= 1 ?
                                <>
                                    <form className="row mb-3" onSubmit={handleAddComent}>
                                        <div className="col-lg-12 optionContainer">
                                            <textarea type="text" className="form-control" id="inputOrderComment" placeholder='Comments'
                                                name="remark" value={cartInfo[0].remark} rows={cartInfo[0].remark ? char_count(cartInfo[0].remark, ':') : 2} disabled />
                                        </div><br />
                                        <div className="col-md-7">
                                            <label htmlFor="inputNewComment" className="form-label">Añadir comentario:</label><br />
                                            <input type="text" className="form-control" value={newComment} id="manufacturerName"
                                                onChange={(e) => { setNewComment(e.target.value) }}
                                                name="inputNewComment" required />
                                        </div>
                                        <div className="col-md-5" style={{ alignSelf: 'flex-end' }}>
                                            {isLoading ?
                                                <button className="btn btn-primary" type="submit" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-success"
                                                    disabled={!newComment}>añadir nuevo</button>
                                            }
                                        </div>
                                    </form>
                                </>
                                :
                                <>
                                    <select className="form-select form-select-sm" aria-label="Default select example" style={{ marginBottom: 20 }}
                                        value={selectItem.index}
                                        onChange={(e) => { setSelectItem({ ...selectItem, index: Number(e.target.value), cartSelect: cartInfo[Number(e.target.value)] }) }}>
                                        {cartInfo.map((cart, j) => {
                                            return (
                                                <option key={j} value={j}>{j + 1}).{" "}{cart.description}</option>
                                            )
                                        })}
                                    </select>
                                    {Object.keys(cartInfo).length !== 0 && isModalOpen ?
                                        <>
                                            <form className="row mb-3" onSubmit={handleAddComentSelect}>
                                                <div className="col-lg-12 optionContainer">
                                                    <textarea type="text" className="form-control" id="inputOrderComment" placeholder='Comments'
                                                        name="remark" value={selectItem.cartSelect.remark} rows={selectItem.cartSelect.remark ? char_count(selectItem.cartSelect.remark, ':') : 2} disabled />
                                                </div><br />
                                                <div className="col-md-7">
                                                    <label htmlFor="inputNewComment" className="form-label">Añadir comentario:</label><br />
                                                    <input type="text" className="form-control" value={newComment} id="manufacturerName"
                                                        onChange={(e) => { setNewComment(e.target.value) }}
                                                        name="inputNewComment" required />
                                                </div>
                                                <div className="col-md-5" style={{ alignSelf: 'flex-end' }}>
                                                    {isLoading ?
                                                        <button className="btn btn-primary" type="submit" disabled>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        </button>
                                                        :
                                                        <button type="submit" className="btn btn-success"
                                                            disabled={!newComment}>añadir nuevo</button>
                                                    }
                                                </div>
                                            </form>
                                        </>
                                        : null
                                    }
                                </>
                            }
                            <br />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => handleCloseModalComments(false)}>Entendido</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalCartComments;



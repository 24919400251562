import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import MUIDataTable from "mui-datatables";
import { getOrdersAdminApi } from '../services';
import { ImEye } from 'react-icons/im';
import { BiCommentDetail } from 'react-icons/bi';
import ModalCartDetails from '../../Account/components/ModalCartDetails';
import ModalCartComments from '../../Account/components/ModalCartComments';
const ordersColumns = [
    {
        name: "#",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Diseñador",
        label: "Diseñador",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Proveedor",
        label: "Proveedor",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Precio",
        label: "Precio",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "Descripción",
        label: "Descripción",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Cantidad",
        label: "Cantidad",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Estado",
        label: "Estado",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Acción",
        label: "Acción",
        options: {
            filter: false,
            sort: false,
        }
    }
]

const options = {
    filterType: 'checkbox',
};
function OrdersAdministrator() {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    const [ordersArray, setOrdersArray] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenComments, setIsModalOpenComments] = useState(false);
    const [cartDetailsArray, setCartDetailsArray] = useState([]);

    const handleVisualizeDetails = (cartInfo) => {
        setCartDetailsArray(cartInfo);
        setIsModalOpen(true);
    }

    const handleVisualizeComments = (orderId, cartInfo) => {
        cartInfo.orderId = orderId;
        setCartDetailsArray(cartInfo);
        setIsModalOpenComments(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleCloseModalComments = () => {
        setIsModalOpenComments(false);
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function bringAllOrders() {
        const response = await getOrdersAdminApi();
        if (typeof response !== 'undefined') {
            if (response.data.orders) {
                let orders = response.data.orders;
                setOrdersArray(orders);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    const handleOrdersCount = () => {
        return ordersArray.length;
    }

    const handleOrdersPriceCount = () => {
        let totalSum = 0;
        ordersArray.forEach(element => {
            totalSum = totalSum + element.totalPrice;
        });
        return Intl.NumberFormat("de-DE").format(totalSum);
    }

    useEffect(() => {
        bringAllOrders();
    }, []);

    return (
        <div>
            <div className="adminInformationContainer">
                {componentToast()}
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card informationCard">
                            <h4 style={{ textAlign: "center" }}>Órdenes activas</h4>
                            <h3 style={{ color: "lightblue", margin: 15 }}><b>{handleOrdersCount()}</b></h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card informationCard">
                            <h4 style={{ textAlign: "center" }}>Total órdenes</h4>
                            <h3 style={{ color: "green", margin: 15 }}><b>$ {handleOrdersPriceCount()}</b></h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="card informationCard">
                        <div className="row w-100" style={{ textAlign: "center" }}>
                            <MUIDataTable
                                title={"Listado de órdenes"}
                                data={ordersArray.map((order, i) => {
                                    return [
                                        i + 1,
                                        order.designerInfo.name + " " + order.designerInfo.lastName,
                                        order.cartInfo.map((cart, j) => {
                                            return (
                                                <div key={j}>
                                                    {cart.providerCompany}
                                                </div>
                                            )
                                        }),
                                        order.totalPrice,
                                        order.cartInfo.map((cart, j) => {
                                            return (
                                                <div key={j}>
                                                    {j + 1}).{" "}{cart.description}
                                                </div>
                                            )
                                        }),
                                        order.cartInfo.map((cart, c) => {
                                            return (<div key={c}>
                                                {" "}{cart.qty}</div>)
                                        }),
                                        order.cartInfo.map((cart, c) => {
                                            return (<div key={c}>
                                                {" "}{cart.stateCart ? cart.stateCart : "Pago aceptado"}</div>)
                                        }),
                                        <div style={{ fontSize: 25 }}>
                                            <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-info cartButon" onClick={() => { handleVisualizeDetails(order.cartInfo) }}>
                                                <ImEye />
                                            </button>
                                            <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-secondary" onClick={() => { handleVisualizeComments(order._id, order.cartInfo) }}>
                                                <BiCommentDetail />
                                            </button>
                                        </div>
                                    ]
                                })}
                                columns={ordersColumns}
                                options={options}
                            />
                        </div>
                    </div>
                </div>

                <ModalCartDetails isModalOpen={isModalOpen} cartInfo={cartDetailsArray} handleCloseModal={handleCloseModal} />
                <ModalCartComments isModalOpen={isModalOpenComments} cartInfo={cartDetailsArray} handleCloseModalComments={handleCloseModalComments} />

            </div>
        </div>
    )
}

export default OrdersAdministrator

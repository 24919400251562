import React, { useState, useEffect } from 'react';
import Information from './components/Information';
import Orders from './components/Orders';
import Historial from './components/Historial';
import '../../css/principal.css';
import ShoppingCart from '../ShoppingCart';

const itemsCrumbs = [
    { i: 1, label: 'Cuenta' },
    { i: 2, label: 'Carrito' },
    { i: 3, label: 'Órdenes' },
    // { i: 4, label: 'Historial' }
]

function Account() {
    const [changeCrumb, setChangeCrumb] = useState('');

    let componentCrumb = () => {
        switch (changeCrumb) {
            case 'Cuenta':
                return <Information />
            case 'Carrito':
                return <ShoppingCart />
            case 'Órdenes':
                return <Orders />
            // case 'Historial':
            //     return <Historial />
            default:
                return <Information />
        }
    }

    useEffect(() => {
        if (window.location.hash === '#3') {
            setChangeCrumb('Órdenes');
        }
        if (window.location.hash === '#1') {
            setChangeCrumb('Cuenta');
        }
    }, [window.location.hash])

    return (

        <div className='principalContainer'>
            <div className='mainWrapper'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {itemsCrumbs.map(({ i, label }) => {
                            if (changeCrumb === label) {
                                return (
                                    <li className="breadcrumb-item active" aria-current="page" key={i} onClick={() => setChangeCrumb(label)}>
                                        {label}
                                    </li>
                                )
                            } else {
                                return (
                                    <li className="breadcrumb-item" key={i} onClick={() => setChangeCrumb(label)}>
                                        <a href={"#" + i}>{label}</a>
                                    </li>
                                )
                            }
                        })}
                    </ol>
                </nav>
                {componentCrumb()}
            </div>
        </div>
    )
}

export default Account

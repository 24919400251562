import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import { getProvidersAdminApi, getQuotersAdminApi } from '../services';
import MUIDataTable from "mui-datatables";
import ProviderDetails from './ProviderDetails';
import ModalQuoterAdmin from './ModalQuoterAdmin';

const columns = [
    {
        name: "#",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Nombre",
        label: "Nombre",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Email",
        label: "Email",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Contacto",
        label: "Contacto",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Estado",
        label: "Estado",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Estado de cuenta",
        label: "Estado de cuenta",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Acción",
        label: "Acción",
        options: {
            filter: false,
            sort: false,
        }
    }
]

const options = {
    filterType: 'checkbox',
};

function ManufacturersAdministrator() {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    const [providersArray, setProvidersArray] = useState([]);
    const [quotersArray, setQuotersArray] = useState([]);
    const [quoterInfo, setQuoterInfo] = useState({});
    const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
    const [providerInfo, setProviderInfo] = useState([]);

    const [isQuoterModal, setIsQuoterModal] = useState(false);
    const [isLoadingQuoterProvider, setIsLoadingQuoterProvider] = useState(false);

    const [isBanModal, setIsBanModal] = useState({ modal: false, id: 0 });
    const [isLoadingBanProvider, setIsLoadingBanProvider] = useState(false);

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function bringAllProviders() {
        const response = await getProvidersAdminApi();
        if (typeof response !== 'undefined') {
            if (response.data.providers) {
                let providers = response.data.providers;
                setProvidersArray(providers);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    const handleProvidersCount = () => {
        return providersArray.length;
    }

    const handlePriceOrdersCount = () => {
        let totalSum = 0;
        providersArray.forEach(element => {
            totalSum = totalSum + element.paymentInformation;
        });
        return Intl.NumberFormat("de-DE").format(totalSum);
    }

    const handleBanProvider = (e) => {
        e.preventDefault();
        setIsLoadingBanProvider(true);
        setTimeout(() => {
            // console.log(isBanModal.id);
            isBanModal.modal = false;
            setIsLoadingBanProvider(false);
            setConfigToast({ ...configToast, typeToast: "done", textToast: 'Baneo exitoso' });
        }, 2000);
    }

    const handleQuoterProvider = (e) => {
        e.preventDefault();
        setIsLoadingQuoterProvider(true);
        setTimeout(() => {
            // console.log(isBanModal.id);
            isQuoterModal.modal = false;
            setIsLoadingQuoterProvider(false);
            setConfigToast({ ...configToast, typeToast: "done", textToast: 'Actualización exitosa' });
        }, 2000);
    }

    const handleShowDetailsProvider = (provider) => {
        setProviderInfo(provider);
        setIsDetailViewOpen(true);
    }

    const handleCloseDetailsProvider = () => {
        setIsDetailViewOpen(false);
    }

    const handleVisualizeQuoter = (providerId) => {
        const foundQuoter = quotersArray.find(element => element.providerId === providerId);
        if (typeof foundQuoter !== 'undefined') {
            // console.log(foundQuoter);
            setQuoterInfo(foundQuoter);
            setIsQuoterModal(true);
        } else {
            setConfigToast({ ...configToast, typeToast: "info", textToast: 'Proveedor sin cotizador' });
        }
    }

    const handleCloseQuoterModal = () => {
        setQuoterInfo({});
        setIsQuoterModal(false);
    }

    async function bringAllQuoters() {
        const response = await getQuotersAdminApi();
        if (typeof response !== 'undefined') {
            if (response.data.quoters) {
                let quoters = response.data.quoters;
                setQuotersArray(quoters);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }

    }

    useEffect(() => {
        bringAllProviders();
        bringAllQuoters();
    }, []);

    return (
        <div>
            {!isDetailViewOpen ?

                <div className="adminInformationContainer">
                    {componentToast()}
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card informationCard">
                                <h4 style={{ textAlign: "center" }}>Estado de cuenta con los proveedores</h4>
                                <h3 style={{ color: "#198754", margin: 15 }}><b>${handlePriceOrdersCount()}</b></h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card informationCard">
                                <h4 style={{ textAlign: "center" }}>Proovedores registrados</h4>
                                <h3 style={{ color: "lightblue", margin: 15 }}><b>{handleProvidersCount()}</b></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="card informationCard">
                            {/* <h3 style={{ marginBottom: 30 }}>Listado de proveedores</h3> */}
                            <div className="row w-100" style={{ textAlign: "center" }}>
                                <MUIDataTable
                                    title={"Listado de proveedores"}
                                    data={providersArray.map((provider, i) => {
                                        return [
                                            i + 1,
                                            provider.companyName,
                                            provider.email,
                                            provider.phoneNumber,
                                            provider.status,
                                            "$ " + Intl.NumberFormat("de-DE").format(provider.paymentInformation),
                                            <div style={{ fontSize: 25 }}>
                                                <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-info cartButon" onClick={() => handleShowDetailsProvider(provider)}>
                                                    <ion-icon name="eye"></ion-icon>
                                                </button>
                                                <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-secondary" onClick={() => handleVisualizeQuoter(provider._id)}>
                                                    <ion-icon name="calculator"></ion-icon>
                                                </button>
                                                <button style={{ padding: 4 }} type="button" className="btn btn-danger" onClick={() => setIsBanModal({ ...isBanModal, modal: true, id: provider._id })}>
                                                    <ion-icon name="close"></ion-icon>
                                                </button>
                                            </div>
                                        ]
                                    })}
                                    columns={columns}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                    <div className={`modal fade registerModal ${isBanModal.modal ? 'show' : ''}`}
                        style={{ display: `${isBanModal.modal ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }} id="deleteModal" tabIndex="1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">¿Desea banear al fabricante?</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsBanModal({ ...isBanModal, modal: false })}></button>
                                </div>
                                <form onSubmit={handleBanProvider}>
                                    <div className="modal-body">
                                        <label htmlFor="">Tiempo de baneo</label>
                                        <input type="date" className="form-control" required />
                                        <label htmlFor="">Escribe tu contraseña para confirmar</label>
                                        <input type="password" className="form-control" required />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setIsBanModal({ ...isBanModal, modal: false })}>Cerrar</button>
                                        {isLoadingBanProvider ?
                                            <button type="submit" className="btn btn-danger" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button>
                                            :
                                            <button type="submit" className="btn btn-danger">Confirmar</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <ModalQuoterAdmin isModalOpen={isQuoterModal} quoterInfo={quoterInfo} handleCloseQuoterModal={handleCloseQuoterModal} />
                </div>
                :
                <ProviderDetails handleCloseDetailsProvider={handleCloseDetailsProvider} providerInfo={providerInfo} />
            }
        </div>
    )
}

export default ManufacturersAdministrator

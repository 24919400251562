import React, { useEffect, useState } from 'react';
import './Footer.css';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import { MdFingerprint } from 'react-icons/md';
import Toast from '../../components/toast/Toast';
import { contactUsUser } from './services';

function Footer() {
  const [configToast, setConfigToast] = useState({
    typeToast: '',
    textToast: ''

  });
  const [formValues, setFormValues] = useState({
    email: '',
    name: '',
    comment: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value });
  }

  const changeError = () => {
    setConfigToast({ ...configToast, typeToast: '', textToast: '' });
  }
  let componentToast = () => {
    if (configToast.typeToast !== '') {
      return <Toast typeToast={configToast.typeToast}
        changeError={changeError}
        textToast={configToast.textToast} />
    } else {
      return null
    }
  }

  async function submitContactUs(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await contactUsUser(formValues);
    if (typeof response !== 'undefined') {
      if (response.data.done) {
        setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
        setFormValues({ ...formValues, name: '', email: '', comment: '' });
        setIsLoading(false);
      }
      if (response.data.info) {
        setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
        setIsLoading(false);
      }
      if (response.data.error) {
        setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
        setIsLoading(false);
      }
    } else {
      console.log("problema en la peticion");
      setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
      setIsLoading(false);
      return
    }
  }

  return (
    <div className='footer-container' id="supp">
      {componentToast()}
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          ¿No encuentras lo que necesitas?
        </p>
        <p className='footer-subscription-text'>
          Contáctanos ahora
        </p>
        <div className='input-areas'>
          <form onSubmit={submitContactUs}>
            <label htmlFor="">*Email:</label>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='user@example.com'
              onChange={handleChange}
              value={formValues.email}
              required
            />
            <label htmlFor="">*Nombre: </label>
            <input
              className='footer-input'
              name='name'
              type='text'
              placeholder='nombre'
              onChange={handleChange}
              value={formValues.name}
              required
            />
            <label htmlFor="">*Texto: </label>
            <textarea
              className='footer-input'
              name='comment'
              type='texarea'
              placeholder='...'
              onChange={handleChange}
              value={formValues.comment}
              required
            />
            {isLoading ?
              <Button buttonColor='primary' buttonStyle="btn--primary" type="submit" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </Button>
              :
              <Button type='submit' buttonStyle='btn--outline'>Enviar</Button>
            }
          </form>
        </div>
      </section>
      {/* <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className="navbar-logo" className='footerLogo'>
              <img src="images/PROTOTIPARK_NOTION.png" alt="" className='navbar-icon' />
                    PROTIPARK
                </Link>
          </div>
          <small className='website-rights'>Designed by ETERUX © 2021</small>
          <div className='social-icons'>
            <a
              className='social-icon-link'
              href='https://www.facebook.com/protipark'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </a>
            <a
              className='social-icon-link'
              href='https://www.instagram.com/protipark/'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </a>
            <a
              className='social-icon-link'
              href='https://www.youtube.com/channel/UCg1bQ9t5EcgxzklbwNP3FNQ'
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </a>
            {/* <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <FaTwitter />
            </Link> */}
            <a
              className='social-icon-link'
              href='https://www.linkedin.com/company/protipark/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
//Url Designer Profile Api
const getDesignerDataUrl = '/designers/designerData';
const fetchUpdateProfileDesignerUrl = '/designers/updateProfileDesigner';
const fetchUpdatePassDesignerUrl = '/designers/updatePassDesigner';
//Url Designer BillingInformation Api
const fetchCreateBillingDesignerUrl = '/designers/createBillingDesigner';
const removeBillingDesignerUrl = '/designers/removeBillingDesigner';
const fetchUpdateBillingDesignerUrl = '/designers/updateBillingDesigner';

//Url Designer ShippingInformation Api
const fetchCreateShippingDesignerUrl = '/designers/createShippingDesigner';
const removeShippingDesignerUrl = '/designers/removeShippingDesigner';
const fetchUpdateShippingDesignerUrl = '/designers/updateShippingDesigner';

//Url Designer Add Comment Cart
const fetchAddComentCartDesignerUrl = '/orders/addCommentCartDesigner';

let userToken = JSON.parse(localStorage.getItem('userToken'));

//Function's Designer Profile

export async function getDesignerDataApi() {
  try {
    const response = await axios({
      url: baseUrl + getDesignerDataUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      // data: dataDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchUpdateDesignerProfileApi(dataProfileDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchUpdateProfileDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataProfileDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchUpdatePassDesignerApi(dataChangePassDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchUpdatePassDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataChangePassDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

//Function's Designer BillingInformation

export async function fetchCreateBillingDesignerApi(dataBillingDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchCreateBillingDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataBillingDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function removeBillingDesignerApi(indexBill) {
  try {
    const response = await axios({
      url: baseUrl + removeBillingDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: indexBill,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchUpdateBillingDesignerApi(dataBillingUpdateDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchUpdateBillingDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataBillingUpdateDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

//Function's Designer ShippingInformation

export async function fetchCreateShippingDesignerApi(dataShippingDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchCreateShippingDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataShippingDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function removeShippingDesignerApi(indexShipping) {
  try {
    const response = await axios({
      url: baseUrl + removeShippingDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: indexShipping,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchUpdateShippingDesignerApi(dataShippingUpdateDesigner) {
  try {
    const response = await axios({
      url: baseUrl + fetchUpdateShippingDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataShippingUpdateDesigner,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchAddComentCartDesignerApi(dataCartUpdate) {
  try {
    const response = await axios({
      url: baseUrl + fetchAddComentCartDesignerUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': userToken.token
      },
      data: dataCartUpdate,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}
import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import { fecthUpdateStateCartOrderProviderApi } from '../services';

const itemsNav = [
    { i: 1, label: 'Placa' },
    { i: 2, label: 'Cliente' },
    { i: 3, label: 'Envío' }
]

function OrderDetails({ handleCloseDetailsView, cartInfo }) {
    const [changeItem, setChangeItem] = useState('');

    const [stateCart, setChangeStateCart] = useState('');
    const [stateCartDelivery, setStateCartDelivery] = useState('');
    const [isLoadingState, setIsLoadingState] = useState(false);

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const char_count = (str, letter) => {
        var letter_Count = 0;
        for (var position = 0; position < str.length; position++) {
            if (str.charAt(position) == letter) {
                letter_Count += 1;
            }
        }
        return letter_Count;
    }

    const handleLayersFormat = (value) => {
        switch (value) {
            case 'LAYER_0':
                return 1
            case 'LAYER_1':
                return 2
            case 'LAYER_2':
                return 4
            case 'LAYER_3':
                return 6
            case 'LAYER_4':
                return 8
            case 'LAYER_5':
                return 10
            case 'LAYER_6':
                return 12
            case 'LAYER_7':
                return 14
            default:
                break;
        }
    }

    const handleSwitchFormat = (value) => {
        switch (value) {
            case 'FORMAT_0':
                return "Single PCB"
            case 'FORMAT_1':
                return "Designer panel"
            case 'FORMAT_2':
                return "Protipark panel"
            default:
                break;
        }
    }

    const handleSwitchMaterial = (value) => {
        switch (value) {
            case 'MATERIAL_0':
                return "FR2"
            case 'MATERIAL_1':
                return "FR4-TG130-140"
            case 'MATERIAL_2':
                return "FR4-TG150-160"
            case 'MATERIAL_3':
                return "FR4-TG170-180"
            default:
                break;
        }
    }

    const handleSwitchThickness = (value) => {
        switch (value) {
            case 'THICKNESS_0':
                return "0.2"
            case 'THICKNESS_1':
                return "0.4"
            case 'THICKNESS_2':
                return "0.6"
            case 'THICKNESS_3':
                return "0.8"
            case 'THICKNESS_4':
                return "1.2"
            case 'THICKNESS_5':
                return "1.4"
            case 'THICKNESS_6':
                return "1.6"
            case 'THICKNESS_7':
                return "1.8"
            case 'THICKNESS_8':
                return "2.0"
            case 'THICKNESS_9':
                return "2.4"
            case 'THICKNESS_10':
                return "2.8"
            case 'THICKNESS_11':
                return "3.0"
            case 'THICKNESS_12':
                return "3.2"
            case 'THICKNESS_13':
                return "3.6"
            case 'THICKNESS_14':
                return "3.8"
            case 'THICKNESS_15':
                return "4.0"
            default:
                break;
        }
    }

    const handleSwitchMinTrackSpacing = (value) => {
        switch (value) {
            case 'MIN_TRACK_SPACING_0':
                return "3/3 mil"
            case 'MIN_TRACK_SPACING_1':
                return "4/4 mil"
            case 'MIN_TRACK_SPACING_2':
                return "5/5 mil"
            case 'MIN_TRACK_SPACING_3':
                return "6/6 mil"
            case 'MIN_TRACK_SPACING_4':
                return "8/8 mil"
            case 'MIN_TRACK_SPACING_5':
                return "10/10 mil"
            case 'MIN_TRACK_SPACING_6':
                return "15/15 mil"
            default:
                break;
        }
    }

    const handleSwitchMinHoleSize = (value) => {
        switch (value) {
            case 'MIN_HOLESIZE_0':
                return "0.15 mm"
            case 'MIN_HOLESIZE_1':
                return "0.20 mm"
            case 'MIN_HOLESIZE_2':
                return "0.25 mm"
            case 'MIN_HOLESIZE_3':
                return "0.30 mm"
            case 'MIN_HOLESIZE_4':
                return "0.40 mm"
            case 'MIN_HOLESIZE_5':
                return "from 0.50 mm"
            default:
                break;
        }
    }


    const handleSwitchSolderMaskColor = (value) => {
        switch (value) {
            case 'SOLDERMASK_0':
                return "Green"
            case 'SOLDERMASK_1':
                return "Red"
            case 'SOLDERMASK_2':
                return "Yellow"
            case 'SOLDERMASK_3':
                return "Blue"
            case 'SOLDERMASK_4':
                return "Purple"
            case 'SOLDERMASK_5':
                return "White"
            case 'SOLDERMASK_6':
                return "Black"
            case 'SOLDERMASK_7':
                return "none"
            default:
                break;
        }
    }

    const handleSwitchSilkScreenColor = (value) => {
        switch (value) {
            case 'SILKSCREEN_0':
                return "White"
            case 'SILKSCREEN_1':
                return "Black"
            case 'SILKSCREEN_2':
                return "Yellow"
            default:
                break;
        }
    }

    const handleSwitchSurface = (value) => {
        switch (value) {
            case 'SURFACE_0':
                return "HASL-Lead"
            case 'SURFACE_1':
                return "HASL-RoHS"
            case 'SURFACE_2':
                return "ENIG-RoHS"
            default:
                break;
        }
    }


    let componentItem = () => {
        switch (changeItem) {
            case 'Placa':
                return (<><div className="mb-4" style={{ marginTop: 10, borderBottom: .5 }}>
                    <label><b>Datos de la placa</b> </label>
                </div>
                    {cartInfo.cartInfo.map((info, i) => {
                        return (
                            <div key={i} className="row">
                                <div className="col-lg-6 cardDetailsBox">
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Capas: </b></label>
                                        <p>{handleLayersFormat(info.layers)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Dimensiones: </b></label>
                                        <p>{info.dimensions.dimension_x}{" * "}{info.dimensions.dimension_y}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Cantidad: </b></label>
                                        <p>{info.qty}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Formato: </b></label>
                                        <p>{handleSwitchFormat(info.format)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Material: </b></label>
                                        <p>{handleSwitchMaterial(info.material)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Thickness: </b></label>
                                        <p>{handleSwitchThickness(info.thickness)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Min Track/Spacing: </b></label>
                                        <p>{handleSwitchMinTrackSpacing(info.minTrackSpacing)}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Min Hole Size: </b></label>
                                        <p>{handleSwitchMinHoleSize(info.minHoleSize)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Mask color: </b></label>
                                        <p>{handleSwitchSolderMaskColor(info.soldermaskColor)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Silk color: </b></label>
                                        <p>{handleSwitchSilkScreenColor(info.silkscreenColor)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>SurfaceFinish: </b></label>
                                        <p>{handleSwitchSurface(info.surfaceFinish)}</p>
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>CooperWight: </b></label>
                                        {info.copperWeight !== '' ?
                                            info.copperWeight === 'COPPER_0' ?
                                                <p>1 Oz</p>
                                                :
                                                <p>2 Oz</p>
                                            :
                                            <p>none</p>
                                        }

                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>Golfinger: </b></label>
                                        {info.goldFinger ?
                                            <p>Yes</p>
                                            : <p>No</p>
                                        }
                                    </div>
                                    <div className="d-flex">
                                        <label style={{ marginRight: 3 }} htmlFor=""><b>CastellatedHoles: </b></label>
                                        {info.castellatedHoles ?
                                            <p>Yes</p>
                                            : <p>No</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>);
            case 'Cliente':
                return (<><div className="mb-4" style={{ marginTop: 10, borderBottom: .5 }}>
                    <label><b>Datos del Cliente</b> </label>
                </div>
                    <div className="row">
                        <div className="col-lg-8 cardDetailsBox">
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Nombre: </b></label>
                                <p>{cartInfo.designerInfo.name}{" "}{cartInfo.designerInfo.lastName}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Email: </b></label>
                                <p>{cartInfo.designerInfo.email}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Teléfono: </b></label>
                                <p>{cartInfo.designerInfo.phone}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 cardDetailsBox">
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Ciudad: </b></label>
                                <p>{cartInfo.designerInfo.city}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>País: </b></label>
                                <p>{cartInfo.designerInfo.country}</p>
                            </div>
                        </div>
                    </div>
                </>);
            case 'Envío':
                return (<><div className="mb-4" style={{ marginTop: 10, borderBottom: .5 }}>
                    <label><b>Datos de envío</b> </label>
                </div>
                    <div className="row">
                        <div className="col-lg-8 cardDetailsBox">
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Nombre: </b></label>
                                <p>{cartInfo.shippingInfo.shippingName}{" "}{cartInfo.shippingInfo.shippingLastName}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Dirección: </b></label>
                                <p>{cartInfo.shippingInfo.shippingAddress1}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Complemento: </b></label>
                                <p>{cartInfo.shippingInfo.shippingAddress2}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Recibe: </b></label>
                                <p>{cartInfo.shippingInfo.shippingReceiver}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Teléfono: </b></label>
                                <p>{cartInfo.shippingInfo.shippingNumber}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 cardDetailsBox">
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>Ciudad: </b></label>
                                <p>{cartInfo.shippingInfo.shippingCity}</p>
                            </div>
                            <div className="d-flex">
                                <abbr title="Departamento"><label style={{ marginRight: 3 }} htmlFor=""><b>Dpto: </b></label></abbr>
                                <p>{cartInfo.shippingInfo.shippingState}</p>
                            </div>
                            <div className="d-flex">
                                <label style={{ marginRight: 3 }} htmlFor=""><b>País: </b></label>
                                <p>{cartInfo.shippingInfo.shippingCountry}</p>
                            </div>
                            <div className="d-flex">
                                <abbr title="Código postal"><label style={{ marginRight: 3 }} htmlFor=""><b>C.postal: </b></label></abbr>
                                <p>{cartInfo.shippingInfo.shippingZipCode}</p>
                            </div>
                        </div>
                    </div>
                </>);
            default:
                return null;
        }
    }

    const handleStateCart = (cartState) => {
        switch (cartState) {
            case "Pago aceptado":
                return (<button type="button" className="btn btn-outline-success" disabled>{" "}{cartState}</button>)
            case "En revisión":
                return (<button type="button" className="btn btn-outline-warning" disabled>{" "}{cartState}</button>)
            case "En proceso":
                return (<button type="button" className="btn btn-outline-secondary" disabled>{" "}{cartState}</button>)
            case "En embalaje":
                return (<button type="button" className="btn btn-outline-info" disabled>{" "}{cartState}</button>)
            case "Enviado":
                return (<button type="button" className="btn btn-outline-primary" disabled>{" "}{cartState}</button>)
            case "Entregado":
                return (<button type="button" className="btn btn-primary" disabled>{" "}{cartState}</button>)
            case "Finalizado":
                return (<button type="button" className="btn btn-success" disabled>{" "}{cartState}</button>)

            default:
                return (<button type="button" className="btn btn-outline-success" disabled>{" Pago aceptado"}</button>)
        }
    }

    async function handleSubmitUpdateState(e) {
        e.preventDefault();
        setIsLoadingState(true);
        let objState = {
            orderId: cartInfo._id,
            stateCart: stateCart,
            cartId: cartInfo.cartInfo[0]._id
        }

        if (stateCartDelivery) {
            objState.deliveryCode = stateCartDelivery;
        }

        const response = await fecthUpdateStateCartOrderProviderApi(objState);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                cartInfo.cartInfo[0].stateCart = stateCart;
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoadingState(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoadingState(false);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoadingState(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoadingState(false);
            return
        }
    }


    useEffect(() => {
        setChangeItem('Placa');
        if (cartInfo.cartInfo[0].stateCart !== "Pago aceptado") {
            setChangeStateCart(cartInfo.cartInfo[0].stateCart);
        }
        if (cartInfo.cartInfo[0].deliveryCode) {
            setStateCartDelivery(cartInfo.cartInfo[0].deliveryCode);
        }
    }, []);

    return (
        <div>
            <section className="w-100">
                {componentToast()}
                <div className="secondContainer">
                    <div className="row mb-3">
                        <div className="div-xl-12">
                            <h3><button type="button" className="btn btn-outline-secondary btn-lg" style={{ margin: 10 }}
                                onClick={() => handleCloseDetailsView()}>
                                <div style={{ marginTop: 5 }}><ion-icon name="arrow-back" /></div>
                            </button>Order</h3>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-3 ">
                            <div className="styledTopCard">
                                <div>
                                    <label htmlFor="">Fecha</label>
                                </div>
                                <div style={{ justifyContent: "center", alignItems: "center" }} className="w-100 d-flex">
                                    <h4 style={{ padding: 0, margin: 5, color: "purple" }}><ion-icon name="calendar"></ion-icon></h4>
                                    {cartInfo.cartInfo.map((info, i) => {
                                        return (
                                            <h5 key={i} style={{ paddingTop: 4 }}>{info.createdAt.substring(0, 10)}</h5>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 ">
                            <div className="styledTopCard">
                                <div>
                                    <label htmlFor="">Valor</label>
                                </div>
                                <div style={{ justifyContent: "center", alignItems: "center" }} className="w-100 d-flex">
                                    <h4 style={{ padding: 0, margin: 5, color: "green" }}><ion-icon name="cash"></ion-icon></h4>
                                    {cartInfo.cartInfo.map((info, i) => {
                                        return (
                                            <h5 style={{ paddingTop: 4, color: "green" }} key={i}>$ {Intl.NumberFormat("de-DE").format(info.totalPrice)}</h5>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 ">
                            <div className="styledTopCard">
                                <div>
                                    <label htmlFor="">Mensajes</label>
                                </div>
                                <div style={{ justifyContent: "center", alignItems: "center" }} className="w-100 d-flex">
                                    <h4 style={{ padding: 0, margin: 5, color: "lightblue" }}><ion-icon name="chatboxes"></ion-icon></h4>
                                    {cartInfo.cartInfo.map((info, i) => {
                                        return (
                                            <h5 style={{ paddingTop: 4 }} key={i}>{char_count(info.remark, ':')}</h5>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 ">
                            <div className="styledTopCard">
                                <div>
                                    <label htmlFor="">Tiempo de producción</label>
                                </div>
                                <div style={{ justifyContent: "center", alignItems: "center" }} className="w-100 d-flex">
                                    <h4 style={{ padding: 0, margin: 5, color: "red" }}><ion-icon name="calendar"></ion-icon></h4>
                                    {cartInfo.cartInfo.map((info, i) => {
                                        return (
                                            <h5 key={i} style={{ paddingTop: 4 }}>{info.productionTime} horas</h5>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-xl-7 ">
                            <div className="styledCard">
                                <div className="row mb-3 ">
                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor=""><b>Administrar los estados del pedido</b></label>
                                    </div>
                                    {/* <div className="col-md-2 mb-2">
                                        <button style={{ height: 30, textAlign: "center", justifyContent: "center", alignItems: "center", display: "flex" }} type="button" className="btn btn-outline-secondary border">Factura</button>
                                    </div>
                                    <div className="col-md-2 mb-2">
                                        <button style={{ height: 30, textAlign: "center", justifyContent: "center", alignItems: "center", display: "flex" }} type="button" className="btn btn-outline-secondary border">GerberFile</button>
                                    </div> */}
                                    <div className="col-xl-9" >
                                        <select className="form-select" aria-label="Default select example"
                                            onChange={(e) => { setChangeStateCart(e.target.value) }} value={stateCart} disabled={stateCart === 'Finalizado'} required>
                                            <option value="">Seleccionar un estado para el pedido</option>
                                            <option value="En revisión">En revisión</option>
                                            <option value="En proceso">En proceso</option>
                                            <option value="En embalaje">En embalaje</option>
                                            <option value="Enviado">Enviado</option>
                                            <option value="Entregado">Entregado</option>
                                            {/* <option value="3">Finalizado</option> */}
                                        </select>
                                    </div>
                                    {stateCart !== 'Enviado' ?
                                        <div className="col-md-3 ">
                                            {isLoadingState ?
                                                <button className="btn btn-primary" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                                :
                                                <button style={{ width: "100%" }} type="button" disabled={stateCart === "" || stateCart === 'Finalizado'}
                                                    onClick={(e) => handleSubmitUpdateState(e)} className="btn btn-primary">Actualizar</button>
                                            }
                                        </div>
                                        : <>
                                            <div className="col-lg-6" style={{ marginTop: 10 }}>
                                                <label htmlFor="inputDeliveryCode" className="form-label">Guia de envío:</label><br />
                                                <input type="text" className="form-control" value={stateCartDelivery} id="deliveryCode"
                                                    onChange={(e) => { setStateCartDelivery(e.target.value) }}
                                                    name="stateCartDelivery" />
                                            </div>
                                            <div className="col-md-3" style={{ alignSelf: 'flex-end' }}>
                                                {isLoadingState ?
                                                    <button className="btn btn-primary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </button>
                                                    :
                                                    <button style={{ width: "100%" }} type="button" disabled={stateCartDelivery === ""}
                                                        onClick={(e) => handleSubmitUpdateState(e)} className="btn btn-primary">Actualizar</button>
                                                }
                                            </div>
                                        </>}



                                    {stateCart === 'Entregado' ?
                                        <div className="col-lg-12 mb-3" style={{ marginTop: 5 }}>
                                            <ion-icon name="information-circle-outline"></ion-icon>
                                            <label htmlFor="">La entrega también debe ser confirmada por el cliente.</label>
                                        </div>
                                        : null}
                                    <div className="col-xl-12 statusBox">

                                        {cartInfo.cartInfo.map((info, i) => {
                                            return (<div key={i} style={{ textAlign: 'center' }}>
                                                <label htmlFor=""><b>Estado Actual: {handleStateCart(info.stateCart)}</b></label>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 ">
                            <div className="styledCard">
                                <ul className="nav nav-tabs">
                                    {itemsNav.map(({ i, label }) => {
                                        if (changeItem === label) {
                                            return (
                                                <li className="nav-item" key={i} onClick={() => setChangeItem(label)}>
                                                    <a className="nav-link active" aria-current="page" href={"#" + i}>{label}</a>
                                                </li>
                                            )
                                        } else {
                                            return (
                                                <li className="nav-item" key={i} onClick={() => setChangeItem(label)}>
                                                    <a className="nav-link" aria-current="page" href={"#" + i}>{label}</a>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>

                                {componentItem()}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OrderDetails

import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
//Url ShopingCart Actions Api
const removeCartDesignerUrl = '/carts/delete';


let userToken = JSON.parse(localStorage.getItem('userToken'));

//Function's Designer Cart

export async function removeCartDesignerApi(id) {
    let idCart = id;
    try {
        const response = await axios({
            url: baseUrl + removeCartDesignerUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: { idCart },
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}
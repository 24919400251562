import React from 'react'
import HeroSection from '../../components/HeroSection'
import { homeObjThree} from './Data'

function Product() {
    return (
        <>
            <HeroSection {...homeObjThree}/>
        </>
    )
}

export default Product

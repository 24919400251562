import React, { useState, useEffect } from 'react'
import { Button } from '../../../components/Button';
import Toast from '../../../components/toast/Toast';
import { getActiveDesignerApi } from '../services';

function ActiveButton() {
    const [isLoading, setIsLoading] = useState(false);
    const [tokenUser, setTokenUser] = useState('');
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    async function activateEmail(e) {
        e.preventDefault();
        setIsLoading(true);
        const response = await getActiveDesignerApi(tokenUser);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                window.location.search = "";
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    let componentActiveMail = () => {
        if (window.location.search) {
            return <><h1>Activacion de cuenta</h1>
                <p>Por favor haz clic en el botón de activación para confirmar tu correo electrónico.</p>
                <form onSubmit={activateEmail}>
                    {isLoading ?
                        <Button buttonColor='primary' buttonStyle="btn--primary" type="submit" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </Button>
                        :
                        <Button type="submit" buttonStyle="btn--primary" buttonColor='primary'>Activar Cuenta</Button>
                    }
                </form></>
        } else {
            return <><h1>Ya confirmaste tu cuenta.</h1>
                <p>Muchas gracias por ser parte de Protipark.</p></>
        }
    }

    useEffect(() => {
        if (window.location.search) {
            var s1 = window.location.search;
            var s2 = s1.substring(1);
            setTokenUser(s2);
        } else {
            console.log('nada en el header');
        }
    }, [])
    return (
        <>
            {componentActiveMail()}
            {componentToast()}
        </>
    )
}

export default ActiveButton

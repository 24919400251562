import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const contactUsUserUrl = '/admin/contacUsUser';

export async function contactUsUser(dataContact) {
  try {
    const response = await axios({
      url: baseUrl + contactUsUserUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataContact,
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
//Url Admin Login Api
const loginAdminUrl = '/admin/login';

export async function loginAdminApi(dataAdmin) {
    try {
      const response = await axios({
        url: baseUrl + loginAdminUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: dataAdmin,
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  }
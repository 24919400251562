import React, { useState } from 'react';
import Toast from '../../../components/toast/Toast';
import '../css/LoginView.css';
import { loginAdminApi } from '../services';

function LoginView() {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''

    });
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target
        setFormValues({ ...formValues, [name]: value });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        const response = await loginAdminApi(formValues);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                setTimeout(() => {
                    let objStorage = { token: response.data.token, user: 3 };
                    localStorage.setItem('userToken', JSON.stringify(objStorage));
                    window.location.href = '/AdminDashboard#1';
                }, 500);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    return (
        <div>
            <div className="mainBakcground">
                {componentToast()}
                <div className="loginForm">
                    <div className='AdmLoginLogo'>
                        <img src="images/PROTOTIPARK_NOTION.png" alt="" />
                    </div>
                    <div className="card loginFormCard">
                        <div className='header'>
                            <div className='header-title'>
                                <h2>Inicia Sesion</h2>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="adminInputEmail1" className="form-label">Admin Email</label>
                                <input type="email" name="email" className="form-control" id="adminInputEmail1" aria-describedby="emailHelp"
                                    onChange={handleChange} required />
                                <div id="emailHelp" className="form-text">Nunca compartas tus datos con nadie más</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="adminInputPassword1" className="form-label">Contraseña</label>
                                <input type="password" name="password" className="form-control" id="adminInputPassword1"
                                    onChange={handleChange} required />
                            </div>
                            <div className="mb-3 form-check">
                                {/* <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" for="exampleCheck1">Recuérdame</label> */}
                            </div>
                            {isLoading ?
                                <button className="btn btn-primary" type="submit" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                :
                                <button type="submit" className="btn btn-primary">Iniciar</button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginView

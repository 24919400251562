import React from 'react'
import ShoppingCartBody from './components/ShoppingCartBody'
import ShoppingCartPayment from './components/ShoppingCartPayment'

function ShoppingCart() {
    return (
        <div className='principalContainer'>
            <div className='mainWrapper'>
                <div className="row">
                    <div className="col-xl-8">
                        <ShoppingCartBody />
                    </div>
                    <div className="col-lg-4">
                        <ShoppingCartPayment />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShoppingCart

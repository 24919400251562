import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import Toast from '../../../components/toast/Toast';

function DesignerDetails({ handleCloseDetailsDesigner, designerInfo }) {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    const [isEdit, setIsEdit] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [personalInfo, setPersonalInfo] = useState({
        profileImage: '',
        username: '',
        name: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        country: '',
        city: '',
        password: ''
    });

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function bringDataDesigner() {
        setPersonalInfo({
            ...personalInfo,
            profileImage: designerInfo.profileImage,
            username: designerInfo.username,
            name: designerInfo.name,
            lastName: designerInfo.lastName,
            email: designerInfo.email,
            phoneNumber: designerInfo.phoneNumber,
            city: designerInfo.city,
            country: designerInfo.country,
        });
    }

    const handleFile = (e) => {
        let files = e.target.files;
        let base64Data;
        if (files.length !== 0) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                base64Data = e.target.result;
                setPersonalInfo({ ...personalInfo, profileImage: base64Data });
            }
        }
    }

    async function handleSubmitProfile(e) {
        e.preventDefault();
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            setConfigToast({ ...configToast, typeToast: "done", textToast: 'Actualización exitosa' });
            setIsEdit(!isEdit);
        }, 2000);
    }

    useEffect(() => {
        bringDataDesigner();
    }, []);


    return (
        <div>
            {componentToast()}
            <div className="secondContainer">
                <div className="row mb-3">
                    <div className="div-xl-12">
                        <h3><button type="button" className="btn btn-outline-secondary btn-lg" style={{ margin: 10 }}
                            onClick={() => handleCloseDetailsDesigner()}>
                            <div style={{ marginTop: 5 }}><ion-icon name="arrow-back" /></div>
                        </button>Diseñador: {designerInfo.name + " " + designerInfo.lastName}
                        </h3>
                    </div>
                </div>

                <form className="row g-3" onSubmit={handleSubmitProfile}>
                    <label htmlFor="inputUsername" className="form-label"><b>Foto de Perfil</b></label>
                    <div className="col-md-12">
                        {personalInfo.profileImage !== '' ?
                            <img src={personalInfo.profileImage} alt="uploadImage" style={{ width: 110, marginBottom: 15 }} />
                            :
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Empty" style={{ width: 110, marginBottom: 15 }} />
                        } <br />

                        <input className="form-control" type="file" id="formFile" onChange={handleFile} disabled={!isEdit} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputUsername" className="form-label"><b>Username *</b></label>
                        <input type="text" className="form-control" id="inputUsername" name="username"
                            onChange={(e) => { setPersonalInfo({ ...personalInfo, username: e.target.value }) }}
                            value={personalInfo.username} disabled={!isEdit} required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputName" className="form-label"><b>Nombre *</b></label>
                        <input type="text" className="form-control" id="inputName" name="name"
                            onChange={(e) => { setPersonalInfo({ ...personalInfo, name: e.target.value }) }}
                            value={personalInfo.name} disabled={!isEdit} required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputLastName" className="form-label"><b>Apellidos</b></label>
                        <input type="text" className="form-control" id="inputLastName" name="lastName"
                            onChange={(e) => { setPersonalInfo({ ...personalInfo, lastName: e.target.value }) }}
                            value={personalInfo.lastName} disabled={!isEdit} />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputEmail" className="form-label"><b>Email *</b></label>
                        <input type="email" className="form-control" id="inputEmail" placeholder="Example@example.com" name="email"
                            onChange={(e) => { setPersonalInfo({ ...personalInfo, email: e.target.value }) }}
                            value={personalInfo.email} disabled={!isEdit} required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputTel" className="form-label"><b>Teléfono</b></label>
                        <input type="text" className="form-control" id="inputTel" placeholder="555-3333" name="phoneNumber"
                            onChange={(e) => { setPersonalInfo({ ...personalInfo, phoneNumber: e.target.value }) }}
                            value={personalInfo.phoneNumber} disabled={!isEdit} />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="inputCity" className="form-label"><b>Ciudad</b></label>
                        <input type="text" className="form-control" id="inputCity" placeholder="Cali" name="city"
                            onChange={(e) => { setPersonalInfo({ ...personalInfo, city: e.target.value }) }}
                            value={personalInfo.city} disabled={!isEdit} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputCountry" className="form-label"><b>País</b></label>
                        <input type="text" className="form-control" id="inputCountry" placeholder="Colombia" name="country"
                            onChange={(e) => { setPersonalInfo({ ...personalInfo, country: e.target.value }) }}
                            value={personalInfo.country} disabled={!isEdit} />
                    </div>
                    {isEdit ?
                        <>
                            <label htmlFor="inputPassword" className="form-label"><b>Contraseña Actual *</b></label>
                            {isShowPass ?
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" id="inputPasswordHide" placeholder="Contraseña Actual" name="password"
                                        onChange={(e) => { setPersonalInfo({ ...personalInfo, password: e.target.value }) }}
                                        value={personalInfo.password} required />
                                    <span className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEyeSlash} /></span>
                                </div>
                                :
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" id="inputPasswordShow" placeholder="Contraseña Actual" name="password"
                                        onChange={(e) => { setPersonalInfo({ ...personalInfo, password: e.target.value }) }}
                                        value={personalInfo.password} required />
                                    <span className="input-group-text" id="basic-addon2" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEye} /></span>
                                </div>
                            }
                        </>
                        : null}
                    {!isEdit ?
                        <div className="col-4">
                            <button type="button" className="btn btn-primary" onClick={() => { setIsEdit(!isEdit) }}>Editar</button>
                        </div>
                        :
                        <>
                            <div className="col-md-2">
                                <button type="button" className="btn btn-danger" onClick={() => { setIsEdit(!isEdit) }}>Cancelar</button>
                            </div>
                            {isLoading ?
                                <div className="col-md-2">
                                    <button className="btn btn-primary" type="submit" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                                :
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-success" >Guardar</button>
                                </div>
                            }
                        </>
                    }
                </form>

            </div>
        </div>
    )
}

export default DesignerDetails
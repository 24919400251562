import React, { useState, useEffect, useContext } from 'react';
import GerberFile from './components/GerberFile';
import CardForm from './components/CardForm';
import ManufacturerList from './components/ManufacturersList';
import './css/GerberFile.css';
import Toast from '../../components/toast/Toast';
import { fetchCreateCartDesignerApi, fetchGetCartDesignerApi, fetchQuotersProvidersApi } from './services/index';
import { CartContext } from '../../context/CartContext';
import { Link } from 'react-router-dom';

let userToken = JSON.parse(localStorage.getItem('userToken'));

function Services() {
    const [cartsContext, setCartsContext] = useContext(CartContext);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isEditCard, setIsEditCard] = useState(false);

    const [isEditMaker, setIsEditMaker] = useState(false);
    const [listProviders, setListProviders] = useState([]);

    const [formCardData, setFormCardData] = useState({});

    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    // const [gerberFile, setGerberFile] = useState('');

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const bringMakerInfo = (values) => {
        if (Object.values(userToken.token).length !== 0) {
            setIsLoading(true);
            setIsEditMaker(true);
            setIsEditCard(false);
            formCardData.providerId = values._id;
            formCardData.providerCompany = values.company;
            formCardData.productionTime = values.timeMake;
            formCardData.totalPrice = values.price;
            // formCardData.gerberFile = { base64: gerberFile.base64Data, name: gerberFile.name };
            createCart();
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "info", textToast: "Debes iniciar sesión" });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (userToken !== null) {
            if (Object.values(userToken.token).length !== 0) {
                bringDataCartDesigner();
            }
        }
    }, []);

    async function bringDataCartDesigner() {
        const response = await fetchGetCartDesignerApi();
        if (typeof response !== 'undefined') {
            if (Object.keys(response.data.cartData).length !== 0) {
                setCartsContext(response.data.cartData);
                if (Object.keys(response.data.cartData).length !== cartsContext.length) {
                    setIsModalOpen(true);
                }
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    const bringCardInfo = (values) => {
        setFormCardData(values);
        setIsEditCard(!isEditCard);
        if (!isEditCard) {
            bringProviders(values);
        }
    }

    async function bringProviders(values) {
        const response = await fetchQuotersProvidersApi(values);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setListProviders(response.data.quotes);
                setIsEditCard(!isEditCard);
                // window.location.href = '#makerSection';
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setListProviders([]);
            }
            if (response.data.error) {
                // setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setListProviders([]);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setListProviders([]);
            return
        }
    }

    async function createCart() {
        const response = await fetchCreateCartDesignerApi(formCardData);
        if (typeof response !== 'undefined') {
            if (response.data.done) {
                setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                setIsLoading(false);
                cartsContext.push(response.data.cardStored);
                setCartsContext([...cartsContext]);
                setIsModalOpen(true);
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                setIsLoading(false);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                setIsLoading(false);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            setIsLoading(false);
            return
        }
    }

    // const bringGerberFile = (values) => {
    //     setGerberFile(values);
    // }

    return (
        <div className='principalContainer'>
            {componentToast()}
            <div className='mainWrapper'>
                <div className='container'>
                    <div className="row serviceFirstSection" id="makerSection">
                        <div className="col-lg-12" >
                            {/* <GerberFile bringGerberFile={bringGerberFile} /> */}
                        </div>
                        <div className="row">
                            <div className="col-md-7 cardSection" ><CardForm bringCardInfo={bringCardInfo} bringMaker={isEditCard} /></div>
                            <div className="col-md-5"><ManufacturerList bringMakerInfo={bringMakerInfo} bringMaker={isEditCard} listProviders={listProviders} /></div>
                        </div>
                    </div>
                </div>

                <div className={`modal fade registerModal ${isModalOpen ? 'show' : ''}`}
                    style={{ display: `${isModalOpen ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }}
                    id="registerModal" tabIndex="1" aria-labelledby="registerModalLabel" aria-hidden="true">

                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 800 }}>
                        <div className="modal-content" style={{ width: 800 }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Resumen de tu pedido</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsModalOpen(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div className='col-xl-12 informationContainer'>
                                    <div className="card" style={{ padding: 25 }}>
                                        <div className="list-group" id="list-tab" role="tablist" style={cartsContext.length > 1 ? { overflowY: 'scroll', height: 250 } : null}>
                                            {cartsContext.map((cart, i) => {
                                                return (
                                                    <div key={i} className="list-group-item" id="list-home-list" role="tab" aria-controls="home">
                                                        <div className="row mb-3">
                                                            <div className="col-sm-3" >
                                                                <h6 style={{ paddingBottom: 20 }}><strong>Descripción</strong></h6>
                                                                <p>
                                                                    {cart.description}
                                                                </p>

                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ paddingBottom: 20 }}><strong>Fabricante</strong></h6>
                                                                <p>{cart.providerCompany}</p>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6 style={{ paddingBottom: 20 }}><strong>Tiempo</strong></h6>
                                                                <p>
                                                                    {cart.productionTime}{" "} Horas
                                                                    </p>

                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6 style={{ paddingBottom: 20 }}><strong>Cantidad</strong></h6>
                                                                <p>
                                                                    {cart.qty}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <h6 style={{ paddingBottom: 20 }}><strong>Precio</strong></h6>
                                                                <p>
                                                                    {"$ "}{Intl.NumberFormat("de-DE").format(cart.totalPrice)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(false)}>Seguir cotizando</button>
                                <div>
                                    <Link to='/shopping-cart' className='btn-link' onClick={() => setIsModalOpen(false)}>
                                        <button type="button" className="btn btn-success" >Ir al carrito</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Services

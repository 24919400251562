import React, { useState, useEffect } from 'react'
import Toast from '../../../components/toast/Toast';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { completeResetPassDesignerApi } from '../services';

function ResetPassDesigner() {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);
    const [formValues, setFormValues] = useState({
        pass: '',
        confirmPass: '',
        token: '',
    });
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    async function handleResetPass(e) {
        e.preventDefault();
        setIsLoading(true);
        if (formValues.pass === formValues.confirmPass) {
            const response = await completeResetPassDesignerApi(formValues);
            if (typeof response !== 'undefined') {
                if (response.data.done) {
                    setConfigToast({ ...configToast, typeToast: "done", textToast: response.data.done });
                    setIsLoading(false);
                    window.location.search = "";
                }
                if (response.data.info) {
                    setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
                    setIsLoading(false);
                }
                if (response.data.error) {
                    setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
                    setIsLoading(false);
                }
            } else {
                console.log("problema en la peticion");
                setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
                setIsLoading(false);
                return
            }
        } else {
            setConfigToast({ ...configToast, typeToast: "warning", textToast: "Las contraseñas no coinciden" });
            setIsLoading(false);
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    let componentActiveMail = () => {
        if (window.location.search) {
            return <><h1>Actualización de contraseña</h1>
                <p>Por favor escribe tu nueva contraseña y repitela para actualizar tu contraseña.</p>
                <form onSubmit={handleResetPass}>

                    <div className="col-md-6">
                        <label htmlFor="formGroupExampleInput1" className="form-label"><b>Nueva contraseña</b></label>
                        <input type={isShowPass ? "text" : "password"} name="password" className="form-control" id="formGroupExampleInput1" placeholder="Contraseña"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="La contraseña debe tener 8 caracteres, incluida 1 letra mayúscula, 1 letra minúscula y 1 número"
                            onChange={(e) => { setFormValues({ ...formValues, pass: e.target.value }) }} value={formValues.pass} required />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="formGroupExampleInput2" className="form-label"><b>Confirmar contraseña</b></label>
                        <input type={isShowPass ? "text" : "password"} name="password2" className="form-control" id="formGroupExampleInput2" placeholder="Confirmar contraseña"
                            onChange={(e) => { setFormValues({ ...formValues, confirmPass: e.target.value }) }} value={formValues.confirmPass} required />
                    </div><br />

                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        {isShowPass ?
                            <div className="input-group mb-3">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                <span className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEyeSlash} /></span>
                            </div>
                            :
                            <div className="input-group mb-3">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                <span className="input-group-text" id="basic-addon1" onClick={() => { setIsShowPass(!isShowPass) }}><FontAwesomeIcon icon={faEye} /></span>
                            </div>
                        }

                    </div>

                    {isLoading ?
                        <div className='resetPasswordContainer-button'>
                            <button className="btn btn-primary" type="submit" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                        :
                        <div className='resetPasswordContainer-button'>
                            <button type="submit" className="btn btn-primary">Actualizar</button>
                        </div>
                    }
                </form></>
        } else {
            return <><h1>Ya actualizaste tu contraseña</h1>
                <p>Protipark agradece tu confianza.</p></>
        }
    }

    useEffect(() => {
        if (window.location.search) {
            var s1 = window.location.search;
            var s2 = s1.substring(1);
            setFormValues({ ...formValues, token: s2 });
        } else {
            console.log('nada en el header');
        }
    }, [])
    return (
        <>
            <div className='principalContainer'>
                {componentToast()}
                <div className='mainWrapper'>
                    <div className='resetPasswordContainer'>
                        {componentActiveMail()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassDesigner

import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const getProvidersAdmin = '/admin/getProviders';
const getDesignersAdmin = '/admin/getDesigners';
const getOrdersAdmin = '/admin/getOrders';
const getQuotersAdminUrl = '/admin/getQuoters';
//Fetch URL initial Content
const createInitialContentUrl = '/admin/createContent';
const updateInitialContentUrl = '/admin/updateContent';
const getInitialContentUrl = '/admin/getContentAdmin';

let userToken = JSON.parse(localStorage.getItem('userToken'));

export async function getProvidersAdminApi() {
    try {
        const response = await axios({
            url: baseUrl + getProvidersAdmin,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function getDesignersAdminApi() {
    try {
        const response = await axios({
            url: baseUrl + getDesignersAdmin,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function getOrdersAdminApi() {
    try {
        const response = await axios({
            url: baseUrl + getOrdersAdmin,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function getQuotersAdminApi() {
    try {
        const response = await axios({
            url: baseUrl + getQuotersAdminUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fetchCreateInitialContent(dataInitialContent) {
    try {
        const response = await axios({
            url: baseUrl + createInitialContentUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataInitialContent,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function fetchUpdateInitialContent(dataInitialContent) {
    try {
        const response = await axios({
            url: baseUrl + updateInitialContentUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': userToken.token
            },
            data: dataInitialContent,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}

export async function getInitialContent() {
    try {
        const response = await axios({
            url: baseUrl + getInitialContentUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'x-auth-token': userToken.token
            },
            // data: dataDesigner,
        });
        return response;
    } catch (e) {
        console.log(e);
    }
}
import React from 'react';
import '../css/manufacturerAccount.css';

function Dashboard() {

    return (
        <>
            <section className="bg-mix">
                <div className="secondContainer">
                    <h4><b>Dashboard</b></h4>
                    <div className="card rounded-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 d-flex stat my-3">
                                    <div className="mx-auto">
                                        <h6 className="text-muted">Ingresos</h6>
                                        <h3 className="font-weight-bold">50.000</h3>
                                        <h6 className="text-success"><ion-icon name="arrow-dropup-circle"></ion-icon>50%</h6>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex stat my-3">
                                    <div className="mx-auto">
                                        <h6 className="text-muted">Ingresos</h6>
                                        <h3 className="font-weight-bold">50.000</h3>
                                        <h6 className="text-success"><ion-icon name="arrow-dropup-circle"></ion-icon>50%</h6>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex stat my-3">
                                    <div className="mx-auto">
                                        <h6 className="text-muted">Ingresos</h6>
                                        <h3 className="font-weight-bold">50.000</h3>
                                        <h6 className="text-success"><ion-icon name="arrow-dropup-circle"></ion-icon>50%</h6>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex my-3">
                                    <div className="mx-auto">
                                        <h6 className="text-muted">Ingresos</h6>
                                        <h3 className="font-weight-bold">50.000</h3>
                                        <h6 className="text-success"><ion-icon name="arrow-dropup-circle"></ion-icon>50%</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="fifthContainer">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card dashboardCard">
                                <h3>Grafico 1</h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card dashboardCard">
                                <h3>Grafico 2</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card dashboardCard">
                                <h3>Grafico 3</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card dashboardCard">
                                <h3>Tabla 1</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard
import React from 'react'
import HeroSection from '../../components/HeroSection'
import Pricing from '../../components/Pricing'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data'
import Carousel from './Carousel'

function Home() {
    return (
        <>
            <HeroSection {...homeObjOne} />
            <Pricing />
            <Carousel/>
        </>
    )
}

export default Home

import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import MUIDataTable from "mui-datatables";
import { getDesignersAdminApi } from '../services';
import DesignerDetails from './DesignerDetails';

const columns = [
    {
        name: "#",
        label: "#",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Nombre",
        label: "Nombre",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Email",
        label: "Email",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Contacto",
        label: "Contacto",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Estado",
        label: "Estado",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Fecha de creación",
        label: "Fecha de creación",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Acción",
        label: "Acción",
        options: {
            filter: false,
            sort: false,
        }
    }
]

const options = {
    filterType: 'checkbox',
};
function DesignerAdministrator() {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });
    const [designersArray, setDesignersArray] = useState([]);

    const [isBanModal, setIsBanModal] = useState({ modal: false, id: 0 });
    const [isLoadingBanDesigner, setIsLoadingBanDesigner] = useState(false);

    const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
    const [designerInfo, setDesignerInfo] = useState([]);

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    async function bringAllDesigners() {
        const response = await getDesignersAdminApi();
        if (typeof response !== 'undefined') {
            if (response.data.designers) {
                let designers = response.data.designers;
                setDesignersArray(designers);
            }
            if (response.data.error) {
                setConfigToast({ ...configToast, typeToast: "error", textToast: response.data.error });
            }
            if (response.data.info) {
                setConfigToast({ ...configToast, typeToast: "info", textToast: response.data.info });
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    const handleDesignersCount = () => {
        return designersArray.length;
    }

    const handleBanProvider = (e) => {
        e.preventDefault();
        setIsLoadingBanDesigner(true);
        setTimeout(() => {
            // console.log(isBanModal.id);
            isBanModal.modal = false;
            setIsLoadingBanDesigner(false);
            setConfigToast({ ...configToast, typeToast: "done", textToast: 'Baneo exitoso' });
        }, 2000);

    }

    const handleShowDetailsDesigner = (designer) => {
        setDesignerInfo(designer);
        setIsDetailViewOpen(true);
    }

    const handleCloseDetailsDesigner = () => {
        setIsDetailViewOpen(false);
    }


    useEffect(() => {
        bringAllDesigners();
    }, []);

    return (
        <div>
            {!isDetailViewOpen ?

                <div className="adminInformationContainer">
                    {componentToast()}
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card informationCard">
                                <h4 style={{ textAlign: "center" }}>Diseñadores registrados</h4>
                                <h3 style={{ color: "#198754", margin: 15 }}><b>{handleDesignersCount()}</b></h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card informationCard">
                                <h4 style={{ textAlign: "center" }}>Devoluciones pendientes</h4>
                                <h3 style={{ color: "red", margin: 15 }}><b>0</b></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="card informationCard">
                            {/* <h3 style={{ marginBottom: 30 }}>Listado de Diseñadores</h3> */}
                            <div className="row w-100" style={{ textAlign: "center" }}>
                                <MUIDataTable
                                    title={"Listado de diseñadores"}
                                    data={designersArray.map((designer, i) => {
                                        return [
                                            i + 1,
                                            designer.name + designer.lastName,
                                            designer.email,
                                            designer.phoneNumber,
                                            designer.status,
                                            designer.createdAt.substring(0, 10),
                                            <div style={{ fontSize: 25 }}>
                                                <button style={{ padding: 4, marginRight: 5 }} type="button" className="btn btn-info cartButon" onClick={() => handleShowDetailsDesigner(designer)}>
                                                    <ion-icon name="eye"></ion-icon>
                                                </button>
                                                <button style={{ padding: 4 }} type="button" className="btn btn-danger" onClick={() => setIsBanModal({ ...isBanModal, modal: true, id: designer._id })}>
                                                    <ion-icon name="close"></ion-icon>
                                                </button>
                                            </div>
                                        ]
                                    })}
                                    columns={columns}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`modal fade registerModal ${isBanModal.modal ? 'show' : ''}`}
                        style={{ display: `${isBanModal.modal ? 'block' : 'none'}`, backgroundColor: 'rgb(0,0,0,0.55)' }} id="deleteModal" tabIndex="1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">¿Desea banear al diseñador?</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsBanModal({ ...isBanModal, modal: false })}></button>
                                </div>
                                <form onSubmit={handleBanProvider}>
                                    <div className="modal-body">
                                        <label htmlFor="">Tiempo de baneo</label>
                                        <input type="date" className="form-control" required />
                                        <label htmlFor="">Escribe tu contraseña para confirmar</label>
                                        <input type="password" className="form-control" required />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setIsBanModal({ ...isBanModal, modal: false })}>Cerrar</button>
                                        {isLoadingBanDesigner ?
                                            <button type="submit" className="btn btn-danger" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button>
                                            :
                                            <button type="submit" className="btn btn-danger">Confirmar</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                : <DesignerDetails handleCloseDetailsDesigner={handleCloseDetailsDesigner} designerInfo={designerInfo} />}
        </div>
    )
}

export default DesignerAdministrator

import React from 'react'
import Faq from './Components/Faq'
import "./css/style.css"

function Faqpage() {
    return (
        <div className="">
            <div className="">
                <Faq />
            </div>
        </div>
    )
}

export default Faqpage

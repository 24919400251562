import React, { useState, useEffect, useContext } from 'react';
import '../css/manufacturerAccount.css';
import AccountManufacturer from './Account';
import Dashboard from './Dashboard';
import ManufacturerOrders from './Orders';
import ManufacturerQuote from './Quote';
import OrderDetails from './OrderDetails';
import { DesignerContext } from '../../../context/DesignerContext';
import { getProviderDataApi } from '../services';
import Toast from '../../../components/toast/Toast';

const itemsCrumbs = [
    { i: 1, label: 'Cuenta', icon: 'person' },
    { i: 2, label: 'Pedidos', icon: 'stats' },
    { i: 3, label: 'Dashboard', icon: 'logo-usd' },
    { i: 4, label: 'Cotizador', icon: 'calculator' },
    { i: 5, label: 'Salir', icon: 'exit' }
]

function ManufacturerDashboard() {
    const [designerContext, setdesignerContext] = useContext(DesignerContext);
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const [changeItem, setChangeItem] = useState('');

    const handleLogoutDesigner = () => {
        let objStorage = { token: '', user: 0 };
        localStorage.setItem('userToken', JSON.stringify(objStorage));
        window.location.href = '/logout';
    }

    async function bringDataProvider() {
        const response = await getProviderDataApi();
        if (typeof response !== 'undefined') {
            if (Object.keys(response.data.providerData).length !== 0) {
                let providerData = response.data.providerData;
                setdesignerContext(providerData);
            }
        } else {
            console.log("problema en la peticion");
            setConfigToast({ ...configToast, typeToast: "error", textToast: "Server Off" });
            return
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    useEffect(() => {
        bringDataProvider();
        if (window.location.hash === '#2') {
            setChangeItem('Pedidos')
        }
    }, []);

    let componentItem = () => {
        switch (changeItem) {
            case 'Cuenta':
                return (<AccountManufacturer />);
            case 'Pedidos':
                return (<ManufacturerOrders />);
            case 'Dashboard':
                return (<Dashboard />);
            case 'Cotizador':
                return (<ManufacturerQuote />);
            case 'Salir':
                return (handleLogoutDesigner());
            default:
                return (<ManufacturerOrders />);
        }
    }

    return (
        <div className="d-flex">
            {componentToast()}
            <input type="checkbox" id="sidebar-toggle" />
            <div className="sidebar-container bg-primary" style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <div className="dashboardLogo">
                    <label htmlFor="sidebar-toggle" style={{ color: "white", fontSize: 30 }}><ion-icon name="menu"></ion-icon></label>
                    <h4 className="text-light font-weight-bold">
                    <a style={{ color: "white", textDecoration:'none' }} href={"/"}><span style={{ marginLeft: 10 }}>{designerContext.companyName}</span></a>
                        </h4>
                </div>
                <div className="dashboardMenu">
                    {itemsCrumbs.map(({ i, label, icon }) => {
                        if (changeItem === label) {
                            return (
                                <li style={{ fontSize: 15 }} className="d-block text-light p-3 active" style={{ textDecoration: 'underline', borderRadius: 10 }} aria-current="page" key={i} onClick={() => setChangeItem(label)}>
                                    <ion-icon name={icon}></ion-icon><a style={{ color: "white" }} href={"#" + i}><span style={{ marginLeft: 10 }}>{label}</span></a>
                                </li>
                            )
                        } else {
                            return (
                                <li style={{ fontSize: 15 }} className="d-block text-light p-3" key={i} onClick={() => setChangeItem(label)}>
                                    <ion-icon name={icon}></ion-icon><a style={{ color: "white" }} href={"#" + i}><span style={{ marginLeft: 10 }}>{label}</span></a>
                                </li>
                            )
                        }
                    })}
                </div>
            </div>
            <div className="w-100">
                <div className="dashboardContent">
                    {componentItem()}
                </div>
            </div>
        </div>
    )
}

export default ManufacturerDashboard

import React from 'react'
import AdminDashboard from './Components/AdminDashboard'

function AdminPanel() {
    return (
        <div>
            <AdminDashboard/>
        </div>
    )
}

export default AdminPanel

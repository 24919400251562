import React from 'react'
import OrderSummary from './Components/OrderSummary'

function Orders() {
    return (
        <div className='principalContainer'>
        <div className='mainWrapper'>
            <div className="row">
                <div className="col-xl-12">
                    <OrderSummary/>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Orders

import React from 'react'
import '../../css/principal.css'
import ActiveButton from './components/ActiveButton'

function index() {
    return (
        <div className='principalContainer'>
            <div className='mainWrapper'>
                <div className="row">
                    <div className="col-md-6">
                        <ActiveButton/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index

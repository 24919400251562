import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import "../css/GerberFile.css";

function ManufacturersList({ bringMakerInfo, bringMaker, listProviders }) {

    const [isMakerSearch, setIsMakerSearch] = useState(false);
    const [providersData, setProvidersData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const [isFilterTime, setIsFilterTime] = useState('');
    const [isFilterPrice, setIsFilterPrice] = useState('');

    const handleSelectMaker = (values) => {
        let provider = values;
        let objProvider = {
            _id: provider.id,
            company: provider.name,
            description: provider.description,
            timeMake: provider.time,
            price: provider.cost
        }
        bringMakerInfo(objProvider);
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }

    const handleFilterTime = (e) => {
        switch (e.target.value) {
            case "1":
                providersData.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));
                break;
            case "2":
                providersData.sort((a, b) => (a.time < b.time) ? 1 : ((b.time < a.time) ? -1 : 0));
                break;
            default:
                break;
        }
        setIsFilterTime(e.target.value);
    }

    const handleFilterPrice = (e) => {
        switch (e.target.value) {
            case "1":
                providersData.sort((a, b) => (a.cost > b.cost) ? 1 : ((b.cost > a.cost) ? -1 : 0));
                break;
            case "2":
                providersData.sort((a, b) => (a.cost < b.cost) ? 1 : ((b.cost < a.cost) ? -1 : 0));
                break;
            default:
                break;
        }
        setIsFilterPrice(e.target.value);
    }
    useEffect(() => {
        if (bringMaker && listProviders.length !== 0) {
            setProvidersData(listProviders);
        } else {
            setProvidersData([]);
        }
        setIsMakerSearch(bringMaker);
    }, [bringMaker, listProviders]);

    return (
        <>
            {componentToast()}
            <div className="manufacturercontainer">
                <div className="card">
                    <form className="cardForm">
                        <p>Seleccionar Proovedor</p>
                        <div className="row">
                            <div className="col-sm-6">
                                <select className="form-select" aria-label="Default select example"
                                    onChange={handleFilterTime} value={isFilterTime}>
                                    <option hidden>Ordenar por tiempo</option>
                                    <option value="1">Más rápido</option>
                                    <option value="2">Más demorado</option>
                                </select>
                            </div>
                            <div className="col-sm-6">
                                <select className="form-select" aria-label="Default select example"
                                    onChange={handleFilterPrice} value={isFilterPrice}>
                                    <option hidden>Ordenar por precio</option>
                                    <option value="1">Más económico</option>
                                    <option value="2">Más costoso</option>
                                </select>
                            </div>
                        </div>

                        {isMakerSearch ?
                            providersData.length !== 0 ?
                                <div className="row manufacturerSection" style={providersData.length > 5 ? { overflowY: 'scroll', height: 996 } : null}>
                                    <div className="col-lg-12">
                                        {providersData.map((provider, i) => {
                                            return (
                                                <div key={i} className="card Structure">
                                                    <div className='manImgCont'>
                                                        {provider.image !== '' ?
                                                            <img src={provider.image} alt="uploadImage" style={{ width: 110, margin: 15 }} />
                                                            :
                                                            <img src="images/PROTOTIPARK_NOTION.png" className="card-img-top manImg" alt="noImg" />
                                                        }
                                                    </div>
                                                    <div className="card-body">
                                                        <h5 className="card-title">{provider.name}{" "}{provider.website}</h5>
                                                        <p className="card-text">{provider.description}</p>
                                                        <p className="card-text">{provider.time} {" "} horas</p>
                                                        <p className="card-text"> {"$ "}{Intl.NumberFormat("de-DE").format(provider.cost)}</p>
                                                        <button type="button" className="btn btn-primary" onClick={() => handleSelectMaker(provider)}>Seleccionar</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                : <div className="card" style={{ marginTop: 20 }}><p style={{ margin: 25 }}>no se encontraron proveedores</p></div>

                            : <div className="card" style={{ marginTop: 20 }}><p style={{ margin: 25 }}>Cotiza para encontrar tu mejor proveedor</p></div>

                        }



                    </form>
                </div>
            </div>
        </>
    )
}

export default ManufacturersList;

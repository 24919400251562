import React, { useState, useEffect } from 'react';
import Toast from '../../../components/toast/Toast';
import './../css/AdminDashboard.css';
import DesignerAdministrator from './DesignerAdministrator';
import GeneralSettings from './GeneralSettings';
import ManufacturersAdministrator from './ManufacturersAdministrator';
import OrdersAdministrator from './OrdersAdministrator';

const itemsCrumbs = [
    { i: 1, label: 'Ajustes Generales', icon: 'stats' },
    { i: 2, label: 'Fabricantes', icon: 'person' },
    { i: 3, label: 'Órdenes', icon: 'stats' },
    { i: 4, label: 'Diseñadores', icon: 'person' },
    { i: 5, label: 'Salir', icon: 'exit' },
]

function AdminDashboard() {
    const [configToast, setConfigToast] = useState({
        typeToast: '',
        textToast: ''
    });

    const [changeItem, setChangeItem] = useState('');

    const handleLogoutAdmin = () => {
        let objStorage = { token: '', user: 0 };
        localStorage.setItem('userToken', JSON.stringify(objStorage));
        window.location.href = '/logout';
    }

    let componentItem = () => {
        switch (changeItem) {
            case 'Ajustes Generales':
                return (<GeneralSettings />);
            case 'Fabricantes':
                return (<ManufacturersAdministrator />);
            case 'Diseñadores':
                return (<DesignerAdministrator />);
            case 'Órdenes':
                return (<OrdersAdministrator />);
            case 'Salir':
                return (handleLogoutAdmin());
            default:
                return (<GeneralSettings />);
        }
    }

    let componentToast = () => {
        if (configToast.typeToast !== '') {
            return <Toast typeToast={configToast.typeToast}
                changeError={changeError}
                textToast={configToast.textToast} />
        } else {
            return null
        }
    }

    const changeError = () => {
        setConfigToast({ ...configToast, typeToast: '', textToast: '' });
    }


    useEffect(() => {
        if (window.location.hash === '#1') {
            setChangeItem('Ajustes Generales');
        }
    }, []);

    return (
        <div>
            <div className="d-flex">
                {componentToast()}
                <input type="checkbox" id="sidebar-toggle" />
                <div className="sidebar-container bg-sidebar" style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <div className="dashboardLogo">
                        <label htmlFor="sidebar-toggle" style={{ color: "white", fontSize: 30 }}><ion-icon name="menu"></ion-icon></label>
                        <h4 className="text-light font-weight-bold">
                            <a style={{ color: "white", textDecoration: 'none' }} href={"/"}><span style={{ marginLeft: 10 }}>PROTIPARK</span></a></h4>
                    </div>
                    <div className="dashboardMenu">
                        {itemsCrumbs.map(({ i, label, icon }) => {
                            if (changeItem === label) {
                                return (
                                    <li style={{ fontSize: 15 }} className="d-block text-light p-3 active" style={{ textDecoration: 'underline', borderRadius: 10 }} aria-current="page" key={i} onClick={() => setChangeItem(label)}>
                                        <ion-icon name={icon}></ion-icon><a style={{ color: "white" }} href={"#" + i}><span style={{ marginLeft: 10 }}>{label}</span></a>
                                    </li>
                                )
                            } else {
                                return (
                                    <li style={{ fontSize: 15 }} className="d-block text-light p-3" key={i} onClick={() => setChangeItem(label)}>
                                        <ion-icon name={icon}></ion-icon><a style={{ color: "white" }} href={"#" + i}><span style={{ marginLeft: 10 }}>{label}</span></a>
                                    </li>
                                )
                            }
                        })}
                    </div>
                </div>
                <div className="w-100">
                    <div className="dashboardContent">
                        {componentItem()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard
